const translation = {
  title: '注釈',
  name: '注釈の返信',
  editBy: '{{author}} によって編集された回答',
  noData: {
    title: '注釈がありません',
    description: 'ここではアプリのデバッグ中に注釈を編集したり、一括で注釈をインポートしたりして高品質の応答を行うことができます。',
  },
  table: {
    header: {
      question: '質問',
      answer: '回答',
      createdAt: '作成日時',
      hits: 'ヒット数',
      actions: 'アクション',
      addAnnotation: '注釈を追加',
      bulkImport: '一括インポート',
      bulkExport: '一括エクスポート',
      clearAll: 'すべての注釈をクリア',
    },
  },
  editModal: {
    title: '注釈の返信を編集',
    queryName: 'ユーザーのクエリ',
    answerName: 'ストーリーテラーボット',
    yourAnswer: '貴方の回答',
    answerPlaceholder: 'ここに回答を入力してください',
    yourQuery: 'あなたのクエリ',
    queryPlaceholder: 'ここにクエリを入力してください',
    removeThisCache: 'この注釈を削除',
    createdAt: '作成日時',
  },
  addModal: {
    title: '注釈の返信を追加',
    queryName: '質問',
    answerName: '回答',
    answerPlaceholder: 'ここに回答を入力してください',
    queryPlaceholder: 'ここに質問を入力してください',
    createNext: '別の注釈付きの応答を追加',
  },
  batchModal: {
    title: '一括インポート',
    csvUploadTitle: 'CSVファイルをここにドラッグ＆ドロップするか、',
    browse: '参照',
    tip: 'CSVファイルは以下の構造に準拠する必要があります:',
    question: '質問',
    answer: '回答',
    contentTitle: 'チャンクの内容',
    content: '内容',
    template: 'テンプレートをここからダウンロード',
    cancel: 'キャンセル',
    run: '一括実行',
    runError: '一括実行に失敗しました',
    processing: '一括処理中',
    completed: 'インポートが完了しました',
    error: 'インポートエラー',
    ok: 'OK',
  },
  errorMessage: {
    answerRequired: '回答は必須です',
    queryRequired: '質問は必須です',
  },
  viewModal: {
    annotatedResponse: '注釈の返信',
    hitHistory: 'ヒット履歴',
    hit: 'ヒット',
    hits: 'ヒット数',
    noHitHistory: 'ヒット履歴はありません',
  },
  hitHistoryTable: {
    query: 'クエリ',
    match: '一致',
    response: '応答',
    source: 'ソース',
    score: 'スコア',
    time: '時間',
  },
  initSetup: {
    title: '注釈の返信の初期設定',
    configTitle: '注釈の返信の設定',
    confirmBtn: '保存して有効にする',
    configConfirmBtn: '保存',
  },
  embeddingModelSwitchTip: '注釈テキストのベクトル化モデルです。モデルを切り替えると再埋め込みが行われ、追加のコストが発生します。',
}

export default translation
