const translation = {
  title: 'Conversation History',
  description: 'Records the details of conversations with the AI Bot, including user inputs and AI responses.',
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      updatedTime: 'Updated time',
      time: 'Created time',
      endUser: 'End User',
      input: 'Input',
      output: 'Output',
      summary: 'Summary',
      messageCount: 'Message Count',
      userRate: 'User Feedback',
      adminRate: 'Admin Feedback',
      startTime: 'Start Time',
      status: 'Status',
      runtime: 'Runtime',
      tokens: 'TOKENS',
      user: 'User',
      version: 'Version',
    },
    pagination: {
      previous: 'Previous Page',
      next: 'Next Page',
    },
    empty: {
      noChat: 'No Conversation Started',
      noOutput: 'No Output',
      element: {
        title: 'Is Anyone Here',
        content: 'Check the conversation between users and AI here to continuously improve AI accuracy. You can <testLink>try</testLink> the WebApp or <shareLink>share</shareLink> it, and then return to this page.',
      },
    },
  },
  detail: {
    time: 'Time',
    conversationId: 'Conversation ID',
    promptTemplate: 'Prompt Template',
    promptTemplateBeforeChat: 'Pre-Chat Prompt · Submitted as a System Message',
    annotationTip: 'Improvement suggestions marked by {{user}}',
    timeConsuming: 'Time Consumed',
    second: ' seconds',
    tokenCost: 'Tokens Spent',
    loading: 'Loading',
    operation: {
      like: 'helpful',
      dislike: 'not helpful',
      addAnnotation: 'Add Improvement Suggestion',
      editAnnotation: 'Edit Improvement Suggestion',
      annotationPlaceholder: 'Enter the expected answer you want AI to respond with. This can be used for model fine-tuning in the future to continuously improve text generation quality.',
    },
    variables: 'Variables',
    uploadImages: 'Uploaded Images',
  },
  filter: {
    period: {
      today: 'Today',
      last7days: 'Last 7 Days',
      last4weeks: 'Last 4 Weeks',
      last3months: 'Last 3 Months',
      last12months: 'Last 12 Months',
      monthToDate: 'Month to Date',
      quarterToDate: 'Quarter to Date',
      yearToDate: 'Year to Date',
      allTime: 'All Time',
    },
    annotation: {
      all: 'All',
      annotated: 'Annotated Improvements ({{count}} items)',
      not_annotated: 'Not Annotated',
    },
    sortBy: 'Sort by:',
    descending: 'descending',
    ascending: 'ascending',
  },
  workflowTitle: 'Logs',
  workflowSubtitle: 'Logs record the execution status of the application',
  runDetail: {
    title: 'Conversation Logs',
    workflowTitle: 'Log Details',
  },
  promptLog: 'Prompt Logs',
  agentLog: 'Agent Logs',
  viewLog: 'View Logs',
  agentLogDetail: {
    agentMode: 'Agent Mode',
    toolUsed: 'Tool Used',
    iterations: 'Iterations',
    iteration: 'Iteration',
    finalProcessing: 'Final Processing',
  },
  answerTrace: {
    title: 'The AI referenced the following knowledge: ',
    loading: 'Searching for AI-referenced knowledge',
    view: 'View AI-referenced knowledge',
  },
}

export default translation
