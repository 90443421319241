const translation = {
  custom: 'Customization',
  upgradeTip: {
    prefix: 'Upgrade your plan to',
    suffix: 'customize your brand.',
  },
  webapp: {
    title: 'Customize WebApp Branding',
    removeBrand: 'Remove Powered by x-elephant',
    changeLogo: 'Change Powered by Brand Image',
    changeLogoTip: 'SVG or PNG format, minimum size 40x40px',
  },
  app: {
    title: 'Customize App Branding',
    changeLogoTip: 'SVG or PNG format, minimum size 80x80px',
  },
  upload: 'Upload',
  uploading: 'Uploading',
  uploadedFail: 'Image upload failed, please re-upload.',
  change: 'Change',
  apply: 'Apply',
  restore: 'Restore Default',
  customize: {
    contactUs: 'Contact Us',
    prefix: 'To customize brand icons within x-elephant, please',
    suffix: 'upgrade to the enterprise version.',
  },
}

export default translation
