const translation = {
  confirm: {
    application: {
      title: 'AI बॉट सीमा पहुंच गई है, अस्थायी रूप से बनाने में असमर्थ',
      content: 'कृपया अपने संस्करण को अपग्रेड करने के लिए हमसे संपर्क करें, या कुछ चैटबॉट्स को हटा दें',
    },
    document: {
      title: 'दस्तावेज़ सीमा पहुंच गई है, अस्थायी रूप से जोड़ने में असमर्थ',
      content: 'कृपया अपने संस्करण को अपग्रेड करने के लिए हमसे संपर्क करें, या कुछ दस्तावेज़ों को हटा दें',
    },
    video: {
      title: 'वीडियो सीमा पहुंच गई है, अस्थायी रूप से जोड़ने में असमर्थ',
      content: 'कृपया अपने संस्करण को अपग्रेड करने के लिए हमसे संपर्क करें, या कुछ वीडियो को हटा दें',
    },
    buttonText: 'समझ गया',
    messageLimitAnswer: 'x-elephant लगता है सो गया है, कृपया सहायता के लिए ग्राहक समर्थन से संपर्क करें',
  },
}

export default translation
