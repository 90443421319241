const translation = {
  pageTitle: 'अरे, चलिए शुरू करते हैं!👋',
  welcome: 'Dify में आपका स्वागत है, कृपया जारी रखने के लिए लॉग इन करें।',
  email: 'ईमेल पता',
  emailPlaceholder: 'आपका ईमेल',
  password: 'पासवर्ड',
  passwordPlaceholder: 'आपका पासवर्ड',
  name: 'उपयोगकर्ता नाम',
  namePlaceholder: 'आपका उपयोगकर्ता नाम',
  forget: 'क्या आप पासवर्ड भूल गए?',
  signBtn: 'साइन इन करें',
  sso: 'SSO के साथ जारी रखें',
  installBtn: 'सेट अप करें',
  setAdminAccount: 'एडमिन खाता सेट कर रहे हैं',
  setAdminAccountDesc:
    'एडमिन खाते के लिए अधिकतम विशेषाधिकार, जिसका उपयोग एप्लिकेशन बनाने और LLM प्रदाताओं को प्रबंधित करने के लिए किया जा सकता है, आदि।',
  createAndSignIn: 'बनाएं और साइन इन करें',
  oneMoreStep: 'एक और कदम',
  createSample: 'इस जानकारी के आधार पर, हम आपके लिए नमूना एप्लिकेशन बनाएंगे',
  invitationCode: 'निमंत्रण कोड',
  invitationCodePlaceholder: 'आपका निमंत्रण कोड',
  interfaceLanguage: 'इंटरफेस भाषा',
  timezone: 'समय क्षेत्र',
  go: 'Dify पर जाएं',
  sendUsMail:
    'हमें अपना परिचय ईमेल करें, और हम निमंत्रण अनुरोध को संभाल लेंगे।',
  acceptPP: 'मैंने गोपनीयता नीति पढ़ी है और स्वीकार करता हूं',
  reset: 'कृपया अपना पासवर्ड रीसेट करने के लिए निम्नलिखित कमांड चलाएं',
  withGitHub: 'GitHub के साथ जारी रखें',
  withGoogle: 'Google के साथ जारी रखें',
  rightTitle: 'LLM की पूरी क्षमता को अनलॉक करें',
  rightDesc:
    'दृश्य रूप से आकर्षक, संचालन योग्य और सुधार योग्य AI एप्लिकेशन को आसानी से बनाएं।',
  tos: 'सेवा की शर्तें',
  pp: 'गोपनीयता नीति',
  tosDesc: 'साइन अप करके, आप हमारी सहमति देते हैं',
  goToInit:
    'यदि आपने खाता प्रारंभ नहीं किया है, तो कृपया प्रारंभिक पृष्ठ पर जाएं',
  donthave: 'नहीं है?',
  invalidInvitationCode: 'अवैध निमंत्रण कोड',
  accountAlreadyInited: 'खाता पहले से प्रारंभ किया गया है',
  forgotPassword: 'क्या आपने अपना पासवर्ड भूल गए हैं?',
  resetLinkSent: 'रीसेट लिंक भेजी गई',
  sendResetLink: 'रीसेट लिंक भेजें',
  backToSignIn: 'साइन इन पर वापस जाएं',
  forgotPasswordDesc: 'कृपया अपना ईमेल पता दर्ज करें ताकि हम आपको अपना पासवर्ड रीसेट करने के निर्देशों के साथ एक ईमेल भेज सकें।',
  checkEmailForResetLink: 'कृपया अपना पासवर्ड रीसेट करने के लिए लिंक के लिए अपना ईमेल चेक करें। अगर यह कुछ मिनटों के भीतर नहीं आता है, तो कृपया अपना स्पैम फोल्डर भी चेक करें।',
  passwordChanged: 'अब साइन इन करें',
  changePassword: 'पासवर्ड बदलें',
  changePasswordTip: 'कृपया अपने खाते के लिए नया पासवर्ड दर्ज करें',
  invalidToken: 'अमान्य या समाप्त टोकन',
  confirmPassword: 'पासवर्ड की पुष्टि करें',
  confirmPasswordPlaceholder: 'अपना नया पासवर्ड पुष्टि करें',
  passwordChangedTip: 'आपका पासवर्ड सफलतापूर्वक बदल दिया गया है',
  error: {
    emailEmpty: 'ईमेल पता आवश्यक है',
    emailInValid: 'कृपया एक मान्य ईमेल पता दर्ज करें',
    nameEmpty: 'नाम आवश्यक है',
    passwordEmpty: 'पासवर्ड आवश्यक है',
    passwordLengthInValid: 'पासवर्ड कम से कम 8 वर्णों का होना चाहिए',
    passwordInvalid:
      'पासवर्ड में अक्षर और अंक होने चाहिए, और लंबाई 8 से अधिक होनी चाहिए',
  },
  license: {
    tip: 'Dify Community Edition शुरू करने से पहले, GitHub पर',
    link: 'ओपन-सोर्स लाइसेंस',
  },
  join: 'शामिल हों',
  joinTipStart: 'आपको आमंत्रित करते हैं',
  joinTipEnd: 'टीम पर Dify',
  invalid: 'लिंक समाप्त हो गया है',
  explore: 'Dify का अन्वेषण करें',
  activatedTipStart: 'आप शामिल हो गए हैं',
  activatedTipEnd: 'टीम',
  activated: 'अब साइन इन करें',
  adminInitPassword: 'एडमिन प्रारंभिक पासवर्ड',
  validate: 'सत्यापित करें',
}

export default translation
