const translation = {
  currentPlan: 'Plan Actual',
  upgradeBtn: {
    plain: 'Actualizar Plan',
    encourage: 'Actualizar Ahora',
    encourageShort: 'Actualizar',
  },
  viewBilling: 'Administrar facturación y suscripciones',
  buyPermissionDeniedTip: 'Por favor, contacta al administrador de tu empresa para suscribirte',
  plansCommon: {
    title: 'Elige un plan que sea adecuado para ti',
    yearlyTip: '¡Obtén 2 meses gratis al suscribirte anualmente!',
    mostPopular: 'Más Popular',
    planRange: {
      monthly: 'Mensual',
      yearly: 'Anual',
    },
    month: 'mes',
    year: 'año',
    save: 'Ahorra ',
    free: 'Gratis',
    currentPlan: 'Plan Actual',
    contractSales: 'Contactar ventas',
    contractOwner: 'Contactar al administrador del equipo',
    startForFree: 'Empezar gratis',
    getStartedWith: 'Empezar con ',
    contactSales: 'Contactar Ventas',
    talkToSales: 'Hablar con Ventas',
    modelProviders: 'Proveedores de Modelos',
    teamMembers: 'Miembros del Equipo',
    annotationQuota: 'Cuota de Anotación',
    buildApps: 'Crear Aplicaciones',
    vectorSpace: 'Espacio Vectorial',
    vectorSpaceBillingTooltip: 'Cada 1MB puede almacenar aproximadamente 1.2 millones de caracteres de datos vectorizados (estimado utilizando OpenAI Embeddings, varía según los modelos).',
    vectorSpaceTooltip: 'El Espacio Vectorial es el sistema de memoria a largo plazo necesario para que los LLMs comprendan tus datos.',
    documentsUploadQuota: 'Cuota de Carga de Documentos',
    documentProcessingPriority: 'Prioridad de Procesamiento de Documentos',
    documentProcessingPriorityTip: 'Para una mayor prioridad de procesamiento de documentos, por favor actualiza tu plan.',
    documentProcessingPriorityUpgrade: 'Procesa más datos con mayor precisión y velocidad.',
    priority: {
      'standard': 'Estándar',
      'priority': 'Prioridad',
      'top-priority': 'Prioridad Máxima',
    },
    logsHistory: 'Historial de Registros',
    customTools: 'Herramientas Personalizadas',
    unavailable: 'No disponible',
    days: 'días',
    unlimited: 'Ilimitado',
    support: 'Soporte',
    supportItems: {
      communityForums: 'Foros Comunitarios',
      emailSupport: 'Soporte por Correo Electrónico',
      priorityEmail: 'Soporte Prioritario por Correo Electrónico y Chat',
      logoChange: 'Cambio de Logotipo',
      SSOAuthentication: 'Autenticación SSO',
      personalizedSupport: 'Soporte Personalizado',
      dedicatedAPISupport: 'Soporte API Dedicado',
      customIntegration: 'Integración y Soporte Personalizado',
      ragAPIRequest: 'Solicitudes API RAG',
      bulkUpload: 'Carga Masiva de Documentos',
      agentMode: 'Modo Agente',
      workflow: 'Flujo de Trabajo',
      llmLoadingBalancing: 'Balanceo de Carga LLM',
      llmLoadingBalancingTooltip: 'Agrega múltiples claves API a los modelos, evitando efectivamente los límites de velocidad de API.',
    },
    comingSoon: 'Próximamente',
    member: 'Miembro',
    memberAfter: 'Miembro',
    messageRequest: {
      title: 'Créditos de Mensajes',
      tooltip: 'Cuotas de invocación de mensajes para varios planes utilizando modelos de OpenAI (excepto gpt4). Los mensajes que excedan el límite utilizarán tu clave API de OpenAI.',
    },
    annotatedResponse: {
      title: 'Límites de Cuota de Anotación',
      tooltip: 'Edición manual y anotación de respuestas proporciona habilidades de respuesta a preguntas personalizadas y de alta calidad para aplicaciones (aplicable solo en aplicaciones de chat).',
    },
    ragAPIRequestTooltip: 'Se refiere al número de llamadas API que invocan solo las capacidades de procesamiento de base de conocimientos de Dify.',
    receiptInfo: 'Solo el propietario del equipo y el administrador del equipo pueden suscribirse y ver la información de facturación.',
  },
  plans: {
    sandbox: {
      name: 'Sandbox',
      description: 'Prueba gratuita de 200 veces GPT',
      includesTitle: 'Incluye:',
    },
    professional: {
      name: 'Profesional',
      description: 'Para individuos y pequeños equipos que desean desbloquear más poder de manera asequible.',
      includesTitle: 'Todo en el plan gratuito, más:',
    },
    team: {
      name: 'Equipo',
      description: 'Colabora sin límites y disfruta de un rendimiento de primera categoría.',
      includesTitle: 'Todo en el plan Profesional, más:',
    },
    enterprise: {
      name: 'Empresa',
      description: 'Obtén capacidades completas y soporte para sistemas críticos a gran escala.',
      includesTitle: 'Todo en el plan Equipo, más:',
    },
  },
  vectorSpace: {
    fullTip: 'El Espacio Vectorial está lleno.',
    fullSolution: 'Actualiza tu plan para obtener más espacio.',
  },
  apps: {
    fullTipLine1: 'Actualiza tu plan para',
    fullTipLine2: 'crear más aplicaciones.',
  },
  annotatedResponse: {
    fullTipLine1: 'Actualiza tu plan para',
    fullTipLine2: 'anotar más conversaciones.',
    quotaTitle: 'Cuota de Respuesta Anotada',
  },
}

export default translation
