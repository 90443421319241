const translation = {
  title: 'Anmerkungen',
  name: 'Antwort Anmerkung',
  editBy: 'Antwort bearbeitet von {{author}}',
  noData: {
    title: 'Keine Anmerkungen',
    description: 'Sie können Anmerkungen während des App-Debuggings bearbeiten oder hier Anmerkungen in großen Mengen importieren für eine hochwertige Antwort.',
  },
  table: {
    header: {
      question: 'Frage',
      answer: 'Antwort',
      createdAt: 'erstellt am',
      hits: 'Treffer',
      actions: 'Aktionen',
      addAnnotation: 'Anmerkung hinzufügen',
      bulkImport: 'Massenimport',
      bulkExport: 'Massenexport',
      clearAll: 'Alle Anmerkungen löschen',
    },
  },
  editModal: {
    title: 'Antwort Anmerkung bearbeiten',
    queryName: 'Benutzeranfrage',
    answerName: 'Geschichtenerzähler Bot',
    yourAnswer: 'Ihre Antwort',
    answerPlaceholder: 'Geben Sie hier Ihre Antwort ein',
    yourQuery: 'Ihre Anfrage',
    queryPlaceholder: 'Geben Sie hier Ihre Anfrage ein',
    removeThisCache: 'Diese Anmerkung entfernen',
    createdAt: 'Erstellt am',
  },
  addModal: {
    title: 'Antwort Anmerkung hinzufügen',
    queryName: 'Frage',
    answerName: 'Antwort',
    answerPlaceholder: 'Antwort hier eingeben',
    queryPlaceholder: 'Anfrage hier eingeben',
    createNext: 'Eine weitere annotierte Antwort hinzufügen',
  },
  batchModal: {
    title: 'Massenimport',
    csvUploadTitle: 'Ziehen Sie Ihre CSV-Datei hierher oder ',
    browse: 'durchsuchen',
    tip: 'Die CSV-Datei muss der folgenden Struktur entsprechen:',
    question: 'Frage',
    answer: 'Antwort',
    contentTitle: 'Inhaltsabschnitt',
    content: 'Inhalt',
    template: 'Laden Sie die Vorlage hier herunter',
    cancel: 'Abbrechen',
    run: 'Batch ausführen',
    runError: 'Batch-Ausführung fehlgeschlagen',
    processing: 'In Batch-Verarbeitung',
    completed: 'Import abgeschlossen',
    error: 'Importfehler',
    ok: 'OK',
  },
  errorMessage: {
    answerRequired: 'Antwort erforderlich',
    queryRequired: 'Frage erforderlich',
  },
  viewModal: {
    annotatedResponse: 'Antwort Anmerkung',
    hitHistory: 'Trefferhistorie',
    hit: 'Treffer',
    hits: 'Treffer',
    noHitHistory: 'Keine Trefferhistorie',
  },
  hitHistoryTable: {
    query: 'Anfrage',
    match: 'Übereinstimmung',
    response: 'Antwort',
    source: 'Quelle',
    score: 'Punktzahl',
    time: 'Zeit',
  },
  initSetup: {
    title: 'Initialeinrichtung Antwort Anmerkung',
    configTitle: 'Einrichtung Antwort Anmerkung',
    confirmBtn: 'Speichern & Aktivieren',
    configConfirmBtn: 'Speichern',
  },
  embeddingModelSwitchTip: 'Anmerkungstext-Vektorisierungsmodell, das Wechseln von Modellen wird neu eingebettet, was zusätzliche Kosten verursacht.',
}

export default translation
