const translation = {
  currentPlan: '현재 요금제',
  upgradeBtn: {
    plain: '요금제 업그레이드',
    encourage: '지금 업그레이드',
    encourageShort: '업그레이드',
  },
  viewBilling: '청구 및 구독 관리',
  buyPermissionDeniedTip: '구독하려면 엔터프라이즈 관리자에게 문의하세요',
  plansCommon: {
    title: '당신에게 맞는 요금제를 선택하세요',
    yearlyTip: '연간 구독 시 2개월 무료!',
    mostPopular: '가장 인기 있는',
    planRange: {
      monthly: '월간',
      yearly: '연간',
    },
    month: '월',
    year: '년',
    save: '절약 ',
    free: '무료',
    currentPlan: '현재 요금제',
    contractSales: '영업에 문의하기',
    contractOwner: '팀 관리자에게 문의하기',
    startForFree: '무료로 시작하기',
    getStartedWith: '시작하기 ',
    contactSales: '영업에 문의하기',
    talkToSales: '영업과 상담하기',
    modelProviders: '모델 제공자',
    teamMembers: '팀 멤버',
    buildApps: '앱 만들기',
    vectorSpace: '벡터 공간',
    vectorSpaceBillingTooltip: '1MB당 약 120만 글자의 벡터화된 데이터를 저장할 수 있습니다 (OpenAI Embeddings을 기반으로 추정되며 모델에 따라 다릅니다).',
    vectorSpaceTooltip: '벡터 공간은 LLM이 데이터를 이해하는 데 필요한 장기 기억 시스템입니다.',
    documentProcessingPriority: '문서 처리 우선순위',
    documentProcessingPriorityTip: '더 높은 문서 처리 우선순위를 원하시면 요금제를 업그레이드하세요.',
    documentProcessingPriorityUpgrade: '더 높은 정확성과 빠른 속도로 데이터를 처리합니다.',
    priority: {
      'standard': '표준',
      'priority': '우선',
      'top-priority': '최우선',
    },
    logsHistory: '로그 기록',
    customTools: '사용자 정의 도구',
    unavailable: '사용 불가',
    days: '일',
    unlimited: '무제한',
    support: '지원',
    supportItems: {
      communityForums: '커뮤니티 포럼',
      emailSupport: '이메일 지원',
      priorityEmail: '우선 이메일 및 채팅 지원',
      logoChange: '로고 변경',
      SSOAuthentication: 'SSO 인증',
      personalizedSupport: '개별 지원',
      dedicatedAPISupport: '전용 API 지원',
      customIntegration: '사용자 정의 통합 및 지원',
      ragAPIRequest: 'RAG API 요청',
      agentMode: '에이전트 모드',
      workflow: '워크플로우',
    },
    comingSoon: '곧 출시 예정',
    member: '멤버',
    memberAfter: '멤버',
    messageRequest: {
      title: '메시지 크레딧',
      tooltip: 'GPT 제외 다양한 요금제에서의 메시지 호출 쿼터 (gpt4 제외). 제한을 초과하는 메시지는 OpenAI API 키를 사용합니다.',
    },
    annotatedResponse: {
      title: '주석 응답 쿼터',
      tooltip: '수동으로 편집 및 응답 주석 달기로 앱의 사용자 정의 가능한 고품질 질의응답 기능을 제공합니다 (채팅 앱에만 해당).',
    },
    ragAPIRequestTooltip: 'Dify의 지식베이스 처리 기능을 호출하는 API 호출 수를 나타냅니다.',
    receiptInfo: '팀 소유자 및 팀 관리자만 구독 및 청구 정보를 볼 수 있습니다',
  },
  plans: {
    sandbox: {
      name: '샌드박스',
      description: 'GPT 무료 체험 200회',
      includesTitle: '포함된 항목:',
    },
    professional: {
      name: '프로페셔널',
      description: '개인 및 소규모 팀을 위해 더 많은 파워를 저렴한 가격에 제공합니다.',
      includesTitle: '무료 플랜에 추가로 포함된 항목:',
    },
    team: {
      name: '팀',
      description: '제한 없이 협업하고 최고의 성능을 누리세요.',
      includesTitle: '프로페셔널 플랜에 추가로 포함된 항목:',
    },
    enterprise: {
      name: '엔터프라이즈',
      description: '대규모 미션 크리티컬 시스템을 위한 완전한 기능과 지원을 제공합니다.',
      includesTitle: '팀 플랜에 추가로 포함된 항목:',
    },
  },
  vectorSpace: {
    fullTip: '벡터 공간이 가득 찼습니다.',
    fullSolution: '더 많은 공간을 얻으려면 요금제를 업그레이드하세요.',
  },
  apps: {
    fullTipLine1: '더 많은 앱을 생성하려면,',
    fullTipLine2: '요금제를 업그레이드하세요.',
  },
  annotatedResponse: {
    fullTipLine1: '더 많은 대화를 주석 처리하려면,',
    fullTipLine2: '요금제를 업그레이드하세요.',
    quotaTitle: '주석 응답 쿼터',
  },
}

export default translation
