const translation = {
  detail: {
    operation: {
      like: 'полезный',
      dislike: 'бесполезный',
    },
  },
}

export default translation
