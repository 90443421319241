const translation = {
  apiServer: 'API Server',
  apiKey: 'API Key',
  status: 'Status',
  disabled: 'Disabled',
  ok: 'Running',
  copy: 'Copy',
  copied: 'Copied',
  play: 'Play',
  pause: 'Pause',
  playing: 'Playing',
  loading: 'Loading',
  merMaind: {
    rerender: 'Rerender',
  },
  never: 'Never',
  apiKeyModal: {
    apiSecretKey: 'API Secret Key',
    apiSecretKeyTips: 'To prevent your API from being abused, please keep your API key safe :) Best practice is to avoid hardcoding it in the frontend code.',
    createNewSecretKey: 'Create Secret Key',
    secretKey: 'Secret Key',
    created: 'Created At',
    lastUsed: 'Last Used',
    generateTips: 'Please store this key in a secure and accessible place.',
  },
  actionMsg: {
    deleteConfirmTitle: 'Delete this key?',
    deleteConfirmTips: 'Deleting the key cannot be undone and will affect any applications currently using it.',
    ok: 'OK',
  },
  completionMode: {
    title: 'Text Generation Application API',
    info: 'Used for text generation applications such as article generation, summarization, translation, etc. By calling the completion-messages API, user inputs are sent to get generated text results. The model parameters and prompt templates for generating texts depend on the settings in the Dify prompt orchestration page.',
    createCompletionApi: 'Create Text Completion Messages',
    createCompletionApiTip: 'Create text completion messages in Q&A mode.',
    inputsTips: '(Optional) Provide user input fields in key-value pairs, corresponding to variables in the prompt orchestration. Key is the variable name, and Value is the parameter value. If the field type is Select, the passed Value should be one of the preset options.',
    queryTips: 'User input text content.',
    blocking: 'Blocking: wait for execution to complete before returning the result. (Requests with long processes may be interrupted)',
    streaming: 'Streaming: returns results in a streaming manner. Implemented using SSE (Server-Sent Events).',
    messageFeedbackApi: 'Message Feedback (Like/Dislike)',
    messageFeedbackApiTip: 'Represents the end user’s evaluation of the returned message, with options to like or dislike. This data will be visible in the “Log & Annotation” page and used for subsequent model fine-tuning.',
    messageIDTip: 'Message ID',
    ratingTip: 'like or dislike, leave empty to cancel',
    parametersApi: 'Fetch Application Configuration',
    parametersApiTip: 'Fetch configured Input parameters, including variable names, field names, types, and default values. Typically used to display these fields in forms or fill in default values on the client side after loading.',
  },
  chatMode: {
    title: 'Conversation Application API',
    info: 'Used for most conversational applications in a Q&A mode with continuous interaction with the user. To start a conversation, call the chat-messages API. To continue a conversation, pass in the returned conversation_id.',
    createChatApi: 'Send Conversation Message',
    createChatApiTip: 'Create conversation message or continue sending messages based on a previous conversation.',
    inputsTips: '(Optional) Provide user input fields in key-value pairs, corresponding to variables in the prompt orchestration. Key is the variable name, and Value is the parameter value. If the field type is Select, the passed Value should be one of the preset options.',
    queryTips: 'User input/question content',
    blocking: 'Blocking: wait for execution to complete before returning the result. (Requests with long processes may be interrupted)',
    streaming: 'Streaming: returns results in a streaming manner. Implemented using SSE (Server-Sent Events).',
    conversationIdTip: '(Optional) Conversation identifier. Leave empty for the first conversation; for continued conversations, pass in the conversation_id returned from the context.',
    messageFeedbackApi: 'Message Feedback (Like/Dislike)',
    messageFeedbackApiTip: 'Represents the end user’s evaluation of the returned message, with options to like or dislike. This data will be visible in the “Log & Annotation” page and used for subsequent model fine-tuning.',
    messageIDTip: 'Message ID',
    ratingTip: 'like or dislike, leave empty to cancel',
    chatMsgHistoryApi: 'Fetch Conversation History Messages',
    chatMsgHistoryApiTip: 'Returns historical chat records in scroll-loading form, with the first page returning the newest `limit` records in reverse order.',
    chatMsgHistoryConversationIdTip: 'Conversation ID',
    chatMsgHistoryFirstId: 'ID of the first chat record on the current page, default none',
    chatMsgHistoryLimit: 'Number of chat records returned per request',
    conversationsListApi: 'Fetch Conversation List',
    conversationsListApiTip: 'Fetch the conversation list for the current user, returning the latest 20 records by default.',
    conversationsListFirstIdTip: 'ID of the first record on the current page, default none',
    conversationsListLimitTip: 'Number of records returned per request',
    conversationRenamingApi: 'Rename Conversation',
    conversationRenamingApiTip: 'Rename the conversation. The conversation name is used to display in clients supporting multiple conversations.',
    conversationRenamingNameTip: 'New Name',
    parametersApi: 'Fetch Application Configuration',
    parametersApiTip: 'Fetch configured Input parameters, including variable names, field names, types, and default values. Typically used to display these fields in forms or fill in default values on the client side after loading.',
  },
  develop: {
    requestBody: 'Request Body',
    pathParams: 'Path Params',
    query: 'Query',
  },
}

export default translation
