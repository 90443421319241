const translation = {
  title: 'Knowledge Base Settings',
  desc: 'Here you can modify the working mode of the knowledge base and other settings.',
  form: {
    name: 'Knowledge Base Name',
    namePlaceholder: 'Please enter the knowledge base name',
    nameError: 'Name cannot be empty',
    desc: 'Knowledge Base Description',
    descInfo: 'Please provide a clear written description to summarize the content of the knowledge base. This description will be used as the basis for matching when selecting from multiple knowledge bases.',
    descPlaceholder: 'Describe the content of this knowledge base. Detailed descriptions allow AI to access the knowledge base content in a timely manner. If left empty, Dify will use the default hit strategy.',
    descWrite: 'Learn how to write better knowledge base descriptions.',
    permissions: 'Permissions',
    permissionsOnlyMe: 'Only me',
    permissionsAllMember: 'All team members',
    permissionsInvitedMembers: 'Partial team members',
    me: '(You)',
    indexMethod: 'Index Method',
    indexMethodHighQuality: 'High Quality',
    indexMethodHighQualityTip: 'Call Embedding model for processing to provide higher accuracy when users query.',
    indexMethodEconomy: 'Economical',
    indexMethodEconomyTip: 'Use offline vector engines, keyword indexes, etc. to reduce accuracy without spending tokens',
    embeddingModel: 'Embedding Model',
    embeddingModelTip: 'To modify the embedding model, please go to',
    embeddingModelTipLink: 'Settings',
    retrievalSetting: {
      title: 'Retrieval Settings',
      learnMore: 'Learn More',
      description: 'About the retrieval methods.',
      longDescription: 'You can change these retrieval settings at any time in the knowledge base settings.',
    },
    save: 'Save',
    datasetDocumentMetadata: {
      title: 'Document Metadata',
      switch: 'Whether to enable document metadata filtering function',
      config: 'Document Metadata Configuration',
      description: 'Please enter the description',
    },
  },
}

export default translation
