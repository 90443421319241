const translation = {
  knowledgeSupplement: {
    title: 'ज्ञान पूरक',
    description: 'उपयोगकर्ताओं के ज्ञान अंतराल को संक्षेप में बताएं, हर सोमवार को अपडेट किया जाता है। पूरक के बाद, AI दिए गए उत्तरों का उपयोग समान प्रश्नों का उत्तर देने के लिए करेगा, जिससे उत्तरों की गुणवत्ता में सुधार होगा।',
    noData: {
      title: 'यहां खाली है',
      description: 'अभी के लिए पूरक करने के लिए कोई ज्ञान नहीं है। आप बातचीत रिकॉर्ड में AI के प्रदर्शन पर नजर रख सकते हैं।',
    },
    saveSuccess: 'ज्ञान पूरक सफलतापूर्वक सहेजा गया',
    valid: {
      wordLimit: 'उत्तर सामग्री 2000 वर्णों तक हो सकती है। कृपया सामग्री को सहेजने से पहले छोटा करें।',
    },
  },
  pending: {
    title: 'नकारात्मक प्रतिक्रिया अनुकूलन',
    description: 'AI प्रतिक्रियाओं को रिकॉर्ड करता है जिनसे उपयोगकर्ता संतुष्ट नहीं थे। अनुकूलन के बाद, AI दिए गए उत्तरों का उपयोग समान प्रश्नों का उत्तर देने के लिए करेगा, जिससे उत्तरों की गुणवत्ता में सुधार होगा।',
    noData: {
      title: 'यहां खाली है',
      description: 'अभी के लिए कोई उपयोगकर्ता शिकायतें नहीं हैं। आप बातचीत रिकॉर्ड में AI के प्रदर्शन पर नजर रख सकते हैं।',
    },
    checkConversation: 'पूरी बातचीत देखें',
    saveSuccess: 'प्रश्नोत्तर अनुकूलन सफलतापूर्वक सहेजा गया',
  },
  optimized: {
    title: 'अनुकूलित',
    description: 'अनुकूलित प्रश्नोत्तर को रिकॉर्ड करता है; आप यहां प्रश्नोत्तर सामग्री को समायोजित कर सकते हैं और AI के उपयोग इतिहास को देख सकते हैं।',
    noData: {
      title: 'यहां खाली है',
      description: 'आप लंबित सूची या बातचीत रिकॉर्ड की जांच कर सकते हैं ताकि असंतोषजनक AI प्रतिक्रियाओं को अनुकूलित किया जा सके।',
    },
    viewModal: {
      optimizedTitle: 'अनुकूलित प्रश्नोत्तर',
      historyTitle: 'AI उपयोग इतिहास',
    },
  },
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      userQuestion: 'उपयोगकर्ता प्रश्न',
      aiAnswer: 'AI प्रतिक्रिया',
      question: 'प्रश्न',
      answer: 'उत्तर',
      createdAt: 'निर्माण समय',
      hits: 'AI उपयोग गणना',
      actions: 'क्रियाएँ',
      time: 'बातचीत का समय',
      questionCount: 'प्रश्न गणना',
    },
    pagination: {
      previous: 'पिछला पृष्ठ',
      next: 'अगला पृष्ठ',
    },
  },
  confirm: {
    dismiss: 'इस प्रश्न को खारिज करें',
    delete: 'इस अनुकूलन को हटाएं',
  },
  operation: {
    edit: 'इस प्रतिक्रिया को अनुकूलित करें',
    reEdit: 'अनुकूलन सामग्री संपादित करें',
    save: 'अनुकूलन सहेजें',
  },
  editModal: {
    question: 'प्रश्न',
    answer: 'उत्तर',
    queryName: 'उपयोगकर्ता प्रश्न',
    answerName: 'AI प्रतिक्रिया',
    yourAnswer: 'अनुकूलित प्रतिक्रिया',
    answerPlaceholder: 'यहां वांछित AI प्रतिक्रिया दर्ज करें',
    yourQuery: 'अनुकूलित प्रश्न',
    queryPlaceholder: 'उपयोगकर्ता प्रश्न को अधिक सामान्य और स्पष्ट प्रश्न में पुनः वाक्यांशित करें, जो AI को समान प्रश्नों को बेहतर ढंग से मिलाने में मदद करता है',
  },
  log: {
    optimizationLog: 'अनुकूलन जोड़ा गया',
    optimized: 'इस प्रतिक्रिया ने अनुकूलित सामग्री का उपयोग किया',
  },
}

export default translation
