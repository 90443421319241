const translation = {
  list: {
    title: 'ドキュメント',
    desc: 'ナレッジのすべてのファイルがここに表示され、ナレッジ全体がDifyの引用やチャットプラグインを介してリンクされるか、インデックス化されることができます。',
    addFile: 'ファイルを追加',
    addPages: 'ページを追加',
    addUrl: 'URLを追加',
    table: {
      header: {
        fileName: 'ファイル名',
        words: '単語数',
        hitCount: '検索回数',
        uploadTime: 'アップロード時間',
        status: 'ステータス',
        action: 'アクション',
      },
      rename: '名前を変更',
      name: '名前',
    },
    action: {
      uploadFile: '新しいファイルをアップロード',
      settings: 'セグメント設定',
      addButton: 'チャンクを追加',
      add: 'チャンクを追加',
      batchAdd: '一括追加',
      archive: 'アーカイブ',
      unarchive: 'アーカイブ解除',
      delete: '削除',
      enableWarning: 'アーカイブされたファイルは有効にできません',
      sync: '同期',
    },
    index: {
      enable: '有効にする',
      disable: '無効にする',
      all: 'すべて',
      enableTip: 'ファイルをインデックス化できます',
      disableTip: 'ファイルをインデックス化できません',
    },
    status: {
      queuing: 'キューイング中',
      indexing: 'インデックス化中',
      paused: '一時停止中',
      error: 'エラー',
      available: '利用可能',
      enabled: '有効',
      disabled: '無効',
      archived: 'アーカイブ済み',
    },
    empty: {
      title: 'まだドキュメントがありません',
      upload: {
        tip: 'ファイルをアップロードしたり、ウェブサイトから同期したり、NotionやGitHubなどのウェブアプリから同期することができます。',
      },
      sync: {
        tip: 'Difyは定期的にNotionからファイルをダウンロードし、処理を完了します。',
      },
    },
    delete: {
      title: '本当に削除しますか？',
      content: '後で処理を再開する必要がある場合は、中断した場所から続行します。',
    },
    batchModal: {
      title: '一括追加',
      csvUploadTitle: 'CSVファイルをここにドラッグアンドドロップするか、',
      browse: '参照',
      tip: 'CSVファイルは次の構造に準拠する必要があります:',
      question: '質問',
      answer: '回答',
      contentTitle: 'チャンクの内容',
      content: '内容',
      template: 'テンプレートをここからダウンロード',
      cancel: 'キャンセル',
      run: '一括実行',
      runError: '一括実行に失敗しました',
      processing: '一括処理中',
      completed: 'インポート完了',
      error: 'インポートエラー',
      ok: 'OK',
    },
  },
  metadata: {
    title: 'メタデータ',
    desc: 'ドキュメントのメタデータにラベルを付けることで、AIがタイムリーにアクセスできるようになり、ユーザーに参照元が公開されます。',
    dateTimeFormat: 'YYYY年M月D日 hh:mm A',
    docTypeSelectTitle: 'ドキュメントタイプを選択してください',
    docTypeChangeTitle: 'ドキュメントタイプを変更',
    docTypeSelectWarning:
      'ドキュメントタイプを変更すると、現在入力されているメタデータは保持されなくなります',
    firstMetaAction: '始めましょう',
    placeholder: {
      add: '追加',
      select: '選択',
    },
    source: {
      upload_file: 'ファイルをアップロード',
      notion: 'Notionから同期',
      github: 'GitHubから同期',
    },
    type: {
      book: '書籍',
      webPage: 'ウェブページ',
      paper: '論文',
      socialMediaPost: 'ソーシャルメディアの投稿',
      personalDocument: '個人のドキュメント',
      businessDocument: 'ビジネスドキュメント',
      IMChat: 'IMチャット',
      wikipediaEntry: 'Wikipediaのエントリー',
      notion: 'Notionから同期',
      github: 'GitHubから同期',
      technicalParameters: '技術的なパラメータ',
    },
    field: {
      processRule: {
        processDoc: 'ドキュメントの処理',
        segmentRule: 'チャンクのルール',
        segmentLength: 'チャンクの長さ',
        processClean: 'テキストの前処理',
      },
      book: {
        title: 'タイトル',
        language: '言語',
        author: '著者',
        publisher: '出版社',
        publicationDate: '出版日',
        ISBN: 'ISBN',
        category: 'カテゴリ',
      },
      webPage: {
        title: 'タイトル',
        url: 'URL',
        language: '言語',
        authorPublisher: '著者/出版社',
        publishDate: '公開日',
        topicsKeywords: 'トピック/キーワード',
        description: '説明',
      },
      paper: {
        title: 'タイトル',
        language: '言語',
        author: '著者',
        publishDate: '公開日',
        journalConferenceName: 'ジャーナル/会議名',
        volumeIssuePage: '巻号ページ',
        DOI: 'DOI',
        topicsKeywords: 'トピック/キーワード',
        abstract: '要約',
      },
      socialMediaPost: {
        platform: 'プラットフォーム',
        authorUsername: '著者/ユーザー名',
        publishDate: '公開日',
        postURL: '投稿URL',
        topicsTags: 'トピック/タグ',
      },
      personalDocument: {
        title: 'タイトル',
        author: '著者',
        creationDate: '作成日',
        lastModifiedDate: '最終更新日',
        documentType: 'ドキュメントタイプ',
        tagsCategory: 'タグ/カテゴリ',
      },
      businessDocument: {
        title: 'タイトル',
        author: '著者',
        creationDate: '作成日',
        lastModifiedDate: '最終更新日',
        documentType: 'ドキュメントタイプ',
        departmentTeam: '部署/チーム',
      },
      IMChat: {
        chatPlatform: 'チャットプラットフォーム',
        chatPartiesGroupName: 'チャット参加者/グループ名',
        participants: '参加者',
        startDate: '開始日',
        endDate: '終了日',
        topicsKeywords: 'トピック/キーワード',
        fileType: 'ファイルタイプ',
      },
      wikipediaEntry: {
        title: 'タイトル',
        language: '言語',
        webpageURL: 'ウェブページURL',
        editorContributor: '編集者/寄稿者',
        lastEditDate: '最終編集日',
        summaryIntroduction: '概要/紹介',
      },
      notion: {
        title: 'タイトル',
        language: '言語',
        author: '著者',
        createdTime: '作成日時',
        lastModifiedTime: '最終更新日時',
        url: 'URL',
        tag: 'タグ',
        description: '説明',
      },
      github: {
        repoName: 'リポジトリ名',
        repoDesc: 'リポジトリの説明',
        repoOwner: 'リポジトリの所有者',
        fileName: 'ファイル名',
        filePath: 'ファイルパス',
        programmingLang: 'プログラミング言語',
        url: 'URL',
        license: 'ライセンス',
        lastCommitTime: '最終コミット時刻',
        lastCommitAuthor: '最終コミットの著者',
      },
      originInfo: {
        originalFilename: '元のファイル名',
        originalFileSize: '元のファイルサイズ',
        uploadDate: 'アップロード日',
        lastUpdateDate: '最終更新日',
        source: 'ソース',
      },
      technicalParameters: {
        segmentSpecification: 'チャンクの仕様',
        segmentLength: 'チャンクの長さ',
        avgParagraphLength: '平均段落長',
        paragraphs: '段落',
        hitCount: '検索回数',
        embeddingTime: '埋め込み時間',
        embeddedSpend: '埋め込みコスト',
      },
    },
    languageMap: {
      zh: '中国語',
      en: '英語',
      es: 'スペイン語',
      fr: 'フランス語',
      de: 'ドイツ語',
      ja: '日本語',
      ko: '韓国語',
      ru: 'ロシア語',
      ar: 'アラビア語',
      pt: 'ポルトガル語',
      it: 'イタリア語',
      nl: 'オランダ語',
      pl: 'ポーランド語',
      sv: 'スウェーデン語',
      tr: 'トルコ語',
      he: 'ヘブライ語',
      hi: 'ヒンディー語',
      da: 'デンマーク語',
      fi: 'フィンランド語',
      no: 'ノルウェー語',
      hu: 'ハンガリー語',
      el: 'ギリシャ語',
      cs: 'チェコ語',
      th: 'タイ語',
      id: 'インドネシア語',
    },
    categoryMap: {
      book: {
        fiction: 'フィクション',
        biography: '伝記',
        history: '歴史',
        science: '科学',
        technology: 'テクノロジー',
        education: '教育',
        philosophy: '哲学',
        religion: '宗教',
        socialSciences: '社会科学',
        art: 'アート',
        travel: '旅行',
        health: '健康',
        selfHelp: '自己啓発',
        businessEconomics: 'ビジネス・経済',
        cooking: '料理',
        childrenYoungAdults: '子供・若者向け',
        comicsGraphicNovels: 'コミック・グラフィックノベル',
        poetry: '詩',
        drama: 'ドラマ',
        other: 'その他',
      },
      personalDoc: {
        notes: 'メモ',
        blogDraft: 'ブログの下書き',
        diary: '日記',
        researchReport: '研究レポート',
        bookExcerpt: '書籍の抜粋',
        schedule: 'スケジュール',
        list: 'リスト',
        projectOverview: 'プロジェクトの概要',
        photoCollection: '写真コレクション',
        creativeWriting: '創作',
        codeSnippet: 'コードスニペット',
        designDraft: 'デザインの下書き',
        personalResume: '履歴書',
        other: 'その他',
      },
      businessDoc: {
        meetingMinutes: '会議議事録',
        researchReport: '研究レポート',
        proposal: '提案',
        employeeHandbook: '従業員ハンドブック',
        trainingMaterials: '研修資料',
        requirementsDocument: '要件定義書',
        designDocument: '設計書',
        productSpecification: '製品仕様書',
        financialReport: '財務報告書',
        marketAnalysis: '市場分析',
        projectPlan: 'プロジェクト計画',
        teamStructure: 'チーム構成',
        policiesProcedures: 'ポリシーと手順',
        contractsAgreements: '契約と合意',
        emailCorrespondence: 'メールのやり取り',
        other: 'その他',
      },
    },
  },
  embedding: {
    processing: '埋め込み処理中...',
    paused: '埋め込みが一時停止中',
    completed: '埋め込みが完了しました',
    error: '埋め込みエラー',
    docName: 'ドキュメントの前処理',
    mode: 'セグメンテーションルール',
    segmentLength: 'チャンクの長さ',
    textCleaning: 'テキストの前処理',
    segments: '段落',
    highQuality: '高品質モード',
    economy: '経済モード',
    estimate: '推定消費量',
    stop: '処理を停止',
    resume: '処理を再開',
    automatic: '自動',
    custom: 'カスタム',
    previewTip: '埋め込みが完了した後、段落のプレビューが利用可能になります',
  },
  segment: {
    paragraphs: '段落',
    keywords: 'キーワード',
    addKeyWord: 'キーワードを追加',
    keywordError: 'キーワードの最大長は20です',
    characters: '文字',
    hitCount: '検索回数',
    vectorHash: 'ベクトルハッシュ: ',
    questionPlaceholder: 'ここに質問を追加',
    questionEmpty: '質問は空にできません',
    answerPlaceholder: 'ここに回答を追加',
    answerEmpty: '回答は空にできません',
    contentPlaceholder: 'ここに内容を追加',
    contentEmpty: '内容は空にできません',
    newTextSegment: '新しいテキストセグメント',
    newQaSegment: '新しいQ&Aセグメント',
    delete: 'このチャンクを削除しますか？',
  },
}

export default translation
