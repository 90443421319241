const translation = {
  createApp: 'CREAR APP',
  types: {
    all: 'Todos',
    chatbot: 'Chatbot',
    agent: 'Agente',
    workflow: 'Flujo de trabajo',
    completion: 'Finalización',
  },
  duplicate: 'Duplicar',
  duplicateTitle: 'Duplicar App',
  export: 'Exportar DSL',
  exportFailed: 'Error al exportar DSL.',
  importDSL: 'Importar archivo DSL',
  createFromConfigFile: 'Crear desde archivo DSL',
  deleteAppConfirmTitle: '¿Eliminar esta app?',
  deleteAppConfirmContent:
    'Eliminar la app es irreversible. Los usuarios ya no podrán acceder a tu app y todas las configuraciones y registros de prompts se eliminarán permanentemente.',
  appDeleted: 'App eliminada',
  appDeleteFailed: 'Error al eliminar app',
  join: 'Únete a la comunidad',
  communityIntro:
    'Discute con miembros del equipo, colaboradores y desarrolladores en diferentes canales.',
  roadmap: 'Ver nuestro plan de desarrollo',
  newApp: {
    startFromBlank: 'Crear desde cero',
    startFromTemplate: 'Crear desde plantilla',
    captionAppType: '¿Qué tipo de app quieres crear?',
    chatbotDescription: 'Crea una aplicación basada en chat. Esta app utiliza un formato de pregunta y respuesta, permitiendo múltiples rondas de conversación continua.',
    completionDescription: 'Crea una aplicación que genera texto de alta calidad basado en prompts, como la generación de artículos, resúmenes, traducciones y más.',
    completionWarning: 'Este tipo de app ya no será compatible.',
    agentDescription: 'Crea un Agente inteligente que puede elegir herramientas de forma autónoma para completar tareas',
    workflowDescription: 'Crea una aplicación que genera texto de alta calidad basado en flujos de trabajo con un alto grado de personalización. Es adecuado para usuarios experimentados.',
    workflowWarning: 'Actualmente en beta',
    chatbotType: 'Método de orquestación del Chatbot',
    basic: 'Básico',
    basicTip: 'Para principiantes, se puede cambiar a Chatflow más adelante',
    basicFor: 'PARA PRINCIPIANTES',
    basicDescription: 'La Orquestación Básica permite la orquestación de una app de Chatbot utilizando configuraciones simples, sin la capacidad de modificar los prompts incorporados. Es adecuado para principiantes.',
    advanced: 'Chatflow',
    advancedFor: 'Para usuarios avanzados',
    advancedDescription: 'La Orquestación de Flujo de Trabajo orquesta Chatbots en forma de flujos de trabajo, ofreciendo un alto grado de personalización, incluida la capacidad de editar los prompts incorporados. Es adecuado para usuarios experimentados.',
    captionName: 'Icono y nombre de la app',
    appNamePlaceholder: 'Asigna un nombre a tu app',
    captionDescription: 'Descripción',
    appDescriptionPlaceholder: 'Ingresa la descripción de la app',
    useTemplate: 'Usar esta plantilla',
    previewDemo: 'Vista previa de demostración',
    chatApp: 'Asistente',
    chatAppIntro:
      'Quiero construir una aplicación basada en chat. Esta app utiliza un formato de pregunta y respuesta, permitiendo múltiples rondas de conversación continua.',
    agentAssistant: 'Nuevo Asistente de Agente',
    completeApp: 'Generador de Texto',
    completeAppIntro:
      'Quiero crear una aplicación que genera texto de alta calidad basado en prompts, como la generación de artículos, resúmenes, traducciones y más.',
    showTemplates: 'Quiero elegir una plantilla',
    hideTemplates: 'Volver a la selección de modo',
    Create: 'Crear',
    Cancel: 'Cancelar',
    nameNotEmpty: 'El nombre no puede estar vacío',
    appTemplateNotSelected: 'Por favor, selecciona una plantilla',
    appTypeRequired: 'Por favor, selecciona un tipo de app',
    appCreated: 'App creada',
    appCreateFailed: 'Error al crear app',
  },
  editApp: 'Editar información',
  editAppTitle: 'Editar información de la app',
  editDone: 'Información de la app actualizada',
  editFailed: 'Error al actualizar información de la app',
  iconPicker: {
    ok: 'OK',
    cancel: 'Cancelar',
    emoji: 'Emoji',
    image: 'Imagen',
  },
  switch: 'Cambiar a Orquestación de Flujo de Trabajo',
  switchTipStart: 'Se creará una nueva copia de la app para ti y la nueva copia cambiará a Orquestación de Flujo de Trabajo. La nueva copia no permitirá',
  switchTip: 'volver',
  switchTipEnd: ' a la Orquestación Básica.',
  switchLabel: 'La copia de la app a crear',
  removeOriginal: 'Eliminar la app original',
  switchStart: 'Iniciar cambio',
  typeSelector: {
    all: 'Todos los tipos',
    chatbot: 'Chatbot',
    agent: 'Agente',
    workflow: 'Flujo de trabajo',
    completion: 'Finalización',
  },
  tracing: {
    title: 'Rastreo del rendimiento de la app',
    description: 'Configuración de un proveedor de LLMOps de terceros y rastreo del rendimiento de la app.',
    config: 'Configurar',
    collapse: 'Contraer',
    expand: 'Expandir',
    tracing: 'Rastreo',
    disabled: 'Deshabilitado',
    disabledTip: 'Por favor, configura el proveedor primero',
    enabled: 'En servicio',
    tracingDescription: 'Captura el contexto completo de la ejecución de la app, incluyendo llamadas LLM, contexto, prompts, solicitudes HTTP y más, en una plataforma de rastreo de terceros.',
    configProviderTitle: {
      configured: 'Configurado',
      notConfigured: 'Configurar proveedor para habilitar el rastreo',
      moreProvider: 'Más proveedores',
    },
    langsmith: {
      title: 'LangSmith',
      description: 'Una plataforma de desarrollo todo en uno para cada paso del ciclo de vida de la aplicación impulsada por LLM.',
    },
    langfuse: {
      title: 'Langfuse',
      description: 'Rastrea, evalúa, gestiona prompts y métricas para depurar y mejorar tu aplicación LLM.',
    },
    inUse: 'En uso',
    configProvider: {
      title: 'Configurar ',
      placeholder: 'Ingresa tu {{key}}',
      project: 'Proyecto',
      publicKey: 'Clave pública',
      secretKey: 'Clave secreta',
      viewDocsLink: 'Ver documentación de {{key}}',
      removeConfirmTitle: '¿Eliminar la configuración de {{key}}?',
      removeConfirmContent: 'La configuración actual está en uso, eliminarla desactivará la función de rastreo.',
    },
  },
}

export default translation
