const translation = {
  common: {
    undo: 'Hoàn tác',
    redo: 'Làm lại',
    editing: 'Đang chỉnh sửa',
    autoSaved: 'Đã tự động lưu',
    unpublished: 'Chưa xuất bản',
    published: 'Đã xuất bản',
    publish: 'Xuất bản',
    update: 'Cập nhật',
    run: 'Chạy',
    running: 'Đang chạy',
    inRunMode: 'Chế độ chạy',
    inPreview: 'Trong chế độ xem trước',
    inPreviewMode: 'Chế độ xem trước',
    preview: 'Xem trước',
    viewRunHistory: 'Xem lịch sử chạy',
    runHistory: 'Lịch sử chạy',
    goBackToEdit: 'Quay lại trình chỉnh sửa',
    conversationLog: 'Nhật ký cuộc trò chuyện',
    features: 'Tính năng',
    debugAndPreview: 'Gỡ lỗi và xem trước',
    restart: 'Khởi động lại',
    currentDraft: 'Bản nháp hiện tại',
    currentDraftUnpublished: 'Bản nháp hiện tại chưa xuất bản',
    latestPublished: 'Xuất bản mới nhất',
    publishedAt: 'Đã xuất bản lúc',
    restore: 'Khôi phục',
    runApp: 'Chạy ứng dụng',
    batchRunApp: 'Chạy ứng dụng hàng loạt',
    accessAPIReference: 'Truy cập tài liệu API',
    embedIntoSite: 'Nhúng vào trang web',
    addTitle: 'Thêm tiêu đề...',
    addDescription: 'Thêm mô tả...',
    noVar: 'Không có biến',
    searchVar: 'Tìm kiếm biến',
    variableNamePlaceholder: 'Tên biến',
    setVarValuePlaceholder: 'Đặt giá trị biến',
    needConnecttip: 'Bước này không được kết nối với bất kỳ điều gì',
    maxTreeDepth: 'Giới hạn tối đa {{depth}} nút trên mỗi nhánh',
    needEndNode: 'Phải thêm khối Kết thúc',
    needAnswerNode: 'Phải thêm khối Trả lời',
    workflowProcess: 'Quy trình làm việc',
    notRunning: 'Chưa chạy',
    previewPlaceholder: 'Nhập nội dung vào hộp bên dưới để bắt đầu gỡ lỗi Chatbot',
    effectVarConfirm: {
      title: 'Xóa biến',
      content: 'Biến được sử dụng trong các nút khác. Bạn có chắc chắn muốn xóa nó không?',
    },
    insertVarTip: 'Nhấn phím \'/\' để chèn nhanh',
    processData: 'Xử lý dữ liệu',
    input: 'Đầu vào',
    output: 'Đầu ra',
    jinjaEditorPlaceholder: 'Gõ \'/\' hoặc \'{\' để chèn biến',
    viewOnly: 'Chỉ xem',
    showRunHistory: 'Hiển thị lịch sử chạy',
    enableJinja: 'Bật hỗ trợ mẫu Jinja',
    learnMore: 'Tìm hiểu thêm',
    copy: 'Sao chép',
    duplicate: 'Nhân bản',
    addBlock: 'Thêm khối',
    pasteHere: 'Dán vào đây',
    pointerMode: 'Chế độ con trỏ',
    handMode: 'Chế độ tay',
    model: 'Mô hình',
    workflowAsTool: 'Quy trình làm việc như công cụ',
    configureRequired: 'Yêu cầu cấu hình',
    configure: 'Cấu hình',
    manageInTools: 'Quản lý trong công cụ',
    workflowAsToolTip: 'Cần cấu hình lại công cụ sau khi cập nhật quy trình làm việc.',
    viewDetailInTracingPanel: 'Xem chi tiết',
  },
  env: {
    envPanelTitle: 'Biến Môi Trường',
    envDescription: 'Biến môi trường có thể được sử dụng để lưu trữ thông tin cá nhân và thông tin xác thực. Chúng chỉ được đọc và có thể được tách khỏi tệp DSL trong quá trình xuất.',
    envPanelButton: 'Thêm Biến',
    modal: {
      title: 'Thêm Biến Môi Trường',
      editTitle: 'Sửa Biến Môi Trường',
      type: 'Loại',
      name: 'Tên',
      namePlaceholder: 'tên môi trường',
      value: 'Giá trị',
      valuePlaceholder: 'giá trị môi trường',
      secretTip: 'Được sử dụng để xác định thông tin hoặc dữ liệu nhạy cảm, với cài đặt DSL được cấu hình để ngăn chặn rò rỉ.',
    },
    export: {
      title: 'Xuất biến môi trường bí mật?',
      checkbox: 'Xuất giá trị bí mật',
      ignore: 'Xuất DSL',
      export: 'Xuất DSL với giá trị bí mật',
    },
  },
  chatVariable: {
    panelTitle: 'Biến Hội Thoại',
    panelDescription: 'Biến Hội Thoại được sử dụng để lưu trữ thông tin tương tác mà LLM cần ghi nhớ, bao gồm lịch sử hội thoại, tệp đã tải lên, tùy chọn người dùng. Chúng có thể đọc và ghi được.',
    docLink: 'Truy cập tài liệu của chúng tôi để tìm hiểu thêm.',
    button: 'Thêm Biến',
    modal: {
      title: 'Thêm Biến Hội Thoại',
      editTitle: 'Chỉnh Sửa Biến Hội Thoại',
      name: 'Tên',
      namePlaceholder: 'Tên biến',
      type: 'Loại',
      value: 'Giá Trị Mặc Định',
      valuePlaceholder: 'Giá trị mặc định, để trống nếu không đặt',
      description: 'Mô tả',
      descriptionPlaceholder: 'Mô tả biến',
      editInJSON: 'Chỉnh sửa dưới dạng JSON',
      oneByOne: 'Thêm từng cái một',
      editInForm: 'Chỉnh sửa trong Biểu mẫu',
      arrayValue: 'Giá trị',
      addArrayValue: 'Thêm Giá trị',
      objectKey: 'Khóa',
      objectType: 'Loại',
      objectValue: 'Giá Trị Mặc Định',
    },
    storedContent: 'Nội dung đã lưu',
    updatedAt: 'Cập nhật lúc ',
  },
  changeHistory: {
    title: 'Lịch sử thay đổi',
    placeholder: 'Bạn chưa thay đổi gì cả',
    clearHistory: 'Xóa lịch sử',
    hint: 'Gợi ý',
    hintText: 'Các hành động chỉnh sửa của bạn được theo dõi trong lịch sử thay đổi, được lưu trên thiết bị của bạn trong suốt phiên làm việc này. Lịch sử này sẽ bị xóa khi bạn thoát khỏi trình soạn thảo.',
    stepBackward_one: '{{count}} bước lùi',
    stepBackward_other: '{{count}} bước lùi',
    stepForward_one: '{{count}} bước tiến',
    stepForward_other: '{{count}} bước tiến',
    sessionStart: 'Bắt đầu phiên',
    currentState: 'Trạng thái hiện tại',
    nodeTitleChange: 'Tiêu đề khối đã thay đổi',
    nodeDescriptionChange: 'Mô tả khối đã thay đổi',
    nodeDragStop: 'Khối đã di chuyển',
    nodeChange: 'Khối đã thay đổi',
    nodeConnect: 'Khối đã kết nối',
    nodePaste: 'Khối đã dán',
    nodeDelete: 'Khối đã xóa',
    nodeAdd: 'Khối đã thêm',
    nodeResize: 'Khối đã thay đổi kích thước',
    noteAdd: 'Ghi chú đã thêm',
    noteChange: 'Ghi chú đã thay đổi',
    noteDelete: 'Ghi chú đã xóa',
    edgeDelete: 'Khối đã ngắt kết nối',
  },
  errorMsg: {
    fieldRequired: '{{field}} là bắt buộc',
    authRequired: 'Yêu cầu xác thực',
    invalidJson: '{{field}} là JSON không hợp lệ',
    fields: {
      variable: 'Tên biến',
      variableValue: 'Giá trị biến',
      code: 'Mã',
      model: 'Mô hình',
      rerankModel: 'Mô hình xếp hạng lại',
    },
    invalidVariable: 'Biến không hợp lệ',
  },
  singleRun: {
    testRun: 'Chạy thử nghiệm ',
    startRun: 'Bắt đầu chạy',
    running: 'Đang chạy',
    testRunIteration: 'Lặp chạy thử nghiệm',
    back: 'Quay lại',
    iteration: 'Lặp',
  },
  tabs: {
    'searchBlock': 'Tìm kiếm khối',
    'blocks': 'Khối',
    'tools': 'Công cụ',
    'allTool': 'Tất cả',
    'builtInTool': 'Tích hợp sẵn',
    'customTool': 'Tùy chỉnh',
    'workflowTool': 'Quy trình làm việc',
    'question-understand': 'Hiểu câu hỏi',
    'logic': 'Logic',
    'transform': 'Chuyển đổi',
    'utilities': 'Tiện ích',
    'noResult': 'Không tìm thấy kết quả phù hợp',
  },
  blocks: {
    'start': 'Bắt đầu',
    'end': 'Kết thúc',
    'answer': 'Trả lời',
    'llm': 'LLM',
    'knowledge-retrieval': 'Truy xuất kiến thức',
    'question-classifier': 'Phân loại câu hỏi',
    'if-else': 'NẾU/NGƯỢC LẠI',
    'code': 'Mã',
    'template-transform': 'Mẫu',
    'http-request': 'Yêu cầu HTTP',
    'variable-assigner': 'Trình gán biến',
    'variable-aggregator': 'Trình tổng hợp biến',
    'assigner': 'Trình gán biến',
    'iteration-start': 'Bắt đầu lặp',
    'iteration': 'Lặp',
    'parameter-extractor': 'Trình trích xuất tham số',
  },
  blocksAbout: {
    'start': 'Định nghĩa các tham số ban đầu để khởi chạy quy trình làm việc',
    'end': 'Định nghĩa kết thúc và loại kết quả của quy trình làm việc',
    'answer': 'Định nghĩa nội dung trả lời của cuộc trò chuyện',
    'llm': 'Gọi các mô hình ngôn ngữ lớn để trả lời câu hỏi hoặc xử lý ngôn ngữ tự nhiên',
    'knowledge-retrieval': 'Cho phép truy vấn nội dung văn bản liên quan đến câu hỏi của người dùng từ cơ sở kiến thức',
    'question-classifier': 'Định nghĩa các điều kiện phân loại câu hỏi của người dùng, LLM có thể định nghĩa cách cuộc trò chuyện tiến triển dựa trên mô tả phân loại',
    'if-else': 'Cho phép phân chia quy trình làm việc thành hai nhánh dựa trên điều kiện if/else',
    'code': 'Thực thi một đoạn mã Python hoặc NodeJS để thực hiện logic tùy chỉnh',
    'template-transform': 'Chuyển đổi dữ liệu thành chuỗi bằng cú pháp mẫu Jinja',
    'http-request': 'Cho phép gửi các yêu cầu máy chủ qua giao thức HTTP',
    'variable-assigner': 'Tổng hợp các biến từ nhiều nhánh thành một biến duy nhất để cấu hình đồng nhất các nút cuối.',
    'assigner': 'Nút gán biến được sử dụng để gán giá trị cho các biến có thể ghi (như các biến hội thoại).',
    'variable-aggregator': 'Tổng hợp các biến từ nhiều nhánh thành một biến duy nhất để cấu hình đồng nhất các nút cuối.',
    'iteration': 'Thực hiện nhiều bước trên một đối tượng danh sách cho đến khi tất cả các kết quả được xuất ra.',
    'parameter-extractor': 'Sử dụng LLM để trích xuất các tham số có cấu trúc từ ngôn ngữ tự nhiên để gọi công cụ hoặc yêu cầu HTTP.',
  },
  operator: {
    zoomIn: 'Phóng to',
    zoomOut: 'Thu nhỏ',
    zoomTo50: 'Phóng to 50%',
    zoomTo100: 'Phóng to 100%',
    zoomToFit: 'Phóng to vừa màn hình',
  },
  panel: {
    userInputField: 'Trường đầu vào của người dùng',
    changeBlock: 'Thay đổi khối',
    helpLink: 'Liên kết trợ giúp',
    about: 'Giới thiệu',
    createdBy: 'Tạo bởi ',
    nextStep: 'Bước tiếp theo',
    addNextStep: 'Thêm khối tiếp theo trong quy trình làm việc này',
    selectNextStep: 'Chọn khối tiếp theo',
    runThisStep: 'Chạy bước này',
    checklist: 'Danh sách kiểm tra',
    checklistTip: 'Đảm bảo rằng tất cả các vấn đề đã được giải quyết trước khi xuất bản',
    checklistResolved: 'Tất cả các vấn đề đã được giải quyết',
    organizeBlocks: 'Tổ chức các khối',
    change: 'Thay đổi',
    optional: '(tùy chọn)',
  },
  nodes: {
    common: {
      outputVars: 'Biến đầu ra',
      insertVarTip: 'Chèn biến',
      memory: {
        memory: 'Bộ nhớ',
        memoryTip: 'Cài đặt bộ nhớ cuộc trò chuyện',
        windowSize: 'Kích thước cửa sổ',
        conversationRoleName: 'Tên vai trò cuộc trò chuyện',
        user: 'Tiền tố người dùng',
        assistant: 'Tiền tố trợ lý',
      },
      memories: {
        title: 'Bộ nhớ',
        tip: 'Bộ nhớ cuộc trò chuyện',
        builtIn: 'Tích hợp sẵn',
      },
    },
    start: {
      required: 'bắt buộc',
      inputField: 'Trường đầu vào',
      builtInVar: 'Biến tích hợp sẵn',
      outputVars: {
        query: 'Đầu vào của người dùng',
        memories: {
          des: 'Lịch sử cuộc trò chuyện',
          type: 'loại tin nhắn',
          content: 'nội dung tin nhắn',
        },
        files: 'Danh sách tệp',
      },
      noVarTip: 'Đặt các đầu vào có thể sử dụng trong Quy trình làm việc',
    },
    end: {
      outputs: 'Đầu ra',
      output: {
        type: 'loại đầu ra',
        variable: 'biến đầu ra',
      },
      type: {
        'none': 'Không có',
        'plain-text': 'Văn bản thuần',
        'structured': 'Cấu trúc',
      },
    },
    answer: {
      answer: 'Trả lời',
      outputVars: 'Biến đầu ra',
    },
    llm: {
      model: 'mô hình',
      variables: 'biến',
      context: 'ngữ cảnh',
      contextTooltip: 'Bạn có thể nhập Kiến thức làm ngữ cảnh',
      notSetContextInPromptTip: 'Để kích hoạt tính năng ngữ cảnh, vui lòng điền biến ngữ cảnh vào PROMPT.',
      prompt: 'prompt',
      roleDescription: {
        system: 'Cung cấp hướng dẫn cấp cao cho cuộc trò chuyện',
        user: 'Cung cấp hướng dẫn, câu hỏi hoặc bất kỳ đầu vào văn bản nào cho mô hình',
        assistant: 'Các phản hồi của mô hình dựa trên tin nhắn của người dùng',
      },
      addMessage: 'Thêm tin nhắn',
      vision: 'tầm nhìn',
      files: 'Tệp',
      resolution: {
        name: 'Độ phân giải',
        high: 'Cao',
        low: 'Thấp',
      },
      outputVars: {
        output: 'Nội dung được tạo',
        usage: 'Thông tin sử dụng mô hình',
      },
      singleRun: {
        variable: 'Biến',
      },
      sysQueryInUser: 'sys.query trong tin nhắn của người dùng là bắt buộc',
    },
    knowledgeRetrieval: {
      queryVariable: 'Biến truy vấn',
      knowledge: 'Kiến thức',
      outputVars: {
        output: 'Dữ liệu phân đoạn được truy xuất',
        content: 'Nội dung phân đoạn',
        title: 'Tiêu đề phân đoạn',
        icon: 'Biểu tượng phân đoạn',
        url: 'URL phân đoạn',
        metadata: 'Siêu dữ liệu khác',
      },
    },
    http: {
      inputVars: 'Biến đầu vào',
      api: 'API',
      apiPlaceholder: 'Nhập URL, gõ ‘/’ để chèn biến',
      notStartWithHttp: 'API phải bắt đầu bằng http:// hoặc https://',
      key: 'Khóa',
      value: 'Giá trị',
      bulkEdit: 'Chỉnh sửa hàng loạt',
      keyValueEdit: 'Chỉnh sửa khóa-giá trị',
      headers: 'Tiêu đề',
      params: 'Tham số',
      body: 'Nội dung',
      outputVars: {
        body: 'Nội dung phản hồi',
        statusCode: 'Mã trạng thái phản hồi',
        headers: 'Danh sách tiêu đề phản hồi JSON',
        files: 'Danh sách tệp',
      },
      authorization: {
        'authorization': 'Ủy quyền',
        'authorizationType': 'Loại ủy quyền',
        'no-auth': 'Không có',
        'api-key': 'Khóa API',
        'auth-type': 'Loại xác thực',
        'basic': 'Cơ bản',
        'bearer': 'Bearer',
        'custom': 'Tùy chỉnh',
        'api-key-title': 'Khóa API',
        'header': 'Tiêu đề',
      },
      insertVarPlaceholder: 'gõ \'/\' để chèn biến',
      timeout: {
        title: 'Thời gian chờ',
        connectLabel: 'Thời gian chờ kết nối',
        connectPlaceholder: 'Nhập thời gian chờ kết nối tính bằng giây',
        readLabel: 'Thời gian chờ đọc',
        readPlaceholder: 'Nhập thời gian chờ đọc tính bằng giây',
        writeLabel: 'Thời gian chờ ghi',
        writePlaceholder: 'Nhập thời gian chờ ghi tính bằng giây',
      },
    },
    code: {
      inputVars: 'Biến đầu vào',
      outputVars: 'Biến đầu ra',
      advancedDependencies: 'Phụ thuộc nâng cao',
      advancedDependenciesTip: 'Thêm một số phụ thuộc được tải trước mà tốn nhiều thời gian hoặc không phải là mặc định tại đây',
      searchDependencies: 'Tìm kiếm phụ thuộc',
    },
    templateTransform: {
      inputVars: 'Biến đầu vào',
      code: 'Mã',
      codeSupportTip: 'Chỉ hỗ trợ Jinja2',
      outputVars: {
        output: 'Nội dung chuyển đổi',
      },
    },
    ifElse: {
      if: 'Nếu',
      else: 'Ngược lại',
      elseDescription: 'Sử dụng để xác định logic sẽ thực hiện khi điều kiện if không được thỏa mãn.',
      and: 'và',
      or: 'hoặc',
      operator: 'Toán tử',
      notSetVariable: 'Vui lòng đặt biến trước',
      comparisonOperator: {
        'contains': 'chứa',
        'not contains': 'không chứa',
        'start with': 'bắt đầu bằng',
        'end with': 'kết thúc bằng',
        'is': 'là',
        'is not': 'không là',
        'empty': 'trống',
        'not empty': 'không trống',
        'null': 'là null',
        'not null': 'không là null',
      },
      enterValue: 'Nhập giá trị',
      addCondition: 'Thêm điều kiện',
      conditionNotSetup: 'Điều kiện chưa được thiết lập',
    },
    variableAssigner: {
      title: 'Gán biến',
      outputType: 'Loại đầu ra',
      varNotSet: 'Biến chưa được đặt',
      noVarTip: 'Thêm các biến cần gán',
      type: {
        string: 'Chuỗi',
        number: 'Số',
        object: 'Đối tượng',
        array: 'Mảng',
      },
      aggregationGroup: 'Nhóm tổng hợp',
      aggregationGroupTip: 'Bật tính năng này cho phép trình tổng hợp biến tổng hợp nhiều bộ biến.',
      addGroup: 'Thêm nhóm',
      outputVars: {
        varDescribe: 'Đầu ra {{groupName}}',
      },
      setAssignVariable: 'Đặt biến gán',
    },
    assigner: {
      'assignedVariable': 'Biến Được Gán',
      'writeMode': 'Chế Độ Ghi',
      'writeModeTip': 'Khi BIẾN ĐƯỢC GÁN là một mảng, chế độ thêm sẽ thêm vào cuối.',
      'over-write': 'Ghi đè',
      'append': 'Thêm vào',
      'plus': 'Cộng',
      'clear': 'Xóa',
      'setVariable': 'Đặt Biến',
      'variable': 'Biến',
    },
    tool: {
      toAuthorize: 'Ủy quyền',
      inputVars: 'Biến đầu vào',
      outputVars: {
        text: 'nội dung do công cụ tạo ra',
        files: {
          title: 'tệp do công cụ tạo ra',
          type: 'Loại hỗ trợ. Hiện tại chỉ hỗ trợ hình ảnh',
          transfer_method: 'Phương pháp truyền. Giá trị là remote_url hoặc local_file',
          url: 'URL hình ảnh',
          upload_file_id: 'ID tệp đã tải lên',
        },
        json: 'JSON được tạo bởi công cụ',
      },
    },
    questionClassifiers: {
      model: 'mô hình',
      inputVars: 'Biến đầu vào',
      outputVars: {
        className: 'Tên lớp',
      },
      class: 'Lớp',
      classNamePlaceholder: 'Viết tên lớp của bạn',
      advancedSetting: 'Cài đặt nâng cao',
      topicName: 'Tên chủ đề',
      topicPlaceholder: 'Viết tên chủ đề của bạn',
      addClass: 'Thêm lớp',
      instruction: 'Hướng dẫn',
      instructionTip: 'Nhập hướng dẫn bổ sung để giúp trình phân loại câu hỏi hiểu rõ hơn về cách phân loại câu hỏi.',
      instructionPlaceholder: 'Viết hướng dẫn của bạn',
    },
    parameterExtractor: {
      inputVar: 'Biến đầu vào',
      extractParameters: 'Trích xuất tham số',
      importFromTool: 'Nhập từ công cụ',
      addExtractParameter: 'Thêm tham số trích xuất',
      addExtractParameterContent: {
        name: 'Tên',
        namePlaceholder: 'Tên tham số trích xuất',
        type: 'Loại',
        typePlaceholder: 'Loại tham số trích xuất',
        description: 'Mô tả',
        descriptionPlaceholder: 'Mô tả tham số trích xuất',
        required: 'Bắt buộc',
        requiredContent: 'Bắt buộc chỉ được sử dụng làm tài liệu tham khảo cho suy luận mô hình và không phải để xác thực bắt buộc của đầu ra tham số.',
      },
      extractParametersNotSet: 'Tham số trích xuất chưa được thiết lập',
      instruction: 'Hướng dẫn',
      instructionTip: 'Nhập hướng dẫn bổ sung để giúp trình trích xuất tham số hiểu rõ hơn về cách trích xuất tham số.',
      advancedSetting: 'Cài đặt nâng cao',
      reasoningMode: 'Chế độ suy luận',
      reasoningModeTip: 'Bạn có thể chọn chế độ suy luận phù hợp dựa trên khả năng của mô hình để phản hồi các hướng dẫn về việc gọi hàm hoặc prompt.',
      isSuccess: 'Thành công. Khi thành công giá trị là 1, khi thất bại giá trị là 0.',
      errorReason: 'Lý do lỗi',
    },
    iteration: {
      deleteTitle: 'Xóa nút lặp?',
      deleteDesc: 'Xóa nút lặp sẽ xóa tất cả các nút con',
      input: 'Đầu vào',
      output: 'Biến đầu ra',
      iteration_one: '{{count}} Lặp',
      iteration_other: '{{count}} Lặp',
      currentIteration: 'Lặp hiện tại',
    },
  },
  tracing: {
    stopBy: 'Dừng bởi {{user}}',
  },
}

export default translation
