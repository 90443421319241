const translation = {
  confirm: {
    application: {
      title: 'AI Bot sınırına ulaşıldı, geçici olarak oluşturulamıyor',
      content: 'Lütfen sürümünüzü yükseltmek için bizimle iletişime geçin veya bazı Chatbotları silin',
    },
    document: {
      title: 'Belge sınırına ulaşıldı, geçici olarak eklenemiyor',
      content: 'Lütfen sürümünüzü yükseltmek için bizimle iletişime geçin veya bazı belgeleri silin',
    },
    video: {
      title: 'Video sınırına ulaşıldı, geçici olarak eklenemiyor',
      content: 'Lütfen sürümünüzü yükseltmek için bizimle iletişime geçin veya bazı videoları silin',
    },
    buttonText: 'Anladım',
    messageLimitAnswer: 'x-elephant uyuyor gibi görünüyor, lütfen yardım için müşteri desteği ile iletişime geçin',
  },
}

export default translation
