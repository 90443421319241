const translation = {
  pageTitle: 'Hey, başlayalım!👋',
  welcome: 'Dify\'ye hoş geldiniz, devam etmek için lütfen giriş yapın.',
  email: 'E-posta adresi',
  emailPlaceholder: 'E-postanız',
  password: 'Şifre',
  passwordPlaceholder: 'Şifreniz',
  name: 'Kullanıcı adı',
  namePlaceholder: 'Kullanıcı adınız',
  forget: 'Şifrenizi mi unuttunuz?',
  signBtn: 'Giriş yap',
  sso: 'SSO ile devam et',
  installBtn: 'Kurulum',
  setAdminAccount: 'Yönetici hesabı ayarlama',
  setAdminAccountDesc: 'Yönetici hesabı için maksimum ayrıcalıklar, uygulama oluşturma ve LLM sağlayıcılarını yönetme gibi işlemler için kullanılabilir.',
  createAndSignIn: 'Oluştur ve giriş yap',
  oneMoreStep: 'Bir adım kaldı',
  createSample: 'Bu bilgilere dayanarak, sizin için örnek bir uygulama oluşturacağız',
  invitationCode: 'Davet Kodu',
  invitationCodePlaceholder: 'Davet kodunuz',
  interfaceLanguage: 'Arayüz Dili',
  timezone: 'Zaman dilimi',
  go: 'Dify\'ye git',
  sendUsMail: 'Tanıtımınızı e-posta ile gönderin, davet talebini işleme alalım.',
  acceptPP: 'Gizlilik politikasını okudum ve kabul ediyorum',
  reset: 'Şifrenizi sıfırlamak için şu komutu çalıştırın',
  withGitHub: 'GitHub ile devam et',
  withGoogle: 'Google ile devam et',
  rightTitle: 'LLM\'nin tam potansiyelini ortaya çıkarın',
  rightDesc: 'Görsel olarak çekici, çalışılabilir ve iyileştirilebilir AI uygulamaları oluşturun.',
  tos: 'Hizmet Şartları',
  pp: 'Gizlilik Politikası',
  tosDesc: 'Kaydolarak, Hizmet Şartlarımızı kabul etmiş olursunuz',
  goToInit: 'Hesabı başlatmadıysanız, lütfen başlatma sayfasına gidin',
  donthave: 'Sahip değil misiniz?',
  invalidInvitationCode: 'Geçersiz davet kodu',
  accountAlreadyInited: 'Hesap zaten başlatılmış',
  forgotPassword: 'Şifrenizi mi unuttunuz?',
  resetLinkSent: 'Sıfırlama bağlantısı gönderildi',
  sendResetLink: 'Sıfırlama bağlantısı gönder',
  backToSignIn: 'Girişe dön',
  forgotPasswordDesc: 'Şifrenizi sıfırlamak için e-posta adresinizi girin. Şifrenizi nasıl sıfırlayacağınıza dair talimatları içeren bir e-posta göndereceğiz.',
  checkEmailForResetLink: 'Şifrenizi sıfırlamak için bir bağlantı içeren e-postayı kontrol edin. Birkaç dakika içinde görünmezse, spam klasörünüzü kontrol ettiğinizden emin olun.',
  passwordChanged: 'Şimdi giriş yapın',
  changePassword: 'Şifre Değiştir',
  changePasswordTip: 'Hesabınız için yeni bir şifre girin',
  invalidToken: 'Geçersiz veya süresi dolmuş token',
  confirmPassword: 'Şifreyi Onayla',
  confirmPasswordPlaceholder: 'Yeni şifrenizi onaylayın',
  passwordChangedTip: 'Şifreniz başarıyla değiştirildi',
  error: {
    emailEmpty: 'E-posta adresi gereklidir',
    emailInValid: 'Geçerli bir e-posta adresi girin',
    nameEmpty: 'İsim gereklidir',
    passwordEmpty: 'Şifre gereklidir',
    passwordLengthInValid: 'Şifre en az 8 karakterden oluşmalıdır',
    passwordInvalid: 'Şifre harf ve rakamlardan oluşmalı ve uzunluğu 8 karakterden fazla olmalıdır',
  },
  license: {
    tip: 'Dify Community Edition\'ı başlatmadan önce GitHub\'daki',
    link: 'Açık Kaynak Lisansını',
  },
  join: 'Katıl',
  joinTipStart: 'Sizi',
  joinTipEnd: 'takımına davet ediyor',
  invalid: 'Bağlantı süresi doldu',
  explore: 'Dify\'yi Keşfet',
  activatedTipStart: 'Katıldınız',
  activatedTipEnd: 'takımına',
  activated: 'Şimdi giriş yapın',
  adminInitPassword: 'Yönetici başlangıç şifresi',
  validate: 'Doğrula',
}

export default translation
