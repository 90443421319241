const translation = {
  currentPlan: 'Mevcut Plan',
  upgradeBtn: {
    plain: 'Planı Yükselt',
    encourage: 'Şimdi Yükselt',
    encourageShort: 'Yükselt',
  },
  viewBilling: 'Faturalandırma ve abonelikleri yönet',
  buyPermissionDeniedTip: 'Abone olmak için lütfen işletme yöneticinize başvurun',
  plansCommon: {
    title: 'Size uygun bir plan seçin',
    yearlyTip: 'Yıllık abonelikle 2 ay ücretsiz!',
    mostPopular: 'En Popüler',
    planRange: {
      monthly: 'Aylık',
      yearly: 'Yıllık',
    },
    month: 'ay',
    year: 'yıl',
    save: 'Tasarruf et ',
    free: 'Ücretsiz',
    currentPlan: 'Mevcut Plan',
    contractSales: 'Satışla iletişime geçin',
    contractOwner: 'Takım yöneticisine başvurun',
    startForFree: 'Ücretsiz Başla',
    getStartedWith: 'ile başlayın',
    contactSales: 'Satışlarla İletişime Geçin',
    talkToSales: 'Satışlarla Konuşun',
    modelProviders: 'Model Sağlayıcılar',
    teamMembers: 'Takım Üyeleri',
    annotationQuota: 'Ek Açıklama Kotası',
    buildApps: 'Uygulamalar Oluştur',
    vectorSpace: 'Vektör Alanı',
    vectorSpaceBillingTooltip: 'Her 1MB yaklaşık 1.2 milyon karakter vektörize veri depolayabilir (OpenAI Embeddings ile tahmin edilmiştir, modellere göre farklılık gösterebilir).',
    vectorSpaceTooltip: 'Vektör Alanı, LLM\'lerin verilerinizi anlaması için gerekli uzun süreli hafıza sistemidir.',
    documentsUploadQuota: 'Doküman Yükleme Kotası',
    documentProcessingPriority: 'Doküman İşleme Önceliği',
    documentProcessingPriorityTip: 'Daha yüksek doküman işleme önceliği için planınızı yükseltin.',
    documentProcessingPriorityUpgrade: 'Daha fazla veriyi daha yüksek doğrulukla ve daha hızlı işleyin.',
    priority: {
      'standard': 'Standart',
      'priority': 'Öncelikli',
      'top-priority': 'En Öncelikli',
    },
    logsHistory: 'Günlük Geçmişi',
    customTools: 'Özel Araçlar',
    unavailable: 'Mevcut Değil',
    days: 'gün',
    unlimited: 'Sınırsız',
    support: 'Destek',
    supportItems: {
      communityForums: 'Topluluk forumları',
      emailSupport: 'E-posta desteği',
      priorityEmail: 'Öncelikli e-posta ve sohbet desteği',
      logoChange: 'Logo değişikliği',
      SSOAuthentication: 'SSO kimlik doğrulama',
      personalizedSupport: 'Kişiselleştirilmiş destek',
      dedicatedAPISupport: 'Özel API desteği',
      customIntegration: 'Özel entegrasyon ve destek',
      ragAPIRequest: 'RAG API Talepleri',
      bulkUpload: 'Toplu doküman yükleme',
      agentMode: 'Agent Modu',
      workflow: 'Workflow',
      llmLoadingBalancing: 'LLM Yük Dengeleme',
      llmLoadingBalancingTooltip: 'Modellere birden fazla API anahtarı ekleyin, API hız sınırlarını etkili bir şekilde aşın.',
    },
    comingSoon: 'Yakında geliyor',
    member: 'Üye',
    memberAfter: 'Üye',
    messageRequest: {
      title: 'Mesaj Kredileri',
      tooltip: 'OpenAI modellerini (gpt4 hariç) kullanarak çeşitli planlar için mesaj çağrı kotaları. Limitin üzerindeki mesajlar OpenAI API Anahtarınızı kullanır.',
    },
    annotatedResponse: {
      title: 'Ek Açıklama Kota Sınırları',
      tooltip: 'Yanıtların elle düzenlenmesi ve ek açıklanması, uygulamalar için özelleştirilebilir yüksek kaliteli soru-cevap yetenekleri sağlar. (Sadece sohbet uygulamalarında geçerlidir)',
    },
    ragAPIRequestTooltip: 'Dify\'nin sadece bilgi tabanı işleme yeteneklerini çağıran API çağrıları sayısını ifade eder.',
    receiptInfo: 'Sadece takım sahibi ve takım yöneticisi abone olabilir ve faturalandırma bilgilerini görüntüleyebilir',
  },
  plans: {
    sandbox: {
      name: 'Sandbox',
      description: '200 kez GPT ücretsiz deneme',
      includesTitle: 'İçerdikleri:',
    },
    professional: {
      name: 'Profesyonel',
      description: 'Bireyler ve küçük takımlar için daha fazla güç açın.',
      includesTitle: 'Ücretsiz plandaki her şey, artı:',
    },
    team: {
      name: 'Takım',
      description: 'Sınırsız işbirliği ve en üst düzey performans.',
      includesTitle: 'Profesyonel plandaki her şey, artı:',
    },
    enterprise: {
      name: 'Kurumsal',
      description: 'Büyük ölçekli kritik sistemler için tam yetenekler ve destek.',
      includesTitle: 'Takım plandaki her şey, artı:',
    },
  },
  vectorSpace: {
    fullTip: 'Vektör Alanı dolu.',
    fullSolution: 'Daha fazla alan için planınızı yükseltin.',
  },
  apps: {
    fullTipLine1: 'Daha fazla uygulama oluşturmak için',
    fullTipLine2: 'planınızı yükseltin.',
  },
  annotatedResponse: {
    fullTipLine1: 'Daha fazla konuşmayı açıklamak için',
    fullTipLine2: 'planınızı yükseltin.',
    quotaTitle: 'Ek Açıklama Yanıtı Kotası',
  },
}

export default translation
