const translation = {
  title: 'Adnotacje',
  name: 'Odpowiedź adnotacji',
  editBy: 'Odpowiedź edytowana przez {{author}}',
  noData: {
    title: 'Brak adnotacji',
    description:
      'Możesz edytować adnotacje podczas debugowania aplikacji lub importować adnotacje tutaj w celu uzyskania wysokiej jakości odpowiedzi.',
  },
  table: {
    header: {
      question: 'pytanie',
      answer: 'odpowiedź',
      createdAt: 'utworzono',
      hits: 'trafienia',
      actions: 'akcje',
      addAnnotation: 'Dodaj adnotację',
      bulkImport: 'Masowy import',
      bulkExport: 'Masowy eksport',
      clearAll: 'Wyczyść wszystkie adnotacje',
    },
  },
  editModal: {
    title: 'Edytuj odpowiedź adnotacji',
    queryName: 'Zapytanie użytkownika',
    answerName: 'Bot opowiadający historie',
    yourAnswer: 'Twoja odpowiedź',
    answerPlaceholder: 'Wpisz tutaj swoją odpowiedź',
    yourQuery: 'Twoje zapytanie',
    queryPlaceholder: 'Wpisz tutaj swoje zapytanie',
    removeThisCache: 'Usuń tę adnotację',
    createdAt: 'Utworzono',
  },
  addModal: {
    title: 'Dodaj odpowiedź adnotacji',
    queryName: 'Pytanie',
    answerName: 'Odpowiedź',
    answerPlaceholder: 'Wpisz tutaj odpowiedź',
    queryPlaceholder: 'Wpisz tutaj zapytanie',
    createNext: 'Dodaj kolejną odpowiedź adnotacji',
  },
  batchModal: {
    title: 'Masowy import',
    csvUploadTitle: 'Przeciągnij i upuść tutaj swój plik CSV, lub ',
    browse: 'przeglądaj',
    tip: 'Plik CSV musi spełniać następującą strukturę:',
    question: 'pytanie',
    answer: 'odpowiedź',
    contentTitle: 'zawartość fragmentu',
    content: 'zawartość',
    template: 'Pobierz szablon tutaj',
    cancel: 'Anuluj',
    run: 'Uruchom batch',
    runError: 'Uruchomienie batcha nie powiodło się',
    processing: 'Przetwarzanie batcha',
    completed: 'Import zakończony',
    error: 'Błąd importu',
    ok: 'OK',
  },
  errorMessage: {
    answerRequired: 'Odpowiedź jest wymagana',
    queryRequired: 'Pytanie jest wymagane',
  },
  viewModal: {
    annotatedResponse: 'Odpowiedź adnotacji',
    hitHistory: 'Historia trafień',
    hit: 'Trafienie',
    hits: 'Trafienia',
    noHitHistory: 'Brak historii trafień',
  },
  hitHistoryTable: {
    query: 'Zapytanie',
    match: 'Dopasowanie',
    response: 'Odpowiedź',
    source: 'Źródło',
    score: 'Wynik',
    time: 'Czas',
  },
  initSetup: {
    title: 'Początkowa konfiguracja odpowiedzi adnotacji',
    configTitle: 'Konfiguracja odpowiedzi adnotacji',
    confirmBtn: 'Zapisz i włącz',
    configConfirmBtn: 'Zapisz',
  },
  embeddingModelSwitchTip:
    'Model wektoryzacji tekstu adnotacji, przełączanie modeli spowoduje ponowne osadzenie, co wiąże się z dodatkowymi kosztami.',
}

export default translation
