const translation = {
  pageTitle: 'Hi, welcome to x-elephant',
  welcome: 'Welcome to x-elephant, log in to continue',
  email: 'Email',
  emailPlaceholder: 'Enter email address',
  password: 'Password',
  passwordPlaceholder: 'Enter password',
  name: 'Username',
  namePlaceholder: 'Enter username',
  forget: 'Forgot password?',
  signBtn: 'Log In',
  installBtn: 'Set Up',
  setAdminAccount: 'Set Admin Account',
  setAdminAccountDesc: 'The admin has the highest permissions and can be used to create applications and manage LLM providers.',
  createAndSignIn: 'Create Account',
  oneMoreStep: 'One more step',
  createSample: 'Based on this information, we will create a sample application for you',
  invitationCode: 'Invitation Code',
  invitationCodePlaceholder: 'Enter invitation code',
  interfaceLanguage: 'Interface Language',
  timezone: 'Time Zone',
  go: 'Go to Dify',
  sendUsMail: 'Send us an email introducing yourself, we will process it as soon as possible.',
  acceptPP: 'I have read and accept the Privacy Policy',
  reset: 'Please run the following command to reset your password',
  withGitHub: 'Log in with GitHub',
  withGoogle: 'Log in with Google',
  rightTitle: 'Unlock the Full Potential of Large Language Models',
  rightDesc: 'Easily build visual, operational, and improvable AI applications',
  tos: 'Terms of Service',
  pp: 'Privacy Policy',
  tosDesc: 'By using our service, you agree to our',
  goToInit: 'If you have not initialized your account, please go to the initialization page',
  donthave: 'Don’t have an invitation code?',
  invalidInvitationCode: 'Invalid invitation code',
  accountAlreadyInited: 'Account already initialized',
  forgotPassword: 'Forgot your password?',
  resetLinkSent: 'Reset link sent',
  sendResetLink: 'Send reset link',
  backToSignIn: 'Return to sign in',
  forgotPasswordDesc: 'Please enter your email address to reset your password. We will send you an email with instructions on how to reset your password.',
  checkEmailForResetLink: 'Please check your email for a link to reset your password. If it doesn\'t appear within a few minutes, make sure to check your spam folder.',
  passwordChanged: 'Sign in now',
  changePassword: 'Change Password',
  changePasswordTip: 'Please enter a new password for your account',
  invalidToken: 'Invalid or expired token',
  confirmPassword: 'Confirm Password',
  confirmPasswordPlaceholder: 'Confirm your new password',
  passwordChangedTip: 'Your password has been successfully changed',
  error: {
    emailEmpty: 'Email address is required',
    emailInValid: 'Please enter a valid email address',
    nameEmpty: 'Username cannot be empty',
    passwordEmpty: 'Password cannot be empty',
    passwordInvalid: 'Password must contain letters and numbers and be at least 8 characters long',
  },
  license: {
    tip: 'Before starting Dify Community Edition, please read the',
    link: 'open-source license on GitHub',
  },
  join: 'Join',
  joinTipStart: 'Invite you to join',
  joinTipEnd: 'team',
  invalid: 'Link has expired',
  explore: 'Explore Dify',
  activatedTipStart: 'You have joined',
  activatedTipEnd: 'team',
  activated: 'Log in now',
  adminInitPassword: 'Admin Initial Password',
  validate: 'Validate',
  noAccount: 'No account?',
  apply: 'Apply for an experience',
}

export default translation
