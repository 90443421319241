const translation = {
  createApp: 'CRIAR APLICATIVO',
  types: {
    all: 'Todos',
    chatbot: 'Chatbot',
    agent: 'Agente',
    workflow: 'Fluxo de trabalho',
    completion: 'Conclusão',
  },
  duplicate: 'Duplicar',
  duplicateTitle: 'Duplicar Aplicativo',
  export: 'Exportar DSL',
  exportFailed: 'Falha ao exportar DSL.',
  importDSL: 'Importar arquivo DSL',
  createFromConfigFile: 'Criar a partir do arquivo DSL',
  deleteAppConfirmTitle: 'Excluir este aplicativo?',
  deleteAppConfirmContent:
    'A exclusão do aplicativo é irreversível. Os usuários não poderão mais acessar seu aplicativo e todas as configurações de prompt e logs serão permanentemente excluídas.',
  appDeleted: 'Aplicativo excluído',
  appDeleteFailed: 'Falha ao excluir aplicativo',
  join: 'Participe da comunidade',
  communityIntro:
    'Discuta com membros da equipe, colaboradores e desenvolvedores em diferentes canais.',
  roadmap: 'Veja nosso roteiro',
  newApp: {
    startFromBlank: 'Criar do zero',
    startFromTemplate: 'Criar do modelo',
    captionAppType: 'Que tipo de aplicativo você deseja criar?',
    chatbotDescription: 'Construa um aplicativo baseado em chat. Este aplicativo usa um formato de pergunta e resposta, permitindo várias rodadas de conversa contínua.',
    completionDescription: 'Construa um aplicativo que gera texto de alta qualidade com base em prompts, como geração de artigos, resumos, traduções e muito mais.',
    completionWarning: 'Este tipo de aplicativo não será mais suportado.',
    agentDescription: 'Construa um Agente inteligente que pode escolher ferramentas para completar as tarefas autonomamente',
    workflowDescription: 'Construa um aplicativo que gera texto de alta qualidade com base em fluxo de trabalho com alto grau de personalização. É adequado para usuários experientes.',
    workflowWarning: 'Atualmente em beta',
    chatbotType: 'Método de orquestração do Chatbot',
    basic: 'Básico',
    basicTip: 'Para iniciantes, pode mudar para o Chatflow mais tarde',
    basicFor: 'PARA INICIANTES',
    basicDescription: 'A Orquestração Básica permite orquestrar um aplicativo Chatbot usando configurações simples, sem a capacidade de modificar prompts integrados. É adequado para iniciantes.',
    advanced: 'Chatflow',
    advancedFor: 'Para usuários avançados',
    advancedDescription: 'A Orquestração de Fluxo de Trabalho orquestra Chatbots na forma de fluxos de trabalho, oferecendo um alto grau de personalização, incluindo a capacidade de editar prompts integrados. É adequado para usuários experientes.',
    captionName: 'Ícone e nome do aplicativo',
    appNamePlaceholder: 'Dê um nome para o seu aplicativo',
    captionDescription: 'Descrição',
    appDescriptionPlaceholder: 'Digite a descrição do aplicativo',
    useTemplate: 'Usar este modelo',
    previewDemo: 'Visualizar demonstração',
    chatApp: 'Assistente',
    chatAppIntro:
      'Eu quero construir um aplicativo baseado em chat. Este aplicativo usa um formato de pergunta e resposta, permitindo várias rodadas de conversa contínua.',
    agentAssistant: 'Novo Assistente de Agente',
    completeApp: 'Gerador de Texto',
    completeAppIntro:
      'Eu quero criar um aplicativo que gera texto de alta qualidade com base em prompts, como geração de artigos, resumos, traduções e muito mais.',
    showTemplates: 'Quero escolher a partir de um modelo',
    hideTemplates: 'Voltar para a seleção de modo',
    Create: 'Criar',
    Cancel: 'Cancelar',
    nameNotEmpty: 'O nome não pode estar vazio',
    appTemplateNotSelected: 'Por favor, selecione um modelo',
    appTypeRequired: 'Por favor, selecione um tipo de aplicativo',
    appCreated: 'Aplicativo criado',
    appCreateFailed: 'Falha ao criar aplicativo',
  },
  editApp: 'Editar Informações',
  editAppTitle: 'Editar Informações do Aplicativo',
  editDone: 'Informações do aplicativo atualizadas',
  editFailed: 'Falha ao atualizar informações do aplicativo',
  iconPicker: {
    ok: 'OK',
    cancel: 'Cancelar',
    emoji: 'Emoji',
    image: 'Imagem',
  },
  switch: 'Mudar para Orquestração de Fluxo de Trabalho',
  switchTipStart: 'Será criada uma nova cópia do aplicativo para você e a nova cópia mudará para Orquestração de Fluxo de Trabalho. A nova cópia não permitirá a ',
  switchTip: 'volta',
  switchTipEnd: ' para Orquestração Básica.',
  switchLabel: 'A cópia do aplicativo a ser criada',
  removeOriginal: 'Excluir o aplicativo original',
  switchStart: 'Iniciar mudança',
  typeSelector: {
    all: 'Todos os Tipos',
    chatbot: 'Chatbot',
    agent: 'Agente',
    workflow: 'Fluxo de trabalho',
    completion: 'Conclusão',
  },
  tracing: {
    title: 'Rastreamento de desempenho do aplicativo',
    description: 'Configurando um provedor LLMOps de terceiros e rastreando o desempenho do aplicativo.',
    config: 'Configurar',
    collapse: 'Recolher',
    expand: 'Expandir',
    tracing: 'Rastreamento',
    disabled: 'Desativado',
    disabledTip: 'Por favor, configure o provedor primeiro',
    enabled: 'Em serviço',
    tracingDescription: 'Captura o contexto completo da execução do aplicativo, incluindo chamadas LLM, contexto, prompts, solicitações HTTP e mais, para uma plataforma de rastreamento de terceiros.',
    configProviderTitle: {
      configured: 'Configurado',
      notConfigured: 'Configure o provedor para habilitar o rastreamento',
      moreProvider: 'Mais provedores',
    },
    langsmith: {
      title: 'LangSmith',
      description: 'Uma plataforma de desenvolvedor completa para cada etapa do ciclo de vida do aplicativo impulsionado por LLM.',
    },
    langfuse: {
      title: 'Langfuse',
      description: 'Rastreamentos, avaliações, gerenciamento de prompts e métricas para depurar e melhorar seu aplicativo LLM.',
    },
    inUse: 'Em uso',
    configProvider: {
      title: 'Configurar ',
      placeholder: 'Insira sua {{key}}',
      project: 'Projeto',
      publicKey: 'Chave Pública',
      secretKey: 'Chave Secreta',
      viewDocsLink: 'Ver documentação de {{key}}',
      removeConfirmTitle: 'Remover configuração de {{key}}?',
      removeConfirmContent: 'A configuração atual está em uso, removê-la desligará o recurso de Rastreamento.',
    },
  },
}

export default translation
