const translation = {
  list: {
    title: '문서',
    desc: '지식의 모든 파일이 여기에 표시되며, 전체 지식이 Dify의 인용문이나 챗 플러그인을 통해 링크되거나 색인화될 수 있습니다.',
    addFile: '파일 추가',
    addPages: '페이지 추가',
    table: {
      header: {
        fileName: '파일명',
        words: '단어 수',
        hitCount: '검색 횟수',
        uploadTime: '업로드 시간',
        status: '상태',
        action: '동작',
      },
    },
    action: {
      uploadFile: '새 파일 업로드',
      settings: '세그먼트 설정',
      addButton: '청크 추가',
      add: '청크 추가',
      batchAdd: '일괄 추가',
      archive: '아카이브',
      unarchive: '아카이브 해제',
      delete: '삭제',
      enableWarning: '아카이브된 파일은 활성화할 수 없습니다.',
      sync: '동기화',
    },
    index: {
      enable: '활성화',
      disable: '비활성화',
      all: '모두',
      enableTip: '파일을 색인화할 수 있습니다.',
      disableTip: '파일을 색인화할 수 없습니다.',
    },
    status: {
      queuing: '대기 중',
      indexing: '색인화 중',
      paused: '일시 중지됨',
      error: '오류',
      available: '사용 가능',
      enabled: '활성화됨',
      disabled: '비활성화됨',
      archived: '아카이브됨',
    },
    empty: {
      title: '아직 문서가 없습니다',
      upload: {
        tip: '파일을 업로드하거나 웹 사이트에서 동기화하거나 Notion이나 GitHub 같은 웹 앱에서 동기화할 수 있습니다.',
      },
      sync: {
        tip: 'Dify는 정기적으로 Notion에서 파일을 다운로드하고 처리합니다.',
      },
    },
    delete: {
      title: '정말 삭제하시겠습니까?',
      content: '나중에 처리를 계속해야 하는 경우 중단한 곳에서 계속합니다.',
    },
    batchModal: {
      title: '일괄 추가',
      csvUploadTitle: 'CSV 파일을 여기로 드래그 앤 드롭하거나',
      browse: '찾아보기',
      tip: 'CSV 파일은 다음 구조를 따라야 합니다:',
      question: '질문',
      answer: '답변',
      contentTitle: '청크 내용',
      content: '내용',
      template: '여기서 템플릿 다운로드',
      cancel: '취소',
      run: '일괄 실행',
      runError: '일괄 실행에 실패했습니다',
      processing: '일괄 처리 중',
      completed: '가져오기 완료',
      error: '가져오기 오류',
      ok: '확인',
    },
  },
  metadata: {
    title: '메타데이터',
    desc: '문서 메타데이터에 레이블을 붙여 AI가 신속하게 접근할 수 있고 사용자에게 출처가 공개됩니다.',
    dateTimeFormat: 'YYYY년 M월 D일 hh:mm A',
    docTypeSelectTitle: '문서 유형을 선택하세요',
    docTypeChangeTitle: '문서 유형 변경',
    docTypeSelectWarning: '문서 유형을 변경하면 현재 입력된 메타데이터가 유지되지 않습니다.',
    firstMetaAction: '시작하기',
    placeholder: {
      add: '추가',
      select: '선택',
    },
    source: {
      upload_file: '파일 업로드',
      notion: 'Notion에서 동기화',
      github: 'GitHub에서 동기화',
    },
    type: {
      book: '도서',
      webPage: '웹 페이지',
      paper: '논문',
      socialMediaPost: '소셜 미디어 게시물',
      personalDocument: '개인 문서',
      businessDocument: '비즈니스 문서',
      IMChat: 'IM 채팅',
      wikipediaEntry: '위키피디아 항목',
      notion: 'Notion에서 동기화',
      github: 'GitHub에서 동기화',
      technicalParameters: '기술적 매개변수',
    },
    field: {
      processRule: {
        processDoc: '문서 처리',
        segmentRule: '청크 규칙',
        segmentLength: '청크 길이',
        processClean: '텍스트 전처리',
      },
      book: {
        title: '제목',
        language: '언어',
        author: '저자',
        publisher: '출판사',
        publicationDate: '출판일',
        ISBN: 'ISBN',
        category: '카테고리',
      },
      webPage: {
        title: '제목',
        url: 'URL',
        language: '언어',
        authorPublisher: '저자/출판사',
        publishDate: '공개일',
        topicsKeywords: '주제/키워드',
        description: '설명',
      },
      paper: {
        title: '제목',
        language: '언어',
        author: '저자',
        publishDate: '공개일',
        journalConferenceName: '저널/학회명',
        volumeIssuePage: '권호페이지',
        DOI: 'DOI',
        topicsKeywords: '주제/키워드',
        abstract: '요약',
      },
      socialMediaPost: {
        platform: '플랫폼',
        authorUsername: '저자/사용자명',
        publishDate: '공개일',
        postURL: '게시물 URL',
        topicsTags: '주제/태그',
      },
      personalDocument: {
        title: '제목',
        author: '저자',
        creationDate: '생성일',
        lastModifiedDate: '최종 수정일',
        documentType: '문서 유형',
        tagsCategory: '태그/카테고리',
      },
      businessDocument: {
        title: '제목',
        author: '저자',
        creationDate: '생성일',
        lastModifiedDate: '최종 수정일',
        documentType: '문서 유형',
        departmentTeam: '부서/팀',
      },
      IMChat: {
        chatPlatform: '채팅 플랫폼',
        chatPartiesGroupName: '채팅 참여자/그룹명',
        participants: '참여자',
        startDate: '시작일',
        endDate: '종료일',
        topicsKeywords: '주제/키워드',
        fileType: '파일 유형',
      },
      wikipediaEntry: {
        title: '제목',
        language: '언어',
        webpageURL: '웹 페이지 URL',
        editorContributor: '편집자/기고자',
        lastEditDate: '최종 편집일',
        summaryIntroduction: '요약/소개',
      },
      notion: {
        title: '제목',
        language: '언어',
        author: '저자',
        createdTime: '생성 일시',
        lastModifiedTime: '최종 수정 일시',
        url: 'URL',
        tag: '태그',
        description: '설명',
      },
      github: {
        repoName: '저장소 이름',
        repoDesc: '저장소 설명',
        repoOwner: '저장소 소유자',
        fileName: '파일 이름',
        filePath: '파일 경로',
        programmingLang: '프로그래밍 언어',
        url: 'URL',
        license: '라이선스',
        lastCommitTime: '최종 커밋 시간',
        lastCommitAuthor: '최종 커밋 작성자',
      },
      originInfo: {
        originalFilename: '원본 파일 이름',
        originalFileSize: '원본 파일 크기',
        uploadDate: '업로드 일시',
        lastUpdateDate: '최종 업데이트 일시',
        source: '소스',
      },
      technicalParameters: {
        segmentSpecification: '청크 사양',
        segmentLength: '청크 길이',
        avgParagraphLength: '평균 문단 길이',
        paragraphs: '문단',
        hitCount: '검색 횟수',
        embeddingTime: '임베딩 시간',
        embeddedSpend: '임베딩 시간',
      },
    },
    languageMap: {
      zh: '중국어',
      en: '영어',
      es: '스페인어',
      fr: '프랑스어',
      de: '독일어',
      ja: '일본어',
      ko: '한국어',
      ru: '러시아어',
      ar: '아랍어',
      pt: '포르투갈어',
      it: '이탈리아어',
      nl: '네덜란드어',
      pl: '폴란드어',
      sv: '스웨덴어',
      tr: '터키어',
      he: '히브리어',
      hi: '힌디어',
      da: '덴마크어',
      fi: '핀란드어',
      no: '노르웨이어',
      hu: '헝가리어',
      el: '그리스어',
      cs: '체코어',
      th: '태국어',
      id: '인도네시아어',
    },
    categoryMap: {
      book: {
        fiction: '소설',
        biography: '전기',
        history: '역사',
        science: '과학',
        technology: '기술',
        education: '교육',
        philosophy: '철학',
        religion: '종교',
        socialSciences: '사회과학',
        art: '예술',
        travel: '여행',
        health: '건강',
        selfHelp: '자기 도움',
        businessEconomics: '비즈니스/경제',
        cooking: '요리',
        childrenYoungAdults: '어린이/청소년',
        comicsGraphicNovels: '만화/그래픽 소설',
        poetry: '시',
        drama: '연극',
        other: '기타',
      },
      personalDoc: {
        notes: '메모',
        blogDraft: '블로그 초안',
        diary: '다이어리',
        researchReport: '연구 보고서',
        bookExcerpt: '책 발췌',
        schedule: '일정',
        list: '목록',
        projectOverview: '프로젝트 개요',
        photoCollection: '사진 컬렉션',
        creativeWriting: '창작 글',
        codeSnippet: '코드 스니펫',
        designDraft: '디자인 초안',
        personalResume: '이력서',
        other: '기타',
      },
      businessDoc: {
        meetingMinutes: '회의록',
        researchReport: '연구 보고서',
        proposal: '제안서',
        employeeHandbook: '직원 안내서',
        trainingMaterials: '교육 자료',
        requirementsDocument: '요구 사항 문서',
        designDocument: '디자인 문서',
        productSpecification: '제품 사양서',
        financialReport: '재무 보고서',
        marketAnalysis: '시장 분석',
        projectPlan: '프로젝트 계획서',
        teamStructure: '팀 구조',
        policiesProcedures: '정책 및 절차',
        contractsAgreements: '계약 및 협약',
        emailCorrespondence: '이메일 통신',
        other: '기타',
      },
    },
  },
  embedding: {
    processing: '임베딩 처리 중...',
    paused: '임베딩이 일시 중지되었습니다',
    completed: '임베딩이 완료되었습니다',
    error: '임베딩 오류',
    docName: '문서 전처리',
    mode: '세그먼트 규칙',
    segmentLength: '청크의 길이',
    textCleaning: '텍스트 전처리',
    segments: '세그먼트',
    highQuality: '고품질 모드',
    economy: '경제 모드',
    estimate: '소비량 예상',
    stop: '처리 중지',
    resume: '처리 재개',
    automatic: '자동',
    custom: '사용자 정의',
    previewTip: '임베딩이 완료된 후에 세그먼트 미리보기를 사용할 수 있습니다',
  },
  segment: {
    paragraphs: '단락',
    keywords: '키워드',
    addKeyWord: '키워드 추가',
    keywordError: '키워드 최대 길이는 20자입니다',
    characters: '문자',
    hitCount: '검색 횟수',
    vectorHash: '벡터 해시: ',
    questionPlaceholder: '질문을 입력하세요',
    questionEmpty: '질문을 비워둘 수 없습니다',
    answerPlaceholder: '답변을 입력하세요',
    answerEmpty: '답변을 비워둘 수 없습니다',
    contentPlaceholder: '내용을 입력하세요',
    contentEmpty: '내용을 비워둘 수 없습니다',
    newTextSegment: '새로운 텍스트 세그먼트',
    newQaSegment: '새로운 Q&A 세그먼트',
    delete: '이 청크를 삭제하시겠습니까?',
  },
}

export default translation
