const translation = {
  confirm: {
    application: {
      title: 'محدودیت ربات AI به حداکثر رسیده است، به طور موقت قادر به ایجاد نیست',
      content: 'لطفاً برای ارتقاء نسخه خود با ما تماس بگیرید، یا برخی از چت‌بات‌ها را حذف کنید',
    },
    document: {
      title: 'محدودیت سند به حداکثر رسیده است، به طور موقت قادر به افزودن نیست',
      content: 'لطفاً برای ارتقاء نسخه خود با ما تماس بگیرید، یا برخی از اسناد را حذف کنید',
    },
    video: {
      title: 'محدودیت ویدیو به حداکثر رسیده است، به طور موقت قادر به افزودن نیست',
      content: 'لطفاً برای ارتقاء نسخه خود با ما تماس بگیرید، یا برخی از ویدیوها را حذف کنید',
    },
    buttonText: 'متوجه شدم',
    messageLimitAnswer: 'به نظر می‌رسد x-elephant خوابیده است، لطفاً برای کمک با پشتیبانی مشتری تماس بگیرید',
  },
}

export default translation
