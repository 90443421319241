const translation = {
  title: 'Ek Açıklamalar',
  name: 'Ek Açıklama Yanıtı',
  editBy: '{{author}} tarafından düzenlendi',
  noData: {
    title: 'Ek açıklama yok',
    description: 'Uygulama hata ayıklaması sırasında ek açıklamaları düzenleyebilir veya yüksek kaliteli bir yanıt için burada toplu olarak ek açıklamaları içe aktarabilirsiniz.',
  },
  table: {
    header: {
      question: 'soru',
      answer: 'cevap',
      createdAt: 'oluşturulma tarihi',
      hits: 'vuruşlar',
      actions: 'aksiyonlar',
      addAnnotation: 'Ek Açıklama Ekle',
      bulkImport: 'Toplu İçe Aktarma',
      bulkExport: 'Toplu Dışa Aktarma',
      clearAll: 'Tüm Ek Açıklamaları Temizle',
    },
  },
  editModal: {
    title: 'Ek Açıklama Yanıtını Düzenle',
    queryName: 'Kullanıcı Sorgusu',
    answerName: 'Storyteller Bot',
    yourAnswer: 'Senin Cevabın',
    answerPlaceholder: 'Cevabınızı buraya yazın',
    yourQuery: 'Senin Sorgun',
    queryPlaceholder: 'Sorgunuzu buraya yazın',
    removeThisCache: 'Bu Ek Açıklamayı Kaldır',
    createdAt: 'Oluşturulma Tarihi',
  },
  addModal: {
    title: 'Ek Açıklama Yantı Ekle',
    queryName: 'Soru',
    answerName: 'Cevap',
    answerPlaceholder: 'Cevabı buraya yazın',
    queryPlaceholder: 'Sorguyu buraya yazın',
    createNext: 'Başka bir ek açıklamalı yanıt ekle',
  },
  batchModal: {
    title: 'Toplu İçe Aktarma',
    csvUploadTitle: 'CSV dosyanızı buraya sürükleyip bırakın veya ',
    browse: 'gözatın',
    tip: 'CSV dosyası aşağıdaki yapıya uygun olmalıdır:',
    question: 'soru',
    answer: 'cevap',
    contentTitle: 'içerik parçası',
    content: 'içerik',
    template: 'Şablonu buradan indir',
    cancel: 'İptal',
    run: 'Toplu İşlemi Çalıştır',
    runError: 'Toplu işlem başarısız oldu',
    processing: 'Toplu işlemde',
    completed: 'İçe aktarma tamamlandı',
    error: 'İçe Aktarma Hatası',
    ok: 'Tamam',
  },
  errorMessage: {
    answerRequired: 'Cevap gerekli',
    queryRequired: 'Soru gerekli',
  },
  viewModal: {
    annotatedResponse: 'Ek Açıklama Yanıtı',
    hitHistory: 'Vuruş Geçmişi',
    hit: 'Vuruş',
    hits: 'Vuruşlar',
    noHitHistory: 'Vuruş geçmişi yok',
  },
  hitHistoryTable: {
    query: 'Sorgu',
    match: 'Eşleşme',
    response: 'Yanıt',
    source: 'Kaynak',
    score: 'Puan',
    time: 'Zaman',
  },
  initSetup: {
    title: 'Ek Açıklama Yanıtı İlk Kurulum',
    configTitle: 'Ek Açıklama Yanıtı Ayarı',
    confirmBtn: 'Kaydet ve Etkinleştir',
    configConfirmBtn: 'Kaydet',
  },
  embeddingModelSwitchTip: 'Ek açıklama metin vektörleştirme modeli, model değiştirmek yeniden yerleştirilecek ve ek maliyetlere yol açacaktır.',
}

export default translation
