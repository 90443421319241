const translation = {
  knowledge: '知识库',
  documentCount: ' 文档',
  videoCount: ' 视频',
  wordCount: '千字符',
  appCount: ' 关联Chatbot',
  createDataset: '创建文档知识库',
  createVideoDataset: '创建视频知识库',
  createDatasetIntro: '导入您自己的文本数据或通过 Webhook 实时写入数据以增强 LLM 的上下文。',
  deleteDatasetConfirmTitle: '要删除知识库吗？',
  deleteDatasetConfirmContent: '删除后将无法撤销。AI Bot将无法引用该知识库，所有的配置将被永久删除。',
  datasetUsedByApp: '某些应用正在使用该知识库。应用将无法再使用该知识库,所有的提示配置和日志将被永久删除。',
  datasetDeleted: '知识库已删除',
  datasetDeleteFailed: '删除知识库失败',
  didYouKnow: '你知道吗？',
  intro1: '知识库可以被集成到 Dify 应用中',
  intro2: '作为上下文',
  intro3: ',',
  intro4: '或可以',
  intro5: '创建',
  intro6: '为独立的 ChatGPT 插件发布使用',
  unavailable: '不可用',
  unavailableTip: '由于 embedding 模型不可用，需要配置默认 embedding 模型',
  datasets: '文档',
  videoDatasets: '视频',
  datasetsDisabledManage: '该Chatbot未成功添加知识库，请手动添加',
  datasetsApi: 'API',
  retrieval: {
    semantic_search: {
      title: '向量检索',
      description: '通过生成查询嵌入并查询与其向量表示最相似的文本分段',
    },
    full_text_search: {
      title: '全文检索',
      description: '索引文档中的所有词汇，从而允许用户查询任意词汇，并返回包含这些词汇的文本片段',
    },
    hybrid_search: {
      title: '混合检索',
      description: '同时执行全文检索和向量检索，并应用重排序步骤，从两类查询结果中选择匹配用户问题的最佳结果，用户可以选择设置权重或配置重新排序模型。',
      recommend: '推荐',
    },
    invertedIndex: {
      title: '倒排索引',
      description: '倒排索引是一种用于高效检索的结构。按术语组织，每个术语指向包含它的文档或网页',
    },
    change: '更改',
    changeRetrievalMethod: '更改检索方法',
  },
  docsFailedNotice: '文档无法被索引',
  retry: '重试',
  indexingTechnique: {
    high_quality: '高质量',
    economy: '经济',
  },
  indexingMethod: {
    semantic_search: '向量检索',
    full_text_search: '全文检索',
    hybrid_search: '混合检索',
    invertedIndex: '倒排索引',
  },
  mixtureHighQualityAndEconomicTip: '混合使用高质量和经济型知识库需要配置 Rerank 模型。',
  inconsistentEmbeddingModelTip: '当所选知识库配置的 Embedding 模型不一致时，需要配置 Rerank 模型。',
  retrievalSettings: '召回设置',
  rerankSettings: 'Rerank 设置',
  weightedScore: {
    title: '权重设置',
    description: '通过调整分配的权重，重新排序策略确定是优先进行语义匹配还是关键字匹配。',
    semanticFirst: '语义优先',
    keywordFirst: '关键词优先',
    customized: '自定义',
    semantic: '语义',
    keyword: '关键词',
  },
  nTo1RetrievalLegacy: '9 月 1 日起我们将不再提供此能力，推荐使用最新的多路召回获得更好的检索效果。',
  nTo1RetrievalLegacyLink: '了解更多',
  nTo1RetrievalLegacyLinkText: '9 月 1 日起我们将不再提供此能力。',
}

export default translation
