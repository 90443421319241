const translation = {
  input: '入力',
  result: '結果',
  detail: '詳細',
  tracing: 'トレース',
  resultPanel: {
    status: 'ステータス',
    time: '経過時間',
    tokens: 'トークンの合計',
  },
  meta: {
    title: 'メタデータ',
    status: 'ステータス',
    version: 'バージョン',
    executor: '実行者',
    startTime: '開始時間',
    time: '経過時間',
    tokens: 'トークンの合計',
    steps: '実行ステップ',
  },
  resultEmpty: {
    title: 'この実行では JSON 形式のみが出力されます',
    tipLeft: 'にアクセスしてください',
    link: '詳細パネル',
    tipRight: '表示します。',
  },
}

export default translation
