const translation = {
  title: 'Herramientas',
  createCustomTool: 'Crear Herramienta Personalizada',
  customToolTip: 'Aprende más sobre las herramientas personalizadas de Dify',
  type: {
    all: 'Todas',
    builtIn: 'Incorporadas',
    custom: 'Personalizadas',
    workflow: 'Flujo de Trabajo',
  },
  contribute: {
    line1: 'Estoy interesado en ',
    line2: 'contribuir herramientas a Dify.',
    viewGuide: 'Ver la guía',
  },
  author: 'Por',
  auth: {
    unauthorized: 'Para Autorizar',
    authorized: 'Autorizado',
    setup: 'Configurar la autorización para usar',
    setupModalTitle: 'Configurar Autorización',
    setupModalTitleDescription: 'Después de configurar las credenciales, todos los miembros dentro del espacio de trabajo pueden usar esta herramienta al orquestar aplicaciones.',
  },
  includeToolNum: '{{num}} herramientas incluidas',
  addTool: 'Agregar Herramienta',
  addToolModal: {
    type: 'tipo',
    category: 'categoría',
    add: 'agregar',
    added: 'agregada',
    manageInTools: 'Administrar en Herramientas',
    emptyTitle: 'No hay herramientas de flujo de trabajo disponibles',
    emptyTip: 'Ir a "Flujo de Trabajo -> Publicar como Herramienta"',
  },
  createTool: {
    title: 'Crear Herramienta Personalizada',
    editAction: 'Configurar',
    editTitle: 'Editar Herramienta Personalizada',
    name: 'Nombre',
    toolNamePlaceHolder: 'Ingresa el nombre de la herramienta',
    nameForToolCall: 'Nombre de llamada de la herramienta',
    nameForToolCallPlaceHolder: 'Utilizado para el reconocimiento automático, como getCurrentWeather, list_pets',
    nameForToolCallTip: 'Solo soporta números, letras y guiones bajos.',
    description: 'Descripción',
    descriptionPlaceholder: 'Breve descripción del propósito de la herramienta, por ejemplo, obtener la temperatura de una ubicación específica.',
    schema: 'Esquema',
    schemaPlaceHolder: 'Ingresa tu esquema OpenAPI aquí',
    viewSchemaSpec: 'Ver la Especificación OpenAPI-Swagger',
    importFromUrl: 'Importar desde URL',
    importFromUrlPlaceHolder: 'https://...',
    urlError: 'Por favor, ingresa una URL válida',
    examples: 'Ejemplos',
    exampleOptions: {
      json: 'Clima (JSON)',
      yaml: 'Tienda de Mascotas (YAML)',
      blankTemplate: 'Plantilla en Blanco',
    },
    availableTools: {
      title: 'Herramientas Disponibles',
      name: 'Nombre',
      description: 'Descripción',
      method: 'Método',
      path: 'Ruta',
      action: 'Acciones',
      test: 'Probar',
    },
    authMethod: {
      title: 'Método de Autorización',
      type: 'Tipo de Autorización',
      keyTooltip: 'Clave del encabezado HTTP, puedes dejarla como "Authorization" si no tienes idea de qué es o configurarla con un valor personalizado',
      types: {
        none: 'Ninguno',
        api_key: 'Clave API',
        apiKeyPlaceholder: 'Nombre del encabezado HTTP para la Clave API',
        apiValuePlaceholder: 'Ingresa la Clave API',
      },
      key: 'Clave',
      value: 'Valor',
    },
    authHeaderPrefix: {
      title: 'Tipo de Autenticación',
      types: {
        basic: 'Básica',
        bearer: 'Bearer',
        custom: 'Personalizada',
      },
    },
    privacyPolicy: 'Política de Privacidad',
    privacyPolicyPlaceholder: 'Por favor, ingresa la política de privacidad',
    toolInput: {
      title: 'Entrada de la Herramienta',
      name: 'Nombre',
      required: 'Requerido',
      method: 'Método',
      methodSetting: 'Configuración',
      methodSettingTip: 'El usuario completa la configuración de la herramienta',
      methodParameter: 'Parámetro',
      methodParameterTip: 'LLM completa durante la inferencia',
      label: 'Etiquetas',
      labelPlaceholder: 'Elige etiquetas (opcional)',
      description: 'Descripción',
      descriptionPlaceholder: 'Descripción del significado del parámetro',
    },
    customDisclaimer: 'Descargo de responsabilidad personalizado',
    customDisclaimerPlaceholder: 'Por favor, ingresa el descargo de responsabilidad personalizado',
    confirmTitle: '¿Confirmar para guardar?',
    confirmTip: 'Las aplicaciones que usen esta herramienta se verán afectadas',
    deleteToolConfirmTitle: '¿Eliminar esta Herramienta?',
    deleteToolConfirmContent: 'Eliminar la herramienta es irreversible. Los usuarios ya no podrán acceder a tu herramienta.',
  },
  test: {
    title: 'Probar',
    parametersValue: 'Parámetros y Valor',
    parameters: 'Parámetros',
    value: 'Valor',
    testResult: 'Resultados de la Prueba',
    testResultPlaceholder: 'El resultado de la prueba se mostrará aquí',
  },
  thought: {
    using: 'Usando',
    used: 'Usado',
    requestTitle: 'Solicitud a',
    responseTitle: 'Respuesta de',
  },
  setBuiltInTools: {
    info: 'Información',
    setting: 'Ajuste',
    toolDescription: 'Descripción de la herramienta',
    parameters: 'parámetros',
    string: 'cadena',
    number: 'número',
    required: 'Requerido',
    infoAndSetting: 'Información y Ajustes',
  },
  noCustomTool: {
    title: '¡Sin herramientas personalizadas!',
    content: 'Agrega y administra tus herramientas personalizadas aquí para construir aplicaciones de inteligencia artificial.',
    createTool: 'Crear Herramienta',
  },
  noSearchRes: {
    title: '¡Lo sentimos, no hay resultados!',
    content: 'No encontramos herramientas que coincidan con tu búsqueda.',
    reset: 'Restablecer Búsqueda',
  },
  builtInPromptTitle: 'Aviso',
  toolRemoved: 'Herramienta eliminada',
  notAuthorized: 'Herramienta no autorizada',
  howToGet: 'Cómo obtener',
  openInStudio: 'Abrir en Studio',
  toolNameUsageTip: 'Nombre de llamada de la herramienta para razonamiento y promoción de agentes',
}

export default translation
