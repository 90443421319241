const translation = {
  pageTitle: 'Cześć, zaczynajmy!👋',
  welcome: 'Witaj w Dify, zaloguj się, aby kontynuować.',
  email: 'Adres e-mail',
  emailPlaceholder: 'Twój adres e-mail',
  password: 'Hasło',
  passwordPlaceholder: 'Twoje hasło',
  name: 'Nazwa użytkownika',
  namePlaceholder: 'Twoja nazwa użytkownika',
  forget: 'Zapomniałeś hasła?',
  signBtn: 'Zaloguj się',
  sso: 'Kontynuuj za pomocą SSO',
  installBtn: 'Ustaw',
  setAdminAccount: 'Ustawianie konta administratora',
  setAdminAccountDesc:
    'Maksymalne uprawnienia dla konta administratora, które można użyć do tworzenia aplikacji i zarządzania dostawcami LLM, itp.',
  createAndSignIn: 'Utwórz i zaloguj się',
  oneMoreStep: 'Jeszcze jeden krok',
  createSample:
    'Na podstawie tych informacji, utworzymy dla Ciebie przykładową aplikację',
  invitationCode: 'Kod zaproszenia',
  invitationCodePlaceholder: 'Twój kod zaproszenia',
  interfaceLanguage: 'Język interfejsu',
  timezone: 'Strefa czasowa',
  go: 'Przejdź do Dify',
  sendUsMail:
    'Wyślij nam e-mail z swoim wstępem, a my zajmiemy się prośbą o zaproszenie.',
  acceptPP: 'Przeczytałem/am i akceptuję politykę prywatności',
  reset: 'Uruchom poniższą komendę, aby zresetować swoje hasło',
  withGitHub: 'Kontynuuj za pomocą GitHub',
  withGoogle: 'Kontynuuj za pomocą Google',
  rightTitle: 'Odblokuj pełny potencjał LLM',
  rightDesc:
    'Łatwo buduj wizualnie atrakcyjne, działające i udoskonalane aplikacje AI.',
  tos: 'Warunki świadczenia usług',
  pp: 'Polityka prywatności',
  tosDesc: 'Założeniem konta zgadzasz się z naszymi',
  goToInit: 'Jeśli nie zainicjowałeś konta, przejdź do strony inicjalizacji',
  donthave: 'Nie masz?',
  invalidInvitationCode: 'Niewłaściwy kod zaproszenia',
  accountAlreadyInited: 'Konto już zainicjowane',
  forgotPassword: 'Zapomniałeś hasła?',
  resetLinkSent: 'Link resetujący został wysłany',
  sendResetLink: 'Wyślij link resetujący',
  backToSignIn: 'Powrót do logowania',
  forgotPasswordDesc: 'Proszę podać swój adres e-mail, aby zresetować hasło. Wyślemy Ci e-mail z instrukcjami, jak zresetować hasło.',
  checkEmailForResetLink: 'Proszę sprawdzić swój e-mail w poszukiwaniu linku do resetowania hasła. Jeśli nie pojawi się w ciągu kilku minut, sprawdź folder spam.',
  passwordChanged: 'Zaloguj się teraz',
  changePassword: 'Zmień hasło',
  changePasswordTip: 'Wprowadź nowe hasło do swojego konta',
  invalidToken: 'Nieprawidłowy lub wygasły token',
  confirmPassword: 'Potwierdź hasło',
  confirmPasswordPlaceholder: 'Potwierdź nowe hasło',
  passwordChangedTip: 'Twoje hasło zostało pomyślnie zmienione',
  error: {
    emailEmpty: 'Adres e-mail jest wymagany',
    emailInValid: 'Proszę wpisać prawidłowy adres e-mail',
    nameEmpty: 'Nazwa jest wymagana',
    passwordEmpty: 'Hasło jest wymagane',
    passwordInvalid:
      'Hasło musi zawierać litery i cyfry, a jego długość musi być większa niż 8',
  },
  license: {
    tip: 'Przed rozpoczęciem wersji społecznościowej Dify, przeczytaj GitHub',
    link: 'Licencję open-source',
  },
  join: 'Dołącz',
  joinTipStart: 'Zapraszam Cię do dołączenia do',
  joinTipEnd: 'zespołu na Dify',
  invalid: 'Link wygasł',
  explore: 'Odkryj Dify',
  activatedTipStart: 'Dołączyłeś do',
  activatedTipEnd: 'zespołu',
  activated: 'Zaloguj się teraz',
  adminInitPassword: 'Hasło inicjalizacyjne administratora',
  validate: 'Sprawdź',
}

export default translation
