const translation = {
  confirm: {
    application: {
      title: 'Osiągnięto maksymalną liczbę botów AI, nie można utworzyć nowego',
      content: 'Możesz skontaktować się z nami w celu uaktualnienia wersji lub usunięcia niektórych chatbotów',
    },
    document: {
      title: 'Osiągnięto maksymalną liczbę dokumentów, nie można dodać nowego',
      content: 'Możesz skontaktować się z nami w celu uaktualnienia wersji lub usunięcia niektórych dokumentów',
    },
    video: {
      title: 'Osiągnięto maksymalną liczbę filmów, nie można dodać nowego',
      content: 'Możesz skontaktować się z nami w celu uaktualnienia wersji lub usunięcia niektórych filmów',
    },
    buttonText: 'Rozumiem',
    messageLimitAnswer: 'Wygląda na to, że mały słoń zasnął, skontaktuj się z obsługą klienta, aby uzyskać wsparcie',
  },
}

export default translation
