const translation = {
  steps: {
    header: {
      creation: 'Creați Cunoștințe',
      update: 'Adăugați date',
    },
    one: 'Alegeți sursa de date',
    two: 'Prelucrarea și curățarea textului',
    three: 'Executați și finalizați',
  },
  error: {
    unavailable: 'Această Cunoștință nu este disponibilă',
  },
  stepOne: {
    filePreview: 'Previzualizare fișier',
    pagePreview: 'Previzualizare pagină',
    dataSourceType: {
      file: 'Importați din fișier text',
      notion: 'Sincronizați din Notion',
      web: 'Sincronizați din site web',
    },
    uploader: {
      title: 'Încărcați fișier text',
      button: 'Trageți și fixați fișierul, sau',
      browse: 'Răsfoire',
      tip: 'Acceptă {{supportTypes}}. Maxim {{size}}MB fiecare.',
      validation: {
        typeError: 'Tipul de fișier nu este acceptat',
        size: 'Fișierul este prea mare. Maximul este de {{size}}MB',
        count: 'Nu se acceptă mai multe fișiere',
        filesNumber: 'Ați atins limita de încărcare în lot de {{filesNumber}} fișiere.',
      },
      cancel: 'Anulează',
      change: 'Schimbă',
      failed: 'Încărcarea a eșuat',
    },
    notionSyncTitle: 'Notion nu este conectat',
    notionSyncTip: 'Pentru a sincroniza cu Notion, trebuie mai întâi să se stabilească o conexiune la Notion.',
    connect: 'Mergi la conectare',
    button: 'următorul',
    emptyDatasetCreation: 'Vreau să creez o Cunoștință goală',
    modal: {
      title: 'Creați o Cunoștință goală',
      tip: 'O Cunoștință goală nu va conține niciun document, iar dvs. puteți încărca documente în orice moment.',
      input: 'Numele Cunoștinței',
      placeholder: 'Vă rugăm să introduceți',
      nameNotEmpty: 'Numele nu poate fi gol',
      nameLengthInvaild: 'Numele trebuie să fie între 1 și 40 de caractere',
      cancelButton: 'Anulează',
      confirmButton: 'Creează',
      failed: 'Crearea a eșuat',
    },
  },
  stepTwo: {
    segmentation: 'Setări de segmentare',
    auto: 'Automat',
    autoDescription: 'Setează automat regulile de segmentare și prelucrare. Utilizatorilor necunoscuți li se recomandă să selecteze această opțiune.',
    custom: 'Personalizat',
    customDescription: 'Personalizați regulile de segmentare, lungimea segmentelor și regulile de prelucrare, etc.',
    separator: 'Identificator de segment',
    separatorPlaceholder: 'De exemplu, linie nouă (\\\\n) sau separator special (cum ar fi "***")',
    maxLength: 'Lungimea maximă a segmentului',
    overlap: 'Suprapunerea segmentelor',
    overlapTip: 'Setarea suprapunerii segmentelor poate menține relevanța semantică între ele, îmbunătățind efectul de recuperare. Se recomandă să setați 10%-25% din dimensiunea maximă a segmentului.',
    overlapCheck: 'suprapunerea segmentului nu ar trebui să fie mai mare decât lungimea maximă a segmentului',
    rules: 'Reguli de prelucrare a textului',
    removeExtraSpaces: 'Înlocuiește spațiile consecutive, liniile noi și taburile',
    removeUrlEmails: 'Șterge toate adresele URL și e-mailurile',
    removeStopwords: 'Eliminați cuvintele de umplere, cum ar fi "a", "an", "the"',
    preview: 'Confirmă și previzualizează',
    reset: 'Resetează',
    indexMode: 'Mod de indexare',
    qualified: 'Calitate ridicată',
    recommend: 'Recomandă',
    qualifiedTip: 'Apelează interfața de încorporare a sistemului implicit pentru a procesa și a oferi o precizie mai mare atunci când utilizatorii interoghează.',
    warning: 'Vă rugăm să setați mai întâi cheia API a furnizorului de modele.',
    click: 'Mergi la setări',
    economical: 'Economic',
    economicalTip: 'Utilizați motoare de vectori offline, indexuri de cuvinte cheie etc. pentru a reduce precizia fără a cheltui jetoane',
    QATitle: 'Segmentarea în format Întrebare și Răspuns',
    QATip: 'Activarea acestei opțiuni va consuma mai multe jetoane',
    QALanguage: 'Segmentează folosind',
    emstimateCost: 'Estimare',
    emstimateSegment: 'Segmente estimate',
    segmentCount: 'segmente',
    calculating: 'Se calculează...',
    fileSource: 'Prelucrează documente',
    notionSource: 'Prelucrează pagini',
    other: 'și alte ',
    fileUnit: ' fișiere',
    notionUnit: ' pagini',
    previousStep: 'Pasul anterior',
    nextStep: 'Salvează și Procesează',
    save: 'Salvează și Procesează',
    cancel: 'Anulează',
    sideTipTitle: 'De ce segmentare și prelucrare?',
    sideTipP1: 'Atunci când se prelucrează date text, segmentarea și curățarea sunt două etape importante de pre-procesare.',
    sideTipP2: 'Segmentarea împarte textul lung în paragrafe, astfel încât modelele să poată înțelege mai bine. Acest lucru îmbunătățește calitatea și relevanța rezultatelor modelului.',
    sideTipP3: 'Curățarea elimină caracterele și formatele inutile, făcând Cunoștințele mai curate și mai ușor de analizat.',
    sideTipP4: 'O segmentare și curățare adecvată îmbunătățesc performanța modelului, oferind rezultate mai precise și valoroase.',
    previewTitle: 'Previzualizare',
    previewTitleButton: 'Previzualizare',
    previewButton: 'Comutare la format întrebare și răspuns',
    previewSwitchTipStart: 'Previzualizarea curentă a segmentului este în format text, comutarea la o previzualizare în format întrebare și răspuns va',
    previewSwitchTipEnd: ' consuma jetoane suplimentare',
    characters: 'caractere',
    indexSettedTip: 'Pentru a modifica metoda de indexare, vă rugăm să mergeți la ',
    retrivalSettedTip: 'Pentru a modifica metoda de indexare, vă rugăm să mergeți la ',
    datasetSettingLink: 'setările Cunoștinței.',
  },
  stepThree: {
    creationTitle: '🎉 Cunoștință creată',
    creationContent: 'Am denumit automat Cunoștința, o puteți modifica în orice moment',
    label: 'Numele Cunoștinței',
    additionTitle: '🎉 Document încărcat',
    additionP1: 'Documentul a fost încărcat în Cunoștință',
    additionP2: ', îl puteți găsi în lista de documente a Cunoștinței.',
    stop: 'Oprește procesarea',
    resume: 'Reia procesarea',
    navTo: 'Mergi la document',
    sideTipTitle: 'Ce urmează',
    sideTipContent: 'După ce documentul a terminat indexarea, Cunoștința poate fi integrată în aplicație ca context, puteți găsi setările contextuale în pagina de orchestrare a prompturilor. De asemenea, o puteți crea ca un plugin de indexare ChatGPT independent pentru a o publica.',
    modelTitle: 'Sunteți sigur că doriți să opriți încorporarea?',
    modelContent: 'Dacă trebuie să reluați procesarea mai târziu, veți continua de unde ați rămas.',
    modelButtonConfirm: 'Confirmă',
    modelButtonCancel: 'Anulează',
  },
}

export default translation
