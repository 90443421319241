const translation = {
  title: '標註',
  name: '標註回覆',
  editBy: '{{author}}編輯的答案',
  noData: {
    title: '沒有標註',
    description: '你可以在應用會話除錯中編輯標註，也可以在此批次匯入標註用於高質量回復。',
  },
  table: {
    header: {
      question: '提問',
      match: '匹配',
      response: '回覆',
      answer: '答案',
      createdAt: '建立時間',
      hits: '命中次數',
      actions: '操作',
      addAnnotation: '新增標註',
      bulkImport: '批次匯入',
      bulkExport: '批次匯出',
      clearAll: '刪除所有標註',
    },
  },
  editModal: {
    title: '編輯標註回覆',
    queryName: '使用者提問',
    answerName: '機器回覆',
    yourAnswer: '您的回覆',
    answerPlaceholder: '在這裡輸入您的回覆',
    yourQuery: '您的提問',
    queryPlaceholder: '在這裡輸入您的提問',
    removeThisCache: '刪除此標註',
    createdAt: '創建於',
  },
  addModal: {
    title: '新增標註回覆',
    queryName: '提問',
    answerName: '回覆',
    answerPlaceholder: '輸入回覆',
    queryPlaceholder: '輸入提問',
    createNext: '新增下一個標註回覆',
  },
  batchModal: {
    title: '批次匯入',
    csvUploadTitle: '將您的 CSV 檔案拖放到此處，或',
    browse: '選擇檔案',
    tip: 'CSV 檔案必須符合以下結構：',
    question: '問題',
    answer: '回答',
    contentTitle: '分段內容',
    content: '內容',
    template: '下載模板',
    cancel: '取消',
    run: '匯入',
    runError: '批次匯入失敗',
    processing: '批次處理中',
    completed: '匯入完成',
    error: '匯入出錯',
    ok: '確定',
  },
  errorMessage: {
    answerRequired: '回覆不能為空',
    queryRequired: '提問不能為空',
  },
  viewModal: {
    annotatedResponse: '標註回覆',
    hitHistory: '命中歷史',
    hit: '次命中',
    hits: '次命中',
    noHitHistory: '沒有命中歷史',
  },
  hitHistoryTable: {
    question: '問題',
    query: '提問',
    match: '匹配',
    response: '回覆',
    source: '來源',
    score: '分數',
    time: '時間',
  },
  initSetup: {
    title: '標註回覆初始設定',
    configTitle: '標註回覆設定',
    confirmBtn: '儲存並啟用',
    configConfirmBtn: '儲存',
  },
  embeddingModelSwitchTip: '標註文字向量化模型，切換模型會重新嵌入，產生額外費用消耗',
}

export default translation
