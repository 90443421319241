const translation = {
  currentPlan: 'Current Plan',
  upgradeBtn: {
    plain: 'Upgrade Plan',
    encourage: 'Upgrade Now',
    encourageShort: 'Upgrade',
  },
  viewBilling: 'Manage Billing and Subscriptions',
  buyPermissionDeniedTip: 'Please contact your enterprise admin to subscribe',
  plansCommon: {
    title: 'Choose the Right Plan for You',
    yearlyTip: 'Subscribe to the annual plan and get 2 months free!',
    mostPopular: 'Most Popular',
    planRange: {
      monthly: 'Monthly',
      yearly: 'Yearly',
    },
    month: 'Month',
    year: 'Year',
    save: 'Save',
    currentPlan: 'Current Plan',
    contractSales: 'Contact Sales',
    contractOwner: 'Contact Team Admin',
    free: 'Free',
    startForFree: 'Start for Free',
    getStartedWith: 'Get Started with',
    contactSales: 'Contact Sales',
    talkToSales: 'Talk to Sales',
    modelProviders: 'Supported Model Providers',
    teamMembers: 'Team Members',
    buildApps: 'Number of Apps',
    annotationQuota: 'Annotated Responses',
    vectorSpace: 'Vector Space',
    vectorSpaceTooltip: 'Vector Space is the long-term memory system needed for LLMs to understand your data.',
    vectorSpaceBillingTooltip: 'Vector Storage is used to vectorize your knowledge base for long-term memory storage, helping LLMs understand the data. Approx. 1MB can store vectorized data of about 1.2 million characters (estimated with OpenAI Embedding model; calculation may vary with different models). Actual compression or size reduction during vectorization depends on the complexity and redundancy of the content.',
    documentsUploadQuota: 'Document Upload Quota',
    documentProcessingPriority: 'Document Processing Priority',
    documentProcessingPriorityTip: 'Upgrade your plan for higher document processing priority',
    documentProcessingPriorityUpgrade: 'Process more data faster and with greater accuracy.',
    priority: {
      'standard': 'Standard',
      'priority': 'Priority',
      'top-priority': 'Top Priority',
    },
    logsHistory: 'Logs History',
    customTools: 'Custom Tools',
    unavailable: 'Unavailable',
    days: 'Days',
    unlimited: 'Unlimited',
    support: 'Support',
    supportItems: {
      communityForums: 'Community Forums',
      emailSupport: 'Email Support',
      priorityEmail: 'Priority Email and Chat Support',
      logoChange: 'Logo Change',
      SSOAuthentication: 'SSO Authentication',
      personalizedSupport: 'Personalized Support',
      dedicatedAPISupport: 'Dedicated API Support',
      customIntegration: 'Custom Integration and Support',
      ragAPIRequest: 'RAG API Requests',
      bulkUpload: 'Bulk Upload',
      agentMode: 'Agent Mode',
      workflow: 'Workflow',
      llmLoadingBalancing: 'LLM Load Balancing',
      llmLoadingBalancingTooltip: 'Add multiple API keys to models, effectively bypassing the API rate limits. ',
    },
    comingSoon: 'Coming soon',
    member: 'Member',
    memberAfter: 'Members',
    messageRequest: {
      title: 'Message Quota',
      tooltip: 'Message response quota based on OpenAI models for different plans.',
    },
    annotatedResponse: {
      title: 'Annotated Responses',
      tooltip: 'Annotated Response functionality provides customizable high-quality Q&A responses through manual editing.',
    },
    ragAPIRequestTooltip: 'Refers to the API requests for Dify Knowledge Base data processing capability.',
    receiptInfo: 'Only team owners and administrators can subscribe and view billing information',
  },
  plans: {
    sandbox: {
      name: 'Sandbox',
      description: '200 GPT Free Trials',
      includesTitle: 'Includes:',
    },
    professional: {
      name: 'Professional',
      description: 'Empower individuals and small teams with affordable access to more features.',
      includesTitle: 'Everything in the Sandbox plan, plus:',
    },
    team: {
      name: 'Team',
      description: 'Unlimited collaboration and top-tier performance.',
      includesTitle: 'Everything in the Professional plan, plus:',
    },
    enterprise: {
      name: 'Enterprise',
      description: 'Access full capabilities and support for large-scale mission-critical systems.',
      includesTitle: 'Everything in the Team plan, plus:',
    },
  },
  vectorSpace: {
    fullTip: 'Vector Space is full.',
    fullSolution: 'Upgrade your plan to get more space.',
  },
  apps: {
    fullTipLine1: 'Upgrade your plan to',
    fullTipLine2: 'build more apps.',
  },
  annotatedResponse: {
    fullTipLine1: 'Upgrade your plan to',
    fullTipLine2: 'annotate more conversations.',
    quotaTitle: 'Annotated Quota',
  },
}

export default translation
