const translation = {
  title: 'لاگ‌ها',
  description: 'لاگ‌ها وضعیت اجرایی برنامه را ثبت می‌کنند، شامل ورودی‌های کاربر و پاسخ‌های هوش مصنوعی.',
  dateTimeFormat: 'MM/DD/YYYY hh:mm A',
  table: {
    header: {
      updatedTime: 'زمان به‌روزرسانی',
      time: 'زمان ایجاد',
      endUser: 'کاربر نهایی',
      input: 'ورودی',
      output: 'خروجی',
      summary: 'عنوان',
      messageCount: 'تعداد پیام',
      userRate: 'امتیاز کاربر',
      adminRate: 'امتیاز اپراتور',
      startTime: 'زمان شروع',
      status: 'وضعیت',
      runtime: 'زمان اجرا',
      tokens: 'توکن‌ها',
      user: 'کاربر نهایی',
      version: 'نسخه',
    },
    pagination: {
      previous: 'قبلی',
      next: 'بعدی',
    },
    empty: {
      noChat: 'هنوز مکالمه‌ای وجود ندارد',
      noOutput: 'خروجی وجود ندارد',
      element: {
        title: 'کسی هست؟',
        content: 'در اینجا تعاملات بین کاربران نهایی و برنامه‌های هوش مصنوعی را مشاهده و حاشیه‌نویسی کنید تا دقت هوش مصنوعی بهبود یابد. می‌توانید <shareLink>به اشتراک بگذارید</shareLink> یا <testLink>برنامه وب را تست کنید</testLink> و سپس به این صفحه برگردید.',
      },
    },
  },
  detail: {
    time: 'زمان',
    conversationId: 'شناسه مکالمه',
    promptTemplate: 'قالب درخواست',
    promptTemplateBeforeChat: 'قالب درخواست قبل از چت · به عنوان پیام سیستمی',
    annotationTip: 'بهبودها توسط {{user}} علامت‌گذاری شده است',
    timeConsuming: '',
    second: 'ثانیه',
    tokenCost: 'توکن مصرفی',
    loading: 'در حال بارگذاری',
    operation: {
      like: 'پسندیدن',
      dislike: 'نپسندیدن',
      addAnnotation: 'اضافه کردن بهبود',
      editAnnotation: 'ویرایش بهبود',
      annotationPlaceholder: 'پاسخ مورد انتظاری که می‌خواهید هوش مصنوعی بدهد را وارد کنید، که می‌تواند برای بهبود مدل و کیفیت تولید متن در آینده استفاده شود.',
    },
    variables: 'متغیرها',
    uploadImages: 'تصاویر آپلود شده',
  },
  filter: {
    period: {
      today: 'امروز',
      last7days: '7 روز گذشته',
      last4weeks: '4 هفته گذشته',
      last3months: '3 ماه گذشته',
      last12months: '12 ماه گذشته',
      monthToDate: 'از ابتدای ماه تاکنون',
      quarterToDate: 'از ابتدای فصل تاکنون',
      yearToDate: 'از ابتدای سال تاکنون',
      allTime: 'همه زمان‌ها',
    },
    annotation: {
      all: 'همه',
      annotated: 'بهبودهای حاشیه‌نویسی شده ({{count}} آیتم)',
      not_annotated: 'حاشیه‌نویسی نشده',
    },
    sortBy: 'مرتب‌سازی بر اساس:',
    descending: 'نزولی',
    ascending: 'صعودی',
  },
  workflowTitle: 'لاگ‌های جریان کاری',
  workflowSubtitle: 'لاگ عملیات خودکار را ثبت کرده است.',
  runDetail: {
    title: 'لاگ مکالمه',
    workflowTitle: 'جزئیات لاگ',
  },
  promptLog: 'لاگ درخواست',
  agentLog: 'لاگ عامل',
  viewLog: 'مشاهده لاگ',
  agentLogDetail: {
    agentMode: 'حالت عامل',
    toolUsed: 'ابزار استفاده شده',
    iterations: 'تکرارها',
    iteration: 'تکرار',
    finalProcessing: 'پردازش نهایی',
  },
}

export default translation
