const translation = {
  knowledgeSupplement: {
    title: 'Knowledge Supplement',
    description: 'Summarize the knowledge gaps of users, updated every Monday. After supplementation, the AI will use the given responses to answer similar questions, improving the quality of answers.',
    noData: {
      title: 'It is empty here',
      description: 'There is no knowledge to supplement for now. You can keep an eye on the AI’s performance in the conversation records.',
    },
    saveSuccess: 'Knowledge supplement saved successfully',
    valid: {
      wordLimit: 'Response content can be up to 2000 characters. Please shorten the content before saving.',
    },
  },
  pending: {
    title: 'Negative Feedback Optimization',
    description: 'Records AI responses that users were not satisfied with. After optimization, the AI will use the given responses to answer similar questions, improving the quality of answers.',
    noData: {
      title: 'It is empty here',
      description: 'There are no user complaints for now. You can keep an eye on the AI’s performance in the conversation records.',
    },
    checkConversation: 'View full conversation',
    saveSuccess: 'Q&A optimization saved successfully',
  },
  optimized: {
    title: 'Optimized',
    description: 'Records the optimized Q&A; you can adjust the Q&A content and view the AI’s usage history here.',
    noData: {
      title: 'It is empty here',
      description: 'You can check the pending list or conversation records to optimize unsatisfactory AI responses.',
    },
    viewModal: {
      optimizedTitle: 'Optimized Q&A',
      historyTitle: 'AI Usage History',
    },
  },
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      userQuestion: 'User Question',
      aiAnswer: 'AI Response',
      question: 'Question',
      answer: 'Answer',
      createdAt: 'Creation Time',
      hits: 'AI Usage Count',
      actions: 'Actions',
      time: 'Conversation Time',
      questionCount: 'Question Count',
    },
    pagination: {
      previous: 'Previous Page',
      next: 'Next Page',
    },
  },
  confirm: {
    dismiss: 'Dismiss this question',
    delete: 'Delete this optimization',
  },
  operation: {
    edit: 'Optimize this response',
    reEdit: 'Edit optimization content',
    save: 'Save optimization',
  },
  editModal: {
    question: 'Question',
    answer: 'Answer',
    queryName: 'User Question',
    answerName: 'AI Response',
    yourAnswer: 'Optimized Response',
    answerPlaceholder: 'Enter the desired AI response here',
    yourQuery: 'Optimized Question',
    queryPlaceholder: 'Rephrase the user question into a more general and clearer query, which helps AI to match similar questions better',
  },
  log: {
  },
}

export default translation
