const translation = {
  createApp: 'CREEAZĂ APLICAȚIE',
  types: {
    all: 'Toate',
    chatbot: 'Chatbot',
    agent: 'Agent',
    workflow: 'Flux de lucru',
    completion: 'Finalizare',
  },
  duplicate: 'Duplicat',
  duplicateTitle: 'Duplică Aplicația',
  export: 'Exportă DSL',
  exportFailed: 'Exportul DSL a eșuat.',
  importDSL: 'Importă fișier DSL',
  createFromConfigFile: 'Creează din fișier DSL',
  deleteAppConfirmTitle: 'Ștergi această aplicație?',
  deleteAppConfirmContent:
    'Ștergerea aplicației este ireversibilă. Utilizatorii nu vor mai putea accesa aplicația ta, iar toate configurațiile promptului și jurnalele vor fi șterse permanent.',
  appDeleted: 'Aplicația a fost ștearsă',
  appDeleteFailed: 'Ștergerea aplicației a eșuat',
  join: 'Alătură-te comunității',
  communityIntro:
    'Discută cu membrii echipei, colaboratorii și dezvoltatorii pe diferite canale.',
  roadmap: 'Vezi planul nostru de dezvoltare',
  newApp: {
    startFromBlank: 'Creează din Nou',
    startFromTemplate: 'Creează din Șablon',
    captionAppType: 'Ce tip de aplicație vrei să creezi?',
    chatbotDescription: 'Construiește o aplicație bazată pe chat. Această aplicație folosește un format întrebare-răspuns, permițând mai multe runde de conversație continuă.',
    completionDescription: 'Construiește o aplicație care generează text de înaltă calitate pe baza indicațiilor, cum ar fi generarea de articole, rezumate, traduceri și mai multe.',
    completionWarning: 'Acest tip de aplicație nu va mai fi acceptat.',
    agentDescription: 'Construiește un Agent inteligent care poate alege în mod autonom instrumentele pentru a îndeplini sarcinile',
    workflowDescription: 'Construiește o aplicație care generează text de înaltă calitate pe baza unui flux de lucru orchestrat cu un grad ridicat de personalizare. Este potrivit pentru utilizatorii experimentați.',
    workflowWarning: 'În prezent în beta',
    chatbotType: 'Metodă de orchestrare a chatbot-ului',
    basic: 'De bază',
    basicTip: 'Pentru începători, se poate comuta la Chatflow mai târziu',
    basicFor: 'PENTRU ÎNCEPĂTORI',
    basicDescription: 'Orchestrarea de bază permite orchestrarea unei aplicații Chatbot folosind setări simple, fără posibilitatea de a modifica prompturile încorporate. Este potrivit pentru începători.',
    advanced: 'Chatflow',
    advancedFor: 'Pentru utilizatori avansați',
    advancedDescription: 'Orchestrarea fluxului de lucru orchestrează chatboți sub forma fluxurilor de lucru, oferind un grad ridicat de personalizare, inclusiv posibilitatea de a edita prompturile încorporate. Este potrivit pentru utilizatorii experimentați.',
    captionName: 'Pictogramă și nume aplicație',
    appNamePlaceholder: 'Dă-i aplicației tale un nume',
    captionDescription: 'Descriere',
    appDescriptionPlaceholder: 'Introduceți descrierea aplicației',
    useTemplate: 'Folosește acest șablon',
    previewDemo: 'Previzualizează demo',
    chatApp: 'Asistent',
    chatAppIntro:
      'Vreau să construiesc o aplicație bazată pe chat. Această aplicație folosește un format întrebare-răspuns, permițând mai multe runde de conversație continuă.',
    agentAssistant: 'Asistent Agent Nou',
    completeApp: 'Generator de text',
    completeAppIntro:
      'Vreau să creez o aplicație care generează text de înaltă calitate pe baza indicațiilor, cum ar fi generarea de articole, rezumate, traduceri și mai multe.',
    showTemplates: 'Vreau să aleg dintr-un șablon',
    hideTemplates: 'Înapoi la selecția modului',
    Create: 'Creează',
    Cancel: 'Anulează',
    nameNotEmpty: 'Numele nu poate fi gol',
    appTemplateNotSelected: 'Vă rugăm să selectați un șablon',
    appTypeRequired: 'Vă rugăm să selectați un tip de aplicație',
    appCreated: 'Aplicația a fost creată',
    appCreateFailed: 'Crearea aplicației a eșuat',
  },
  editApp: 'Editează Info',
  editAppTitle: 'Editează Info Aplicație',
  editDone: 'Informațiile despre aplicație au fost actualizate',
  editFailed: 'Actualizarea informațiilor despre aplicație a eșuat',
  iconPicker: {
    ok: 'OK',
    cancel: 'Anulează',
    emoji: 'Emoji',
    image: 'Imagine',
  },
  switch: 'Comută la Orchestrare Flux de Lucru',
  switchTipStart: 'O nouă copie a aplicației va fi creată pentru tine, iar noua copie va comuta la Orchestrare Flux de Lucru. Noua copie ',
  switchTip: 'nu va permite',
  switchTipEnd: ' comutarea înapoi la Orchestrare de Bază.',
  switchLabel: 'Copia aplicației care urmează să fie creată',
  removeOriginal: 'Șterge aplicația originală',
  switchStart: 'Începe comutarea',
  typeSelector: {
    all: 'TOATE Tipurile',
    chatbot: 'Chatbot',
    agent: 'Agent',
    workflow: 'Flux de lucru',
    completion: 'Finalizare',
  },
  tracing: {
    title: 'Urmărirea performanței aplicației',
    description: 'Configurarea unui furnizor LLMOps terț și urmărirea performanței aplicației.',
    config: 'Configurare',
    collapse: 'Restrânge',
    expand: 'Extinde',
    tracing: 'Urmărire',
    disabled: 'Dezactivat',
    disabledTip: 'Vă rugăm să configurați mai întâi furnizorul',
    enabled: 'În serviciu',
    tracingDescription: 'Captează contextul complet al execuției aplicației, inclusiv apelurile LLM, context, prompt-uri, cereri HTTP și altele, către o platformă de urmărire terță.',
    configProviderTitle: {
      configured: 'Configurat',
      notConfigured: 'Configurați furnizorul pentru a activa urmărirea',
      moreProvider: 'Mai mulți furnizori',
    },
    langsmith: {
      title: 'LangSmith',
      description: 'O platformă de dezvoltare all-in-one pentru fiecare etapă a ciclului de viață al aplicației bazate pe LLM.',
    },
    langfuse: {
      title: 'Langfuse',
      description: 'Urmărire, evaluări, gestionarea prompt-urilor și metrici pentru depanarea și îmbunătățirea aplicației dvs. LLM.',
    },
    inUse: 'În utilizare',
    configProvider: {
      title: 'Configurare ',
      placeholder: 'Introduceți {{key}}-ul dvs.',
      project: 'Proiect',
      publicKey: 'Cheie publică',
      secretKey: 'Cheie secretă',
      viewDocsLink: 'Vizualizați documentația {{key}}',
      removeConfirmTitle: 'Eliminați configurația {{key}}?',
      removeConfirmContent: 'Configurația curentă este în uz, eliminarea acesteia va dezactiva funcția de Urmărire.',
    },
  },
}

export default translation
