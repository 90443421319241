const translation = {
  confirm: {
    application: {
      title: 'AI Bot数量已达上限，暂时无法创建',
      content: '可联系我们升级版本，或删除部分Chatbot',
    },
    document: {
      title: '文档数量已达上限，暂时无法添加',
      content: '可联系我们升级版本，或删除部分文档',
    },
    video: {
      title: '视频数量已达上限，暂时无法添加',
      content: '可联系我们升级版本，或删除部分视频',
    },
    buttonText: '我知道了',
    messageLimitAnswer: '小象似乎睡着了，请联系人工客服获取支持哦',
  },
}

export default translation
