const translation = {
  knowledgeSupplement: {
    title: 'تکمیل دانش',
    description: 'خلاصه‌ای از شکاف‌های دانش کاربران، هر دوشنبه به‌روزرسانی می‌شود. پس از تکمیل، AI از پاسخ‌های داده شده برای پاسخ به سوالات مشابه استفاده خواهد کرد و کیفیت پاسخ‌ها را بهبود می‌بخشد.',
    noData: {
      title: 'اینجا خالی است',
      description: 'در حال حاضر دانشی برای تکمیل وجود ندارد. می‌توانید عملکرد AI را در سوابق مکالمه پیگیری کنید.',
    },
    saveSuccess: 'تکمیل دانش با موفقیت ذخیره شد',
    valid: {
      wordLimit: 'محتوای پاسخ می‌تواند تا 2000 کاراکتر باشد. لطفاً قبل از ذخیره، محتوا را کوتاه کنید.',
    },
  },
  pending: {
    title: 'بهینه‌سازی بازخورد منفی',
    description: 'پاسخ‌های AI که کاربران از آن‌ها راضی نبودند را ثبت می‌کند. پس از بهینه‌سازی، AI از پاسخ‌های داده شده برای پاسخ به سوالات مشابه استفاده خواهد کرد و کیفیت پاسخ‌ها را بهبود می‌بخشد.',
    noData: {
      title: 'اینجا خالی است',
      description: 'در حال حاضر هیچ شکایتی از کاربران وجود ندارد. می‌توانید عملکرد AI را در سوابق مکالمه پیگیری کنید.',
    },
    checkConversation: 'مشاهده مکالمه کامل',
    saveSuccess: 'بهینه‌سازی پرسش و پاسخ با موفقیت ذخیره شد',
  },
  optimized: {
    title: 'بهینه‌سازی شده',
    description: 'پرسش و پاسخ‌های بهینه‌سازی شده را ثبت می‌کند؛ می‌توانید محتوای پرسش و پاسخ را تنظیم کنید و تاریخچه استفاده از AI را در اینجا مشاهده کنید.',
    noData: {
      title: 'اینجا خالی است',
      description: 'می‌توانید لیست در انتظار یا سوابق مکالمه را بررسی کنید تا پاسخ‌های نامطلوب AI را بهینه‌سازی کنید.',
    },
    viewModal: {
      optimizedTitle: 'پرسش و پاسخ بهینه‌سازی شده',
      historyTitle: 'تاریخچه استفاده از AI',
    },
  },
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      userQuestion: 'سوال کاربر',
      aiAnswer: 'پاسخ AI',
      question: 'سوال',
      answer: 'پاسخ',
      createdAt: 'زمان ایجاد',
      hits: 'تعداد استفاده از AI',
      actions: 'اقدامات',
      time: 'زمان مکالمه',
      questionCount: 'تعداد سوالات',
    },
    pagination: {
      previous: 'صفحه قبلی',
      next: 'صفحه بعدی',
    },
  },
  confirm: {
    dismiss: 'این سوال را رد کنید',
    delete: 'این بهینه‌سازی را حذف کنید',
  },
  operation: {
    edit: 'این پاسخ را بهینه‌سازی کنید',
    reEdit: 'ویرایش محتوای بهینه‌سازی',
    save: 'ذخیره بهینه‌سازی',
  },
  editModal: {
    question: 'سوال',
    answer: 'پاسخ',
    queryName: 'سوال کاربر',
    answerName: 'پاسخ AI',
    yourAnswer: 'پاسخ بهینه‌سازی شده',
    answerPlaceholder: 'پاسخ مورد نظر AI را اینجا وارد کنید',
    yourQuery: 'سوال بهینه‌سازی شده',
    queryPlaceholder: 'سوال کاربر را به یک سوال عمومی‌تر و واضح‌تر بازنویسی کنید، که به AI کمک می‌کند سوالات مشابه را بهتر تطبیق دهد',
  },
  log: {
    optimizationLog: 'بهینه‌سازی اضافه شد',
    optimized: 'این پاسخ از محتوای بهینه‌سازی شده استفاده کرد',
  },
}

export default translation
