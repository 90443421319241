const translation = {
  currentPlan: 'Aktueller Tarif',
  upgradeBtn: {
    plain: 'Tarif Upgraden',
    encourage: 'Jetzt Upgraden',
    encourageShort: 'Upgraden',
  },
  viewBilling: 'Abrechnung und Abonnements verwalten',
  buyPermissionDeniedTip: 'Bitte kontaktieren Sie Ihren Unternehmensadministrator, um zu abonnieren',
  plansCommon: {
    title: 'Wählen Sie einen Tarif, der zu Ihnen passt',
    yearlyTip: 'Erhalten Sie 2 Monate kostenlos durch jährliches Abonnieren!',
    mostPopular: 'Am beliebtesten',
    planRange: {
      monthly: 'Monatlich',
      yearly: 'Jährlich',
    },
    month: 'Monat',
    year: 'Jahr',
    save: 'Sparen ',
    free: 'Kostenlos',
    currentPlan: 'Aktueller Tarif',
    contractSales: 'Vertrieb kontaktieren',
    contractOwner: 'Teammanager kontaktieren',
    startForFree: 'Kostenlos starten',
    getStartedWith: 'Beginnen Sie mit ',
    contactSales: 'Vertrieb kontaktieren',
    talkToSales: 'Mit dem Vertrieb sprechen',
    modelProviders: 'Modellanbieter',
    teamMembers: 'Teammitglieder',
    buildApps: 'Apps bauen',
    vectorSpace: 'Vektorraum',
    vectorSpaceBillingTooltip: 'Jedes 1MB kann ungefähr 1,2 Millionen Zeichen an vektorisierten Daten speichern (geschätzt mit OpenAI Embeddings, variiert je nach Modell).',
    vectorSpaceTooltip: 'Vektorraum ist das Langzeitspeichersystem, das erforderlich ist, damit LLMs Ihre Daten verstehen können.',
    documentsUploadQuota: 'Dokumenten-Upload-Kontingent',
    documentProcessingPriority: 'Priorität der Dokumentenverarbeitung',
    documentProcessingPriorityTip: 'Für eine höhere Dokumentenverarbeitungspriorität, bitte Ihren Tarif upgraden.',
    documentProcessingPriorityUpgrade: 'Mehr Daten mit höherer Genauigkeit bei schnelleren Geschwindigkeiten verarbeiten.',
    priority: {
      'standard': 'Standard',
      'priority': 'Priorität',
      'top-priority': 'Höchste Priorität',
    },
    logsHistory: 'Protokollverlauf',
    customTools: 'Benutzerdefinierte Werkzeuge',
    unavailable: 'Nicht verfügbar',
    days: 'Tage',
    unlimited: 'Unbegrenzt',
    support: 'Support',
    supportItems: {
      communityForums: 'Community-Foren',
      emailSupport: 'E-Mail-Support',
      priorityEmail: 'Priorisierter E-Mail- und Chat-Support',
      logoChange: 'Logo-Änderung',
      SSOAuthentication: 'SSO-Authentifizierung',
      personalizedSupport: 'Persönlicher Support',
      dedicatedAPISupport: 'Dedizierter API-Support',
      customIntegration: 'Benutzerdefinierte Integration und Support',
      ragAPIRequest: 'RAG-API-Anfragen',
      bulkUpload: 'Massenupload von Dokumenten',
      agentMode: 'Agentenmodus',
      workflow: 'Workflow',
    },
    comingSoon: 'Demnächst',
    member: 'Mitglied',
    memberAfter: 'Mitglied',
    messageRequest: {
      title: 'Nachrichtenguthaben',
      tooltip: 'Nachrichtenaufrufkontingente für verschiedene Tarife unter Verwendung von OpenAI-Modellen (außer gpt4).Nachrichten über dem Limit verwenden Ihren OpenAI-API-Schlüssel.',
    },
    annotatedResponse: {
      title: 'Kontingentgrenzen für Annotationen',
      tooltip: 'Manuelle Bearbeitung und Annotation von Antworten bieten anpassbare, hochwertige Frage-Antwort-Fähigkeiten für Apps. (Nur anwendbar in Chat-Apps)',
    },
    ragAPIRequestTooltip: 'Bezieht sich auf die Anzahl der API-Aufrufe, die nur die Wissensdatenbankverarbeitungsfähigkeiten von Dify aufrufen.',
    receiptInfo: 'Nur der Teaminhaber und der Teamadministrator können abonnieren und Abrechnungsinformationen einsehen',
  },
  plans: {
    sandbox: {
      name: 'Sandbox',
      description: '200 mal GPT kostenlos testen',
      includesTitle: 'Beinhaltet:',
    },
    professional: {
      name: 'Professionell',
      description: 'Für Einzelpersonen und kleine Teams, um mehr Leistung erschwinglich freizuschalten.',
      includesTitle: 'Alles im kostenlosen Tarif, plus:',
    },
    team: {
      name: 'Team',
      description: 'Zusammenarbeiten ohne Grenzen und Top-Leistung genießen.',
      includesTitle: 'Alles im Professionell-Tarif, plus:',
    },
    enterprise: {
      name: 'Unternehmen',
      description: 'Erhalten Sie volle Fähigkeiten und Unterstützung für großangelegte, missionskritische Systeme.',
      includesTitle: 'Alles im Team-Tarif, plus:',
    },
  },
  vectorSpace: {
    fullTip: 'Vektorraum ist voll.',
    fullSolution: 'Upgraden Sie Ihren Tarif, um mehr Speicherplatz zu erhalten.',
  },
  apps: {
    fullTipLine1: 'Upgraden Sie Ihren Tarif, um',
    fullTipLine2: 'mehr Apps zu bauen.',
  },
  annotatedResponse: {
    fullTipLine1: 'Upgraden Sie Ihren Tarif, um',
    fullTipLine2: 'mehr Konversationen zu annotieren.',
    quotaTitle: 'Kontingent für Annotation-Antworten',
  },
}

export default translation
