const translation = {
  common: {
    welcome: '',
    appUnavailable: 'Uygulama kullanılamıyor',
    appUnkonwError: 'Uygulama kullanılamıyor',
  },
  chat: {
    newChat: 'Yeni sohbet',
    pinnedTitle: 'Sabitlenmiş',
    unpinnedTitle: 'Sohbetler',
    newChatDefaultName: 'Yeni konuşma',
    resetChat: 'Konuşmayı sıfırla',
    powerBy: 'Tarafından desteklenmektedir',
    prompt: 'Prompt',
    privatePromptConfigTitle: 'Konuşma ayarları',
    publicPromptConfigTitle: 'Başlangıç Promptu',
    configStatusDes: 'Başlamadan önce, konuşma ayarlarını değiştirebilirsiniz',
    configDisabled: 'Bu oturum için önceki oturum ayarları kullanılmıştır.',
    startChat: 'Sohbete Başla',
    privacyPolicyLeft: 'Lütfen okuyun',
    privacyPolicyMiddle: 'gizlilik politikası',
    privacyPolicyRight: 'uygulama geliştiricisi tarafından sağlanmıştır.',
    deleteConversation: {
      title: 'Konuşmayı sil',
      content: 'Bu konuşmayı silmek istediğinize emin misiniz?',
    },
    tryToSolve: 'Çözmeyi Dene',
    temporarySystemIssue: 'Üzgünüz, geçici sistem sorunu.',
  },
  generation: {
    tabs: {
      create: 'Bir Kere Çalıştır',
      batch: 'Toplu Çalıştır',
      saved: 'Kaydedildi',
    },
    savedNoData: {
      title: 'Henüz bir sonuç kaydetmediniz!',
      description: 'İçerik oluşturmaya başlayın ve kaydedilen sonuçları burada bulun.',
      startCreateContent: 'İçerik oluşturmayı başlat',
    },
    title: 'AI Tamamlama',
    queryTitle: 'Sorgu içeriği',
    completionResult: 'Tamamlama sonucu',
    queryPlaceholder: 'Sorgu içeriğinizi yazın...',
    run: 'Çalıştır',
    copy: 'Kopyala',
    resultTitle: 'AI Tamamlama',
    noData: 'AI burada istediğinizi size verecek.',
    csvUploadTitle: 'CSV dosyanızı buraya sürükleyip bırakın ya da ',
    browse: 'göz atın',
    csvStructureTitle: 'CSV dosyası şu yapıya uygun olmalıdır:',
    downloadTemplate: 'Şablonu buradan indir',
    field: 'Alan',
    batchFailed: {
      info: '{{num}} başarısız işlemler',
      retry: 'Yeniden dene',
      outputPlaceholder: 'Çıktı içeriği yok',
    },
    errorMsg: {
      empty: 'Lütfen yüklenen dosyada içerik girin.',
      fileStructNotMatch: 'Yüklenen CSV dosyası yapıya uymuyor.',
      emptyLine: 'Satır {{rowIndex}} boş',
      invalidLine: 'Satır {{rowIndex}}: {{varName}} değeri boş olamaz',
      moreThanMaxLengthLine: 'Satır {{rowIndex}}: {{varName}} değeri {{maxLength}} karakterden fazla olamaz',
      atLeastOne: 'Lütfen yüklenen dosyada en az bir satır girin.',
    },
  },
}

export default translation
