const translation = {
  pageTitle: 'Hey, let\'s get started!👋',
  welcome: 'Welcome to Dify, please log in to continue.',
  email: 'Email address',
  emailPlaceholder: 'Your email',
  password: 'Password',
  passwordPlaceholder: 'Your password',
  name: 'Username',
  namePlaceholder: 'Your username',
  forget: 'Forgot your password?',
  signBtn: 'Sign in',
  sso: 'Continue with SSO',
  installBtn: 'Set up',
  setAdminAccount: 'Setting up an admin account',
  setAdminAccountDesc: 'Maximum privileges for admin account, which can be used to create applications and manage LLM providers, etc.',
  createAndSignIn: 'Create and sign in',
  oneMoreStep: 'One more step',
  createSample: 'Based on this information, we’ll create sample application for you',
  invitationCode: 'Invitation Code',
  invitationCodePlaceholder: 'Your invitation code',
  interfaceLanguage: 'Interface Language',
  timezone: 'Time zone',
  go: 'Go to Dify',
  sendUsMail: 'Email us your introduction, and we\'ll handle the invitation request.',
  acceptPP: 'I have read and accept the privacy policy',
  reset: 'Please run following command to reset your password',
  withGitHub: 'Continue with GitHub',
  withGoogle: 'Continue with Google',
  rightTitle: 'Unlock the full potential of LLM',
  rightDesc: 'Effortlessly build visually captivating, operable, and improvable AI applications.',
  tos: 'Terms of Service',
  pp: 'Privacy Policy',
  tosDesc: 'By signing up, you agree to our',
  goToInit: 'If you have not initialized the account, please go to the initialization page',
  donthave: 'Don\'t have?',
  invalidInvitationCode: 'Invalid invitation code',
  accountAlreadyInited: 'Account already initialized',
  error: {
    emailEmpty: 'Email address is required',
    emailInValid: 'Please enter a valid email address',
    nameEmpty: 'Name is required',
    passwordEmpty: 'Password is required',
    passwordLengthInValid: 'Password must be at least 8 characters',
    passwordInvalid: 'Password must contain letters and numbers, and the length must be greater than 8',
  },
  license: {
    tip: 'Before starting Dify Community Edition, read the GitHub',
    link: 'Open-source License',
  },
  join: 'Join',
  joinTipStart: 'Invite you join',
  joinTipEnd: 'team on Dify',
  invalid: 'The link has expired',
  explore: 'Explore Dify',
  activatedTipStart: 'You have joined the',
  activatedTipEnd: 'team',
  activated: 'Sign in now',
  adminInitPassword: 'Admin initialization password',
  validate: 'Validate',
}

export default translation
