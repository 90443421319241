const translation = {
  title: 'Annotation',
  name: 'Annotation Reply',
  editBy: 'Answer edited by {{author}}',
  noData: {
    title: 'No Annotations',
    description: 'You can edit annotations in the app session debugging or batch import annotations here for high-quality replies.',
  },
  table: {
    header: {
      question: 'Question',
      match: 'Match',
      response: 'Reply',
      answer: 'Answer',
      createdAt: 'Created At',
      hits: 'Hits',
      actions: 'Actions',
      addAnnotation: 'Add Annotation',
      bulkImport: 'Bulk Import',
      bulkExport: 'Bulk Export',
      clearAll: 'Delete All Annotations',
    },
  },
  editModal: {
    title: 'Edit Annotation Reply',
    queryName: 'Question',
    answerName: 'Reply',
    yourAnswer: 'Your Reply',
    answerPlaceholder: 'Enter your reply here',
    yourQuery: 'Your Question',
    queryPlaceholder: 'Enter your question here',
    removeThisCache: 'Delete this Annotation',
    createdAt: 'Created On',
  },
  addModal: {
    title: 'Add Annotation Reply',
    queryName: 'Question',
    answerName: 'Reply',
    answerPlaceholder: 'Enter reply',
    queryPlaceholder: 'Enter question',
    createNext: 'Add Next Annotation Reply',
  },
  batchModal: {
    title: 'Bulk Import',
    csvUploadTitle: 'Drag and drop your CSV file here, or',
    browse: 'Browse files',
    tip: 'The CSV file must adhere to the following structure:',
    question: 'Question',
    answer: 'Answer',
    contentTitle: 'Segment Content',
    content: 'Content',
    template: 'Download Template',
    cancel: 'Cancel',
    run: 'Import',
    runError: 'Batch Import Failed',
    processing: 'Batch Processing',
    completed: 'Import Completed',
    error: 'Import Error',
    ok: 'OK',
  },
  errorMessage: {
    answerRequired: 'Reply cannot be empty',
    queryRequired: 'Question cannot be empty',
  },
  viewModal: {
    annotatedResponse: 'Annotated Reply',
    hitHistory: 'Hit History',
    hit: 'Hit',
    hits: 'Hits',
    noHitHistory: 'No usage record',
  },
  hitHistoryTable: {
    question: 'Question',
    query: 'Query',
    match: 'Match',
    response: 'AI Reply',
    source: 'Source',
    score: 'Score',
    time: 'Time',
  },
  initSetup: {
    title: 'Initial Setup for Annotation Reply',
    configTitle: 'Annotation Reply Settings',
    confirmBtn: 'Save and Enable',
    configConfirmBtn: 'Save',
  },
  embeddingModelSwitchTip: 'Annotation text vectorization model. Switching models will re-embed, incurring additional costs.',
}

export default translation
