const translation = {
  pageTitle: {
    line1: 'INDICACIÓN',
    line2: 'Ingeniería',
  },
  orchestrate: 'Orquestar',
  promptMode: {
    simple: 'Cambia a Modo Experto para editar toda la INDICACIÓN',
    advanced: 'Modo Experto',
    switchBack: 'Volver',
    advancedWarning: {
      title: 'Has cambiado a Modo Experto, y una vez que modifiques la INDICACIÓN, NO PODRÁS regresar al modo básico.',
      description: 'En Modo Experto, puedes editar toda la INDICACIÓN.',
      learnMore: 'Aprender más',
      ok: 'OK',
    },
    operation: {
      addMessage: 'Agregar Mensaje',
    },
    contextMissing: 'Componente de contexto faltante, la efectividad de la indicación puede no ser buena.',
  },
  operation: {
    applyConfig: 'Publicar',
    resetConfig: 'Restablecer',
    debugConfig: 'Depurar',
    addFeature: 'Agregar Función',
    automatic: 'Automático',
    stopResponding: 'Dejar de responder',
    agree: 'Me gusta',
    disagree: 'No me gusta',
    cancelAgree: 'Cancelar Me gusta',
    cancelDisagree: 'Cancelar No me gusta',
    userAction: 'Usuario ',
    inputQuery: 'Escriba aquí...',
  },
  notSetAPIKey: {
    title: 'La clave del proveedor LLM no se ha establecido',
    trailFinished: 'Prueba terminada',
    description: 'La clave del proveedor LLM no se ha establecido, y debe configurarse antes de depurar.',
    settingBtn: 'Ir a configuración',
  },
  trailUseGPT4Info: {
    title: 'No se admite GPT-4 ahora',
    description: 'Para usar GPT-4, configure la clave API.',
  },
  feature: {
    groupChat: {
      title: 'Mejorar chat',
      description: 'Agregar configuraciones previas a la conversación en aplicaciones puede mejorar la experiencia del usuario.',
    },
    groupExperience: {
      title: 'Mejorar experiencia',
    },
    conversationOpener: {
      title: 'Iniciadores de conversación',
      description: 'En una aplicación de chat, la primera oración que la IA dice al usuario suele usarse como bienvenida.',
    },
    suggestedQuestionsAfterAnswer: {
      title: 'Seguimiento',
      description: 'Configurar sugerencias de próximas preguntas puede proporcionar una mejor conversación.',
      resDes: '3 sugerencias para la próxima pregunta del usuario.',
      tryToAsk: 'Intenta preguntar',
    },
    moreLikeThis: {
      title: 'Más como esto',
      description: 'Genera múltiples textos a la vez, luego edítalos y continúa generando',
      generateNumTip: 'Número de veces generado cada vez',
      tip: 'Usar esta función incurrirá en un costo adicional de tokens',
    },
    speechToText: {
      title: 'Voz a Texto',
      description: 'Una vez habilitado, puedes usar la entrada de voz.',
      resDes: 'Entrada de voz habilitada',
    },
    textToSpeech: {
      title: 'Texto a Voz',
      description: 'Una vez habilitado, el texto puede convertirse en voz.',
      resDes: 'Texto a Audio habilitado',
    },
    citation: {
      title: 'Citas y Atribuciones',
      description: 'Una vez habilitado, muestra el documento fuente y la sección atribuida del contenido generado.',
      resDes: 'Citas y Atribuciones habilitadas',
    },
    annotation: {
      title: 'Respuesta de Anotación',
      description: 'Puedes agregar manualmente una respuesta de alta calidad a la caché para una coincidencia prioritaria con preguntas similares de los usuarios.',
      resDes: 'Respuesta de Anotación habilitada',
      scoreThreshold: {
        title: 'Umbral de Puntuación',
        description: 'Usado para establecer el umbral de similitud para la respuesta de anotación.',
        easyMatch: 'Coincidencia Fácil',
        accurateMatch: 'Coincidencia Precisa',
      },
      matchVariable: {
        title: 'Variable de Coincidencia',
        choosePlaceholder: 'Elige la variable de coincidencia',
      },
      cacheManagement: 'Anotaciones',
      cached: 'Anotado',
      remove: 'Eliminar',
      removeConfirm: '¿Eliminar esta anotación?',
      add: 'Agregar anotación',
      edit: 'Editar anotación',
    },
    dataSet: {
      title: 'Contexto',
      noData: 'Puedes importar Conocimiento como contexto',
      words: 'Palabras',
      textBlocks: 'Bloques de Texto',
      selectTitle: 'Seleccionar Conocimiento de referencia',
      selected: 'Conocimiento seleccionado',
      noDataSet: 'No se encontró Conocimiento',
      toCreate: 'Ir a crear',
      notSupportSelectMulti: 'Actualmente solo se admite un Conocimiento',
      queryVariable: {
        title: 'Variable de Consulta',
        tip: 'Esta variable se utilizará como entrada de consulta para la recuperación de contexto, obteniendo información de contexto relacionada con la entrada de esta variable.',
        choosePlaceholder: 'Elige la variable de consulta',
        noVar: 'No hay variables',
        noVarTip: 'por favor, crea una variable en la sección Variables',
        unableToQueryDataSet: 'No se puede consultar el Conocimiento',
        unableToQueryDataSetTip: 'No se puede consultar el Conocimiento con éxito, por favor elige una variable de consulta de contexto en la sección de contexto.',
        ok: 'OK',
        contextVarNotEmpty: 'La variable de consulta de contexto no puede estar vacía',
        deleteContextVarTitle: '¿Eliminar variable "{{varName}}"?',
        deleteContextVarTip: 'Esta variable ha sido establecida como una variable de consulta de contexto, y eliminarla afectará el uso normal del Conocimiento. Si aún necesitas eliminarla, por favor vuelve a seleccionarla en la sección de contexto.',
      },
    },
    tools: {
      title: 'Herramientas',
      tips: 'Las herramientas proporcionan un método estándar de llamada API, tomando la entrada del usuario o variables como parámetros de solicitud para consultar datos externos como contexto.',
      toolsInUse: '{{count}} herramientas en uso',
      modal: {
        title: 'Herramienta',
        toolType: {
          title: 'Tipo de Herramienta',
          placeholder: 'Por favor selecciona el tipo de herramienta',
        },
        name: {
          title: 'Nombre',
          placeholder: 'Por favor ingresa el nombre',
        },
        variableName: {
          title: 'Nombre de la Variable',
          placeholder: 'Por favor ingresa el nombre de la variable',
        },
      },
    },
    conversationHistory: {
      title: 'Historial de Conversaciones',
      description: 'Establecer nombres de prefijo para los roles de conversación',
      tip: 'El Historial de Conversaciones no está habilitado, por favor agrega <histories> en la indicación arriba.',
      learnMore: 'Aprender más',
      editModal: {
        title: 'Editar Nombres de Roles de Conversación',
        userPrefix: 'Prefijo de Usuario',
        assistantPrefix: 'Prefijo de Asistente',
      },
    },
    toolbox: {
      title: 'CAJA DE HERRAMIENTAS',
    },
    moderation: {
      title: 'Moderación de contenido',
      description: 'Asegura la salida del modelo utilizando API de moderación o manteniendo una lista de palabras sensibles.',
      allEnabled: 'Contenido de ENTRADA/SALIDA Habilitado',
      inputEnabled: 'Contenido de ENTRADA Habilitado',
      outputEnabled: 'Contenido de SALIDA Habilitado',
      modal: {
        title: 'Configuración de moderación de contenido',
        provider: {
          title: 'Proveedor',
          openai: 'Moderación de OpenAI',
          openaiTip: {
            prefix: 'La Moderación de OpenAI requiere una clave API de OpenAI configurada en la ',
            suffix: '.',
          },
          keywords: 'Palabras clave',
        },
        keywords: {
          tip: 'Una por línea, separadas por saltos de línea. Hasta 100 caracteres por línea.',
          placeholder: 'Una por línea, separadas por saltos de línea',
          line: 'Línea',
        },
        content: {
          input: 'Moderar Contenido de ENTRADA',
          output: 'Moderar Contenido de SALIDA',
          preset: 'Respuestas predefinidas',
          placeholder: 'Contenido de respuestas predefinidas aquí',
          condition: 'Moderar Contenido de ENTRADA y SALIDA habilitado al menos uno',
          fromApi: 'Las respuestas predefinidas son devueltas por la API',
          errorMessage: 'Las respuestas predefinidas no pueden estar vacías',
          supportMarkdown: 'Markdown soportado',
        },
        openaiNotConfig: {
          before: 'La Moderación de OpenAI requiere una clave API de OpenAI configurada en la',
          after: '',
        },
      },
    },
  },
  automatic: {
    title: 'Orquestación automatizada de aplicaciones',
    description: 'Describe tu escenario, Dify orquestará una aplicación para ti.',
    intendedAudience: '¿Quién es el público objetivo?',
    intendedAudiencePlaceHolder: 'p.ej. Estudiante',
    solveProblem: '¿Qué problemas esperan que la IA pueda resolver para ellos?',
    solveProblemPlaceHolder: 'p.ej. Extraer ideas y resumir información de informes y artículos largos',
    generate: 'Generar',
    audiencesRequired: 'Audiencia requerida',
    problemRequired: 'Problema requerido',
    resTitle: 'Hemos orquestado la siguiente aplicación para ti.',
    apply: 'Aplicar esta orquestación',
    noData: 'Describe tu caso de uso a la izquierda, la vista previa de la orquestación se mostrará aquí.',
    loading: 'Orquestando la aplicación para ti...',
    overwriteTitle: '¿Sobrescribir configuración existente?',
    overwriteMessage: 'Aplicar esta orquestación sobrescribirá la configuración existente.',
  },
  resetConfig: {
    title: '¿Confirmar restablecimiento?',
    message: 'Restablecer descarta cambios, restaurando la última configuración publicada.',
  },
  errorMessage: {
    nameOfKeyRequired: 'nombre de la clave: {{key}} requerido',
    valueOfVarRequired: 'el valor de {{key}} no puede estar vacío',
    queryRequired: 'Se requiere texto de solicitud.',
    waitForResponse: 'Por favor espera la respuesta al mensaje anterior para completar.',
    waitForBatchResponse: 'Por favor espera la respuesta a la tarea por lotes para completar.',
    notSelectModel: 'Por favor elige un modelo',
    waitForImgUpload: 'Por favor espera a que la imagen se cargue',
    waitSubmitForm: 'Por favor, envíe el formulario primero.',
  },
  chatSubTitle: 'Instrucciones',
  completionSubTitle: 'Prefijo de la Indicación',
  promptTip: 'Las indicaciones guían las respuestas de la IA con instrucciones y restricciones. Inserta variables como {{input}}. Esta indicación no será visible para los usuarios.',
  formattingChangedTitle: 'Formato cambiado',
  formattingChangedText: 'Modificar el formato restablecerá el área de depuración, ¿estás seguro?',
  variableTitle: 'Variables',
  variableTip: 'Los usuarios completan las variables en un formulario, reemplazando automáticamente las variables en la indicación.',
  notSetVar: 'Las variables permiten a los usuarios introducir palabras de indicación u observaciones de apertura al completar formularios. Puedes intentar ingresar "{{input}}" en las palabras de indicación.',
  autoAddVar: 'Variables no definidas referenciadas en la pre-indicación, ¿quieres agregarlas en el formulario de entrada del usuario?',
  variableTable: {
    key: 'Clave de Variable',
    name: 'Nombre del Campo de Entrada del Usuario',
    optional: 'Opcional',
    type: 'Tipo de Entrada',
    action: 'Acciones',
    typeString: 'Cadena',
    typeSelect: 'Seleccionar',
  },
  varKeyError: {
    canNoBeEmpty: 'La clave de la variable no puede estar vacía',
    tooLong: 'Clave de la variable: {{key}} demasiado larga. No puede tener más de 30 caracteres',
    notValid: 'Clave de la variable: {{key}} no es válida. Solo puede contener letras, números y guiones bajos',
    notStartWithNumber: 'Clave de la variable: {{key}} no puede comenzar con un número',
    keyAlreadyExists: 'Clave de la variable: {{key}} ya existe',
  },
  otherError: {
    promptNoBeEmpty: 'La indicación no puede estar vacía',
    historyNoBeEmpty: 'El historial de conversaciones debe establecerse en la indicación',
    queryNoBeEmpty: 'La consulta debe establecerse en la indicación',
  },
  variableConig: {
    'addModalTitle': 'Agregar Campo de Entrada',
    'editModalTitle': 'Editar Campo de Entrada',
    'description': 'Configuración para la variable {{varName}}',
    'fieldType': 'Tipo de campo',
    'string': 'Texto corto',
    'text-input': 'Texto corto',
    'paragraph': 'Párrafo',
    'select': 'Seleccionar',
    'number': 'Número',
    'notSet': 'No configurado, intenta escribir {{input}} en la indicación de prefijo',
    'stringTitle': 'Opciones de cuadro de texto de formulario',
    'maxLength': 'Longitud máxima',
    'options': 'Opciones',
    'addOption': 'Agregar opción',
    'apiBasedVar': 'Variable basada en API',
    'varName': 'Nombre de la Variable',
    'labelName': 'Nombre de la Etiqueta',
    'inputPlaceholder': 'Por favor ingresa',
    'content': 'Contenido',
    'required': 'Requerido',
    'errorMsg': {
      varNameRequired: 'Nombre de la variable es requerido',
      labelNameRequired: 'Nombre de la etiqueta es requerido',
      varNameCanBeRepeat: 'El nombre de la variable no puede repetirse',
      atLeastOneOption: 'Se requiere al menos una opción',
      optionRepeat: 'Hay opciones repetidas',
    },
  },
  vision: {
    name: 'Visión',
    description: 'Habilitar Visión permitirá al modelo recibir imágenes y responder preguntas sobre ellas.',
    settings: 'Configuraciones',
    visionSettings: {
      title: 'Configuraciones de Visión',
      resolution: 'Resolución',
      resolutionTooltip: `Baja resolución permitirá que el modelo reciba una versión de baja resolución de 512 x 512 de la imagen, y represente la imagen con un presupuesto de 65 tokens. Esto permite que la API devuelva respuestas más rápidas y consuma menos tokens de entrada para casos de uso que no requieren alta detalle.
      \n
      Alta resolución permitirá primero que el modelo vea la imagen de baja resolución y luego crea recortes detallados de las imágenes de entrada como cuadrados de 512px basados en el tamaño de la imagen de entrada. Cada uno de los recortes detallados usa el doble del presupuesto de tokens para un total de 129 tokens.`,
      high: 'Alta',
      low: 'Baja',
      uploadMethod: 'Método de carga',
      both: 'Ambos',
      localUpload: 'Carga Local',
      url: 'URL',
      uploadLimit: 'Límite de carga',
    },
  },
  voice: {
    name: 'Voz',
    defaultDisplay: 'Voz Predeterminada',
    description: 'Configuraciones de voz a texto',
    settings: 'Configuraciones',
    voiceSettings: {
      title: 'Configuraciones de Voz',
      language: 'Idioma',
      resolutionTooltip: 'Soporte de idioma para voz a texto.',
      voice: 'Voz',
      autoPlay: 'Auto-reproducción',
      autoPlayEnabled: 'Abierto',
      autoPlayDisabled: 'Cierre',
    },
  },
  openingStatement: {
    title: 'Apertura de Conversación',
    add: 'Agregar',
    writeOpener: 'Escribir apertura',
    placeholder: 'Escribe tu mensaje de apertura aquí, puedes usar variables, intenta escribir {{variable}}.',
    openingQuestion: 'Preguntas de Apertura',
    noDataPlaceHolder: 'Después de iniciar la conversación, la primera frase dicha por la IA puede ayudar a reducir la distancia entre el usuario y la IA.',
    varTip: 'Puedes usar variables, intenta escribir {{variable}}',
    tooShort: 'Se requieren al menos 20 palabras en la indicación inicial para generar una apertura de conversación.',
    notIncludeKey: 'La indicación inicial no incluye la variable: {{key}}. Por favor agrégala a la indicación inicial.',
  },
  modelConfig: {
    model: 'Modelo',
    setTone: 'Establecer tono de respuestas',
    title: 'Modelo y Parámetros',
    modeType: {
      chat: 'Chat',
      completion: 'Completar',
    },
  },
  inputs: {
    title: 'Depurar y Previsualizar',
    noPrompt: 'Intenta escribir alguna indicación en la entrada de pre-indicación',
    userInputField: 'Campo de Entrada del Usuario',
    noVar: 'Completa el valor de la variable, que se reemplazará automáticamente en la palabra de indicación cada vez que se inicie una nueva sesión.',
    chatVarTip: 'Completa el valor de la variable, que se reemplazará automáticamente en la palabra de indicación cada vez que se inicie una nueva sesión',
    completionVarTip: 'Completa el valor de la variable, que se reemplazará automáticamente en las palabras de indicación cada vez que se envíe una pregunta.',
    previewTitle: 'Vista previa de la indicación',
    queryTitle: 'Contenido de la consulta',
    queryPlaceholder: 'Por favor ingresa el texto de la solicitud.',
    run: 'EJECUTAR',
  },
  result: 'Texto de salida',
  datasetConfig: {
    settingTitle: 'Configuraciones de Recuperación',
    knowledgeTip: 'Haz clic en el botón “+” para agregar conocimiento',
    retrieveOneWay: {
      title: 'Recuperación N-a-1',
      description: 'Basado en la intención del usuario y las descripciones de Conocimiento, el Agente selecciona autónomamente el mejor Conocimiento para consultar. Ideal para aplicaciones con Conocimiento limitado y distintivo.',
    },
    retrieveMultiWay: {
      title: 'Recuperación Multi-camino',
      description: 'Basado en la intención del usuario, consulta a través de todo el Conocimiento, recupera texto relevante de múltiples fuentes y selecciona los mejores resultados que coinciden con la consulta del usuario después de reordenar. Se requiere configuración de la API del modelo de Reordenar.',
    },
    rerankModelRequired: 'Se requiere modelo de Reordenar',
    params: 'Parámetros',
    top_k: 'Top K',
    top_kTip: 'Usado para filtrar fragmentos que son más similares a las preguntas del usuario. El sistema también ajustará dinámicamente el valor de Top K, de acuerdo con los max_tokens del modelo seleccionado.',
    score_threshold: 'Umbral de Puntuación',
    score_thresholdTip: 'Usado para establecer el umbral de similitud para la filtración de fragmentos.',
    retrieveChangeTip: 'Modificar el modo de índice y el modo de recuperación puede afectar las aplicaciones asociadas con este Conocimiento.',
  },
  debugAsSingleModel: 'Depurar como Modelo Único',
  debugAsMultipleModel: 'Depurar como Múltiples Modelos',
  duplicateModel: 'Duplicar',
  publishAs: 'Publicar como',
  assistantType: {
    name: 'Tipo de Asistente',
    chatAssistant: {
      name: 'Asistente Básico',
      description: 'Construye un asistente basado en chat usando un Modelo de Lenguaje Grande',
    },
    agentAssistant: {
      name: 'Asistente Agente',
      description: 'Construye un Agente inteligente que puede elegir herramientas autónomamente para completar tareas',
    },
  },
  agent: {
    agentMode: 'Modo Agente',
    agentModeDes: 'Establecer el tipo de modo de inferencia para el agente',
    agentModeType: {
      ReACT: 'ReAct',
      functionCall: 'Llamada de Función',
    },
    setting: {
      name: 'Configuraciones del Agente',
      description: 'Las configuraciones del Asistente Agente permiten establecer el modo del agente y funciones avanzadas como indicaciones integradas, disponibles solo en el tipo Agente.',
      maximumIterations: {
        name: 'Iteraciones Máximas',
        description: 'Limitar el número de iteraciones que un asistente agente puede ejecutar',
      },
    },
    buildInPrompt: 'Indicación Integrada',
    firstPrompt: 'Primera Indicación',
    nextIteration: 'Próxima Iteración',
    promptPlaceholder: 'Escribe tu indicación aquí',
    tools: {
      name: 'Herramientas',
      description: 'El uso de herramientas puede extender las capacidades del LLM, como buscar en internet o realizar cálculos científicos',
      enabled: 'Habilitado',
    },
  },
}

export default translation
