const translation = {
  pageTitle: 'Oi, vamos começar!👋',
  welcome: 'Bem-vindo ao Dify, faça login para continuar.',
  email: 'Endereço de e-mail',
  emailPlaceholder: 'Seu e-mail',
  password: 'Senha',
  passwordPlaceholder: 'Sua senha',
  name: 'Nome de usuário',
  namePlaceholder: 'Seu nome de usuário',
  forget: 'Esqueceu sua senha?',
  signBtn: 'Entrar',
  installBtn: 'Configuração',
  setAdminAccount: 'Configurando uma conta de administrador',
  setAdminAccountDesc: 'Privilégios máximos para a conta de administrador, que pode ser usada para criar aplicativos e gerenciar provedores LLM, etc.',
  createAndSignIn: 'Criar e entrar',
  oneMoreStep: 'Mais um passo',
  createSample: 'Com base nessas informações, criaremos um aplicativo de exemplo para você',
  invitationCode: 'Código de convite',
  invitationCodePlaceholder: 'Seu código de convite',
  interfaceLanguage: 'Idioma da interface',
  timezone: 'Fuso horário',
  go: 'Ir para o Dify',
  sendUsMail: 'Envie-nos um e-mail com sua introdução e cuidaremos do pedido de convite.',
  acceptPP: 'Li e aceito a política de privacidade',
  reset: 'Execute o seguinte comando para redefinir sua senha',
  withGitHub: 'Continuar com o GitHub',
  withGoogle: 'Continuar com o Google',
  rightTitle: 'Desbloqueie todo o potencial do LLM',
  rightDesc: 'Crie aplicativos de IA visualmente cativantes, operáveis e aprimoráveis sem esforço.',
  tos: 'Termos de Serviço',
  pp: 'Política de Privacidade',
  tosDesc: 'Ao se inscrever, você concorda com nossos',
  goToInit: 'Se você não inicializou a conta, vá para a página de inicialização',
  donthave: 'Não tem?',
  invalidInvitationCode: 'Código de convite inválido',
  accountAlreadyInited: 'Conta já iniciada',
  forgotPassword: 'Esqueceu sua senha?',
  resetLinkSent: 'Link de redefinição enviado',
  sendResetLink: 'Enviar link de redefinição',
  backToSignIn: 'Voltar para login',
  forgotPasswordDesc: 'Por favor, insira seu endereço de e-mail para redefinir sua senha. Enviaremos um e-mail com instruções sobre como redefinir sua senha.',
  checkEmailForResetLink: 'Verifique seu e-mail para um link para redefinir sua senha. Se não aparecer dentro de alguns minutos, verifique sua pasta de spam.',
  passwordChanged: 'Entre agora',
  changePassword: 'Mudar a senha',
  changePasswordTip: 'Por favor, insira uma nova senha para sua conta',
  invalidToken: 'Token inválido ou expirado',
  confirmPassword: 'Confirme a Senha',
  confirmPasswordPlaceholder: 'Confirme sua nova senha',
  passwordChangedTip: 'Sua senha foi alterada com sucesso',
  error: {
    emailEmpty: 'O endereço de e-mail é obrigatório',
    emailInValid: 'Digite um endereço de e-mail válido',
    nameEmpty: 'O nome é obrigatório',
    passwordEmpty: 'A senha é obrigatória',
    passwordInvalid: 'A senha deve conter letras e números e ter um comprimento maior que 8',
  },
  license: {
    tip: 'Antes de começar a usar a Edição Comunitária do Dify, leia a',
    link: 'Licença de código aberto do GitHub',
  },
  join: 'Participar',
  joinTipStart: 'Convidamos você a participar da',
  joinTipEnd: 'equipe no Dify',
  invalid: 'O link expirou',
  explore: 'Explorar o Dify',
  activatedTipStart: 'Você se juntou à equipe',
  activatedTipEnd: '',
  activated: 'Entrar agora',
}

export default translation
