const translation = {
  pageTitle: 'はじめましょう！👋',
  welcome: 'Difyへようこそ。続行するにはログインしてください。',
  email: 'メールアドレス',
  emailPlaceholder: 'メールアドレスを入力してください',
  password: 'パスワード',
  passwordPlaceholder: 'パスワードを入力してください',
  name: 'ユーザー名',
  namePlaceholder: 'ユーザー名を入力してください',
  forget: 'パスワードをお忘れですか？',
  signBtn: 'サインイン',
  sso: 'SSOに続ける',
  installBtn: 'セットアップ',
  setAdminAccount: '管理者アカウントの設定',
  setAdminAccountDesc: 'アプリケーションの作成やLLMプロバイダの管理など、管理者アカウントの最大権限を設定します。',
  createAndSignIn: '作成してサインイン',
  oneMoreStep: 'あと一歩',
  createSample: 'この情報を基に、サンプルアプリケーションを作成します',
  invitationCode: '招待コード',
  invitationCodePlaceholder: '招待コードを入力してください',
  interfaceLanguage: 'インターフェース言語',
  timezone: 'タイムゾーン',
  go: 'Difyへ移動',
  sendUsMail: '自己紹介をメールで送信し、招待リクエストを処理します。',
  acceptPP: 'プライバシーポリシーを読み、同意します',
  reset: 'パスワードをリセットするには、次のコマンドを実行してください',
  withGitHub: 'GitHubで続行',
  withGoogle: 'Googleで続行',
  rightTitle: 'LLMのフルポテンシャルを解き放つ',
  rightDesc: '魅力的で操作可能で改善可能なAIアプリケーションを簡単に構築します。',
  tos: '利用規約',
  pp: 'プライバシーポリシー',
  tosDesc: 'サインアップすることで、以下に同意するものとします',
  goToInit: 'アカウントを初期化していない場合は、初期化ページに移動してください',
  donthave: 'お持ちでない場合',
  invalidInvitationCode: '無効な招待コード',
  accountAlreadyInited: 'アカウントは既に初期化されています',
  forgotPassword: 'パスワードを忘れましたか？',
  resetLinkSent: 'リセットリンクが送信されました',
  sendResetLink: 'リセットリンクを送信',
  backToSignIn: 'サインインに戻る',
  forgotPasswordDesc: 'パスワードをリセットするためにメールアドレスを入力してください。パスワードのリセット方法に関する指示が記載されたメールを送信します。',
  checkEmailForResetLink: 'パスワードリセットリンクを確認するためにメールを確認してください。数分以内に表示されない場合は、スパムフォルダーを確認してください。',
  passwordChanged: '今すぐサインイン',
  changePassword: 'パスワードを変更する',
  changePasswordTip: 'アカウントの新しいパスワードを入力してください',
  invalidToken: '無効または期限切れのトークン',
  confirmPassword: 'パスワードを確認',
  confirmPasswordPlaceholder: '新しいパスワードを確認してください',
  passwordChangedTip: 'パスワードが正常に変更されました',
  error: {
    emailEmpty: 'メールアドレスは必須です',
    emailInValid: '有効なメールアドレスを入力してください',
    nameEmpty: '名前は必須です',
    passwordEmpty: 'パスワードは必須です',
    passwordLengthInValid: 'パスワードは8文字以上でなければなりません',
    passwordInvalid: 'パスワードは文字と数字を含み、長さは8以上である必要があります',
  },
  license: {
    tip: 'Dify Community Editionを開始する前に、GitHubの',
    link: 'オープンソースライセンス',
  },
  join: '参加する',
  joinTipStart: 'あなた様を招待します',
  joinTipEnd: 'チームに参加する',
  invalid: 'リンクの有効期限が切れています',
  explore: 'Difyを探索する',
  activatedTipStart: 'あなた様は',
  activatedTipEnd: 'チームに参加しました',
  activated: '今すぐサインイン',
  adminInitPassword: '管理者初期化パスワード',
  validate: '検証',
}

export default translation
