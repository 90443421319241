const translation = {
  pageTitle: 'Ehi, iniziamo!👋',
  welcome: 'Benvenuto su x-elephant, per favore accedi per continuare.',
  email: 'Indirizzo email',
  emailPlaceholder: 'La tua email',
  password: 'Password',
  passwordPlaceholder: 'La tua password',
  name: 'Nome utente',
  namePlaceholder: 'Il tuo nome utente',
  forget: 'Hai dimenticato la password?',
  signBtn: 'Accedi',
  sso: 'Continua con SSO',
  installBtn: 'Configura',
  setAdminAccount: 'Impostazione di un account amministratore',
  setAdminAccountDesc:
    'Privilegi massimi per l\'account amministratore, che può essere utilizzato per creare applicazioni e gestire i fornitori di LLM, ecc.',
  createAndSignIn: 'Crea e accedi',
  oneMoreStep: 'Un altro passo',
  createSample:
    'In base a queste informazioni, creeremo un\'applicazione di esempio per te',
  invitationCode: 'Codice di invito',
  invitationCodePlaceholder: 'Il tuo codice di invito',
  interfaceLanguage: 'Lingua dell\'interfaccia',
  timezone: 'Fuso orario',
  go: 'Vai a Dify',
  sendUsMail:
    'Inviaci una email con la tua presentazione e gestiremo la richiesta di invito.',
  acceptPP: 'Ho letto e accetto l\'informativa sulla privacy',
  reset:
    'Per favore esegui il seguente comando per reimpostare la tua password',
  withGitHub: 'Continua con GitHub',
  withGoogle: 'Continua con Google',
  rightTitle: 'Sblocca tutto il potenziale di LLM',
  rightDesc:
    'Costruisci senza sforzo applicazioni AI visivamente accattivanti, operabili e migliorabili.',
  tos: 'Termini di servizio',
  pp: 'Informativa sulla privacy',
  tosDesc: 'Iscrivendoti, accetti i nostri',
  goToInit:
    'Se non hai inizializzato l\'account, vai alla pagina di inizializzazione',
  donthave: 'Non hai?',
  invalidInvitationCode: 'Codice di invito non valido',
  accountAlreadyInited: 'Account già inizializzato',
  forgotPassword: 'Hai dimenticato la password?',
  resetLinkSent: 'Link per il reset inviato',
  sendResetLink: 'Invia link per il reset',
  backToSignIn: 'Torna al login',
  forgotPasswordDesc:
    'Per favore inserisci il tuo indirizzo email per reimpostare la tua password. Ti invieremo una email con le istruzioni su come reimpostare la tua password.',
  checkEmailForResetLink:
    'Per favore controlla la tua email per un link per reimpostare la password. Se non compare entro pochi minuti, assicurati di controllare la cartella spam.',
  passwordChanged: 'Accedi ora',
  changePassword: 'Cambia Password',
  changePasswordTip:
    'Per favore inserisci una nuova password per il tuo account',
  invalidToken: 'Token non valido o scaduto',
  confirmPassword: 'Conferma Password',
  confirmPasswordPlaceholder: 'Conferma la tua nuova password',
  passwordChangedTip: 'La tua password è stata cambiata con successo',
  error: {
    emailEmpty: 'L\'indirizzo email è obbligatorio',
    emailInValid: 'Per favore inserisci un indirizzo email valido',
    nameEmpty: 'Il nome è obbligatorio',
    passwordEmpty: 'La password è obbligatoria',
    passwordLengthInValid: 'La password deve essere di almeno 8 caratteri',
    passwordInvalid:
      'La password deve contenere lettere e numeri, e la lunghezza deve essere maggiore di 8',
  },
  license: {
    tip: 'Prima di avviare Dify Community Edition, leggi su GitHub',
    link: 'Licenza open-source',
  },
  join: 'Unisciti',
  joinTipStart: 'Invitato a unirti al',
  joinTipEnd: 'team su Dify',
  invalid: 'Il link è scaduto',
  explore: 'Esplora Dify',
  activatedTipStart: 'Sei entrato nel team',
  activatedTipEnd: '',
  activated: 'Accedi ora',
  adminInitPassword: 'Password di inizializzazione amministratore',
  validate: 'Convalida',
}

export default translation
