const translation = {
  steps: {
    header: {
      creation: 'ایجاد دانش',
      update: 'افزودن داده',
    },
    one: 'انتخاب منبع داده',
    two: 'پیشپردازش و پاکسازی متن',
    three: 'اجرا و پایان',
  },
  error: {
    unavailable: 'این دانش در دسترس نیست',
  },
  firecrawl: {
    configFirecrawl: 'پیکربندی fireFirecrawl',
    apiKeyPlaceholder: 'کلید API از firecrawl.dev',
    getApiKeyLinkText: 'کلید API خود را از firecrawl.dev دریافت کنید',
  },
  stepOne: {
    filePreview: 'پیشنمایش فایل',
    pagePreview: 'پیشنمایش صفحه',
    dataSourceType: {
      file: 'وارد کردن از فایل',
      notion: 'همگامسازی از Notion',
      web: 'همگامسازی از وبسایت',
    },
    uploader: {
      title: 'بارگذاری فایل',
      button: 'کشیدن و رها کردن فایل، یا',
      browse: 'مرور',
      tip: 'پشتیبانی از {{supportTypes}}. حداکثر {{size}}MB هر کدام.',
      validation: {
        typeError: 'نوع فایل پشتیبانی نمیشود',
        size: 'فایل خیلی بزرگ است. حداکثر {{size}}MB',
        count: 'چندین فایل پشتیبانی نمیشود',
        filesNumber: 'شما به حد مجاز بارگذاری دستهای {{filesNumber}} رسیدهاید.',
      },
      cancel: 'لغو',
      change: 'تغییر',
      failed: 'بارگذاری ناموفق بود',
    },
    notionSyncTitle: 'Notion متصل نیست',
    notionSyncTip: 'برای همگامسازی با Notion، ابتدا باید اتصال به Notion برقرار شود.',
    connect: 'رفتن به اتصال',
    button: 'بعدی',
    emptyDatasetCreation: 'میخواهم یک دانش خالی ایجاد کنم',
    modal: {
      title: 'ایجاد یک دانش خالی',
      tip: 'یک دانش خالی هیچ سندی نخواهد داشت و شما میتوانید هر زمان اسناد را بارگذاری کنید.',
      input: 'نام دانش',
      placeholder: 'لطفاً وارد کنید',
      nameNotEmpty: 'نام نمیتواند خالی باشد',
      nameLengthInvaild: 'نام باید بین 1 تا 40 کاراکتر باشد',
      cancelButton: 'لغو',
      confirmButton: 'ایجاد',
      failed: 'ایجاد ناموفق بود',
    },
    website: {
      fireCrawlNotConfigured: 'Firecrawl پیکربندی نشده است',
      fireCrawlNotConfiguredDescription: 'برای استفاده از Firecrawl با کلید API پیکربندی کنید.',
      configure: 'پیکربندی',
      run: 'اجرا',
      firecrawlTitle: 'استخراج محتوای وب با fireFirecrawl',
      firecrawlDoc: 'مستندات Firecrawl',
      firecrawlDocLink: '<a href="https://docs.dify.ai/guides/knowledge-base/sync-from-website">https://docs.dify.ai/guides/knowledge-base/sync-from-website</a>',
      options: 'گزینهها',
      crawlSubPage: 'خزش صفحات فرعی',
      limit: 'محدودیت',
      maxDepth: 'حداکثر عمق',
      excludePaths: 'مسیرهای مستثنی',
      includeOnlyPaths: 'فقط مسیرهای شامل',
      extractOnlyMainContent: 'فقط محتوای اصلی را استخراج کنید (بدون هدرها، ناوبریها، پاورقیها و غیره)',
      exceptionErrorTitle: 'یک استثنا در حین اجرای کار Firecrawl رخ داد:',
      unknownError: 'خطای ناشناخته',
      totalPageScraped: 'کل صفحات خراشیده شده:',
      selectAll: 'انتخاب همه',
      resetAll: 'بازنشانی همه',
      scrapTimeInfo: 'در مجموع {{total}} صفحه در {{time}} ثانیه خراشیده شد',
      preview: 'پیشنمایش',
      maxDepthTooltip: 'حداکثر عمق برای خزش نسبت به URL وارد شده. عمق 0 فقط صفحه URL وارد شده را خراش میدهد، عمق 1 URL و همه چیز بعد از URL وارد شده + یک / را خراش میدهد، و غیره.',
    },
  },
  stepTwo: {
    segmentation: 'تنظیمات بخشبندی',
    auto: 'خودکار',
    autoDescription: 'به طور خودکار قوانین بخشبندی و پیشپردازش را تنظیم کنید. به کاربران ناآشنا توصیه میشود این گزینه را انتخاب کنند.',
    custom: 'سفارشی',
    customDescription: 'قوانین بخشبندی، طول بخشها و قوانین پیشپردازش را سفارشی کنید، و غیره.',
    separator: 'شناسه بخش',
    separatorPlaceholder: 'برای مثال، خط جدید (\\\\n) یا جداکننده خاص (مانند "***")',
    maxLength: 'حداکثر طول بخش',
    overlap: 'همپوشانی بخش',
    overlapTip: 'تنظیم همپوشانی بخش میتواند ارتباط معنایی بین آنها را حفظ کند و اثر بازیابی را افزایش دهد. توصیه میشود 10%-25% از حداکثر اندازه بخش تنظیم شود.',
    overlapCheck: 'همپوشانی بخش نباید بزرگتر از طول حداکثر بخش باشد',
    rules: 'قوانین پیشپردازش متن',
    removeExtraSpaces: 'جایگزینی فضاهای متوالی، خطوط جدید و تبها',
    removeUrlEmails: 'حذف همه URLها و آدرسهای ایمیل',
    removeStopwords: 'حذف کلمات توقف مانند "a"، "an"، "the"',
    preview: 'تأیید و پیشنمایش',
    reset: 'بازنشانی',
    indexMode: 'حالت شاخص',
    qualified: 'کیفیت بالا',
    recommend: 'توصیه شده',
    qualifiedTip: 'رابط جاسازی سیستم پیشفرض را برای پردازش فراخوانی کنید تا دقت بالاتری هنگام پرسش کاربران فراهم شود.',
    warning: 'لطفاً ابتدا کلید API ارائهدهنده مدل را تنظیم کنید.',
    click: 'رفتن به تنظیمات',
    economical: 'اقتصادی',
    economicalTip: 'از موتورهای برداری آفلاین، شاخصهای کلیدواژه و غیره استفاده کنید تا دقت را بدون صرف توکنها کاهش دهید',
    QATitle: 'بخشبندی در قالب پرسش و پاسخ',
    QATip: 'فعال کردن این گزینه توکنهای بیشتری مصرف خواهد کرد',
    QALanguage: 'بخشبندی با استفاده از',
    emstimateCost: 'برآورد',
    emstimateSegment: 'بخشهای برآورد شده',
    segmentCount: 'بخشها',
    calculating: 'در حال محاسبه...',
    fileSource: 'پیشپردازش اسناد',
    notionSource: 'پیشپردازش صفحات',
    websiteSource: 'پیشپردازش وبسایت',
    other: 'و سایر',
    fileUnit: ' فایلها',
    notionUnit: ' صفحات',
    webpageUnit: ' صفحات',
    previousStep: 'مرحله قبلی',
    nextStep: 'ذخیره و پردازش',
    save: 'ذخیره و پردازش',
    cancel: 'لغو',
    sideTipTitle: 'چرا بخشبندی و پیشپردازش؟',
    sideTipP1: 'هنگام پردازش دادههای متنی، بخشبندی و پاکسازی دو مرحله مهم پیشپردازش هستند.',
    sideTipP2: 'بخشبندی متن طولانی را به پاراگرافها تقسیم میکند تا مدلها بهتر بتوانند آن را درک کنند. این کیفیت و ارتباط نتایج مدل را بهبود میبخشد.',
    sideTipP3: 'پاکسازی کاراکترها و فرمتهای غیرضروری را حذف میکند و دانش را پاکتر و آسانتر برای تجزیه میکند.',
    sideTipP4: 'بخشبندی و پاکسازی مناسب عملکرد مدل را بهبود میبخشد و نتایج دقیقتر و ارزشمندتری ارائه میدهد.',
    previewTitle: 'پیشنمایش',
    previewTitleButton: 'پیشنمایش',
    previewButton: 'تغییر به قالب پرسش و پاسخ',
    previewSwitchTipStart: 'پیشنمایش بخش فعلی در قالب متن است، تغییر به پیشنمایش قالب پرسش و پاسخ',
    previewSwitchTipEnd: ' توکنهای اضافی مصرف خواهد کرد',
    characters: 'کاراکترها',
    indexSettedTip: 'برای تغییر روش شاخص، لطفاً به',
    retrivalSettedTip: 'برای تغییر روش شاخص، لطفاً به',
    datasetSettingLink: 'تنظیمات دانش بروید.',
  },
  stepThree: {
    creationTitle: ' دانش ایجاد شد',
    creationContent: 'ما به طور خودکار نام دانش را تعیین کردیم، شما میتوانید هر زمان آن را تغییر دهید',
    label: 'نام دانش',
    additionTitle: ' سند بارگذاری شد',
    additionP1: 'سند به دانش بارگذاری شده است',
    additionP2: '، میتوانید آن را در لیست اسناد دانش پیدا کنید.',
    stop: 'توقف پردازش',
    resume: 'ادامه پردازش',
    navTo: 'رفتن به سند',
    sideTipTitle: 'بعدی چیست',
    sideTipContent: 'پس از اتمام فهرستبندی سند، دانش میتواند به عنوان زمینه در برنامه یکپارچه شود، میتوانید تنظیمات زمینه را در صفحه ارکستراسیون درخواست پیدا کنید. همچنین میتوانید آن را به عنوان یک افزونه فهرستبندی مستقل ChatGPT برای انتشار ایجاد کنید.',
    modelTitle: 'آیا مطمئن هستید که میخواهید جاسازی را متوقف کنید؟',
    modelContent: 'اگر نیاز به ادامه پردازش بعداً دارید، از جایی که متوقف شدهاید ادامه خواهید داد.',
    modelButtonConfirm: 'تأیید',
    modelButtonCancel: 'لغو',
  },
}

export default translation
