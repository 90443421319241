const translation = {
  knowledgeSupplement: {
    title: '缺少知识',
    description: '总结用户缺少知识的问题，每周一更新。',
    noData: {
      title: '这里是空的',
      description: '暂时没有待补充的知识，可在对话记录中持续关注AI回复的表现哦。',
    },
    saveSuccess: '知识补充保存成功',
    valid: {
      wordLimit: '回复内容最多2000字符，请精简内容后再保存',
    },
  },
  pending: {
    title: '用户差评',
    description: '记录了用户反馈不满意的AI回复。',
    noData: {
      title: '这里是空的',
      description: '暂时没有用户差评，可在对话记录中持续关注AI回复的表现哦。',
    },
    checkConversation: '查看完整对话',
    saveSuccess: '问答优化保存成功',
  },
  optimized: {
    title: '已优化',
    description: '记录了已优化过的问答，可在此调整问答内容、查看AI使用记录。',
    noData: {
      title: '这里是空的',
      description: '可以去待优化列表或对话记录看看，对不满意的AI回复进行优化。',
    },
    viewModal: {
      optimizedTitle: '已优化问答',
      historyTitle: 'AI使用记录',
    },
  },
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      userQuestion: '用户提问',
      aiAnswer: 'AI 回复',
      question: '问题',
      answer: '回复',
      createdAt: '创建时间',
      hits: 'AI 使用次数',
      actions: '操作',
      time: '对话时间',
      questionCount: '提问次数',
    },
    pagination: {
      previous: '上一页',
      next: '下一页',
    },
  },
  confirm: {
    dismiss: '忽略此问题',
    delete: '删除此优化',
  },
  operation: {
    edit: '优化此回复',
    reEdit: '编辑优化内容',
    save: '保存优化',
  },
  editModal: {
    question: '问题',
    answer: '回复',
    queryName: '用户提问',
    answerName: 'AI 回复',
    yourAnswer: '优化后回复',
    answerPlaceholder: '在这里输入预期的AI回复',
    yourQuery: '优化后的问题',
    queryPlaceholder: '将用户提问表达为更通用、更清晰的问题，有助于AI匹配类似问题',
  },
}

export default translation
