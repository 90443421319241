const translation = {
  confirm: {
    application: {
      title: 'AI Bot limit reached, unable to create temporarily',
      content: 'Please contact us to upgrade your version, or delete some Chatbots',
    },
    document: {
      title: 'Document limit reached, unable to add temporarily',
      content: 'Please contact us to upgrade your version, or delete some documents',
    },
    video: {
      title: 'Video limit reached, unable to add temporarily',
      content: 'Please contact us to upgrade your version, or delete some videos',
    },
    buttonText: 'Got it',
    messageLimitAnswer: 'x-elephant seems to be asleep, please contact customer support for assistance',
  },
}

export default translation
