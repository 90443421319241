const translation = {
  createApp: 'CRÉER UNE APPLICATION',
  types: {
    all: 'Tout',
    chatbot: 'Chatbot',
    agent: 'Agent',
    workflow: 'Flux de travail',
    completion: 'Terminaison',
  },
  duplicate: 'Dupliquer',
  duplicateTitle: 'Dupliquer l\'application',
  export: 'Exporter DSL',
  exportFailed: 'Échec de l\'exportation du DSL.',
  importDSL: 'Importer le fichier DSL',
  createFromConfigFile: 'Créer à partir du fichier DSL',
  deleteAppConfirmTitle: 'Supprimer cette application ?',
  deleteAppConfirmContent:
    'La suppression de l\'application est irréversible. Les utilisateurs ne pourront plus accéder à votre application et toutes les configurations de prompt et les journaux seront définitivement supprimés.',
  appDeleted: 'Application supprimée',
  appDeleteFailed: 'Échec de la suppression de l\'application',
  join: 'Rejoindre la communauté',
  communityIntro:
    'Discutez avec les membres de l\'équipe, les contributeurs et les développeurs sur différents canaux.',
  roadmap: 'Voir notre feuille de route',
  newApp: {
    startFromBlank: 'Créer à partir de zéro',
    startFromTemplate: 'Créer à partir d\'un modèle',
    captionAppType: 'Quel type d\'application souhaitez-vous créer ?',
    chatbotDescription: 'Construisez une application basée sur le chat. Cette application utilise un format question-réponse, permettant ainsi plusieurs tours de conversation continue.',
    completionDescription: 'Construisez une application qui génère du texte de haute qualité en fonction des invites, telles que la génération d\'articles, de résumés, de traductions, et plus encore.',
    completionWarning: 'Ce type d\'application ne sera plus pris en charge.',
    agentDescription: 'Construisez un agent intelligent capable de choisir automatiquement les outils pour accomplir les tâches',
    workflowDescription: 'Construisez une application qui génère du texte de haute qualité en fonction d\'un flux de travail avec un haut degré de personnalisation. Il convient aux utilisateurs expérimentés.',
    workflowWarning: 'Actuellement en version bêta',
    chatbotType: 'Méthode d\'orchestration du chatbot',
    basic: 'Basique',
    basicTip: 'Pour les débutants, peut passer à Chatflow plus tard',
    basicFor: 'POUR LES DÉBUTANTS',
    basicDescription: 'L\'orchestration de base permet d\'orchestrer une application Chatbot à l\'aide de paramètres simples, sans possibilité de modifier les invites intégrées. Il convient aux débutants.',
    advanced: 'Chatflow',
    advancedFor: 'Pour les utilisateurs avancés',
    advancedDescription: 'L\'orchestration de flux de travail orchestre les Chatbots sous forme de workflows, offrant un haut degré de personnalisation, y compris la possibilité de modifier les invites intégrées. Il convient aux utilisateurs expérimentés.',
    captionName: 'Icône et nom de l\'application',
    appNamePlaceholder: 'Donnez un nom à votre application',
    captionDescription: 'Description',
    appDescriptionPlaceholder: 'Entrez la description de l\'application',
    useTemplate: 'Utiliser ce modèle',
    previewDemo: 'Aperçu de la démo',
    chatApp: 'Assistant',
    chatAppIntro:
      'Je veux construire une application basée sur le chat. Cette application utilise un format question-réponse, permettant plusieurs tours de conversation continue.',
    agentAssistant: 'Nouvel assistant agent',
    completeApp: 'Générateur de texte',
    completeAppIntro:
      'Je veux créer une application qui génère du texte de haute qualité en fonction des invites, telles que la génération d\'articles, de résumés, de traductions, et plus encore.',
    showTemplates: 'Je veux choisir parmi un modèle',
    hideTemplates: 'Revenir à la sélection de mode',
    Create: 'Créer',
    Cancel: 'Annuler',
    nameNotEmpty: 'Le nom ne peut pas être vide',
    appTemplateNotSelected: 'Veuillez sélectionner un modèle',
    appTypeRequired: 'Veuillez sélectionner un type d\'application',
    appCreated: 'Application créée',
    appCreateFailed: 'Échec de la création de l\'application',
  },
  editApp: 'Modifier les informations',
  editAppTitle: 'Modifier les informations de l\'application',
  editDone: 'Informations sur l\'application mises à jour',
  editFailed: 'Échec de la mise à jour des informations de l\'application',
  iconPicker: {
    ok: 'OK',
    cancel: 'Annuler',
    emoji: 'Emoji',
    image: 'Image',
  },
  switch: 'Passer à l\'orchestration de flux de travail',
  switchTipStart: 'Une nouvelle copie de l\'application sera créée pour vous, et la nouvelle copie passera à l\'orchestration de flux de travail. La nouvelle copie ne permettra pas le ',
  switchTip: 'retour',
  switchTipEnd: ' à l\'orchestration de base.',
  switchLabel: 'La copie de l\'application à créer',
  removeOriginal: 'Supprimer l\'application d\'origine',
  switchStart: 'Commencer la commutation',
  typeSelector: {
    all: 'Tous Types',
    chatbot: 'Chatbot',
    agent: 'Agent',
    workflow: 'Flux de travail',
    completion: 'Terminaison',
  },
  tracing: {
    title: 'Traçage des performances de l\'application',
    description: 'Configuration d\'un fournisseur LLMOps tiers et traçage des performances de l\'application.',
    config: 'Configurer',
    collapse: 'Réduire',
    expand: 'Développer',
    tracing: 'Traçage',
    disabled: 'Désactivé',
    disabledTip: 'Veuillez d\'abord configurer le fournisseur',
    enabled: 'En service',
    tracingDescription: 'Capturez le contexte complet de l\'exécution de l\'application, y compris les appels LLM, le contexte, les prompts, les requêtes HTTP et plus encore, vers une plateforme de traçage tierce.',
    configProviderTitle: {
      configured: 'Configuré',
      notConfigured: 'Configurez le fournisseur pour activer le traçage',
      moreProvider: 'Plus de fournisseurs',
    },
    langsmith: {
      title: 'LangSmith',
      description: 'Une plateforme de développement tout-en-un pour chaque étape du cycle de vie des applications basées sur LLM.',
    },
    langfuse: {
      title: 'Langfuse',
      description: 'Traces, évaluations, gestion des prompts et métriques pour déboguer et améliorer votre application LLM.',
    },
    inUse: 'En utilisation',
    configProvider: {
      title: 'Configurer ',
      placeholder: 'Entrez votre {{key}}',
      project: 'Projet',
      publicKey: 'Clé Publique',
      secretKey: 'Clé Secrète',
      viewDocsLink: 'Voir la documentation de {{key}}',
      removeConfirmTitle: 'Supprimer la configuration de {{key}} ?',
      removeConfirmContent: 'La configuration actuelle est en cours d\'utilisation, sa suppression désactivera la fonction de Traçage.',
    },
  },
}

export default translation
