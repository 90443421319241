const translation = {
  title: 'ابزارها',
  createCustomTool: 'ایجاد ابزار سفارشی',
  customToolTip: 'بیشتر در مورد ابزارهای سفارشی Dify بیاموزید',
  type: {
    all: 'همه',
    builtIn: 'سفارشی شده',
    custom: 'سفارشی',
    workflow: 'جریان کار',
  },
  contribute: {
    line1: 'من علاقه‌مند به ',
    line2: 'مشارکت در ابزارهای Dify هستم.',
    viewGuide: 'مشاهده راهنما',
  },
  author: 'توسط',
  auth: {
    unauthorized: 'برای مجوز دادن',
    authorized: 'مجوز داده شده',
    setup: 'تنظیم مجوز برای استفاده',
    setupModalTitle: 'تنظیم مجوز',
    setupModalTitleDescription: 'پس از پیکربندی اعتبارنامه‌ها، همه اعضای موجود در فضای کاری می‌توانند از این ابزار هنگام هماهنگی برنامه‌ها استفاده کنند.',
  },
  includeToolNum: '{{num}} ابزار شامل شد',
  addTool: 'افزودن ابزار',
  addToolModal: {
    type: 'نوع',
    category: 'دسته‌بندی',
    add: 'افزودن',
    added: 'افزوده شد',
    manageInTools: 'مدیریت در ابزارها',
    emptyTitle: 'هیچ ابزار جریان کاری در دسترس نیست',
    emptyTip: 'به "جریان کاری -> انتشار به عنوان ابزار" بروید',
  },
  createTool: {
    title: 'ایجاد ابزار سفارشی',
    editAction: 'پیکربندی',
    editTitle: 'ویرایش ابزار سفارشی',
    name: 'نام',
    toolNamePlaceHolder: 'نام ابزار را وارد کنید',
    nameForToolCall: 'نام فراخوانی ابزار',
    nameForToolCallPlaceHolder: 'برای شناسایی ماشین، مانند getCurrentWeather، list_pets',
    nameForToolCallTip: 'فقط اعداد، حروف و خط زیر پشتیبانی می‌شود.',
    description: 'توضیحات',
    descriptionPlaceholder: 'توضیحات مختصر در مورد هدف ابزار، مثلاً، گرفتن دما برای یک مکان خاص.',
    schema: 'طرح',
    schemaPlaceHolder: 'طرح OpenAPI خود را اینجا وارد کنید',
    viewSchemaSpec: 'مشاهده مشخصات OpenAPI-Swagger',
    importFromUrl: 'وارد کردن از URL',
    importFromUrlPlaceHolder: 'https://...',
    urlError: 'لطفاً یک URL معتبر وارد کنید',
    examples: 'مثال‌ها',
    exampleOptions: {
      json: 'آب و هوا (JSON)',
      yaml: 'فروشگاه حیوانات خانگی (YAML)',
      blankTemplate: 'الگوی خالی',
    },
    availableTools: {
      title: 'ابزارهای موجود',
      name: 'نام',
      description: 'توضیحات',
      method: 'روش',
      path: 'مسیر',
      action: 'عملیات',
      test: 'آزمایش',
    },
    authMethod: {
      title: 'روش مجوز',
      type: 'نوع مجوز',
      keyTooltip: 'کلید Http Header، می‌توانید آن را با "Authorization" ترک کنید اگر نمی‌دانید چیست یا آن را به یک مقدار سفارشی تنظیم کنید',
      types: {
        none: 'هیچ',
        api_key: 'کلید API',
        apiKeyPlaceholder: 'نام هدر HTTP برای کلید API',
        apiValuePlaceholder: 'کلید API را وارد کنید',
      },
      key: 'کلید',
      value: 'مقدار',
    },
    authHeaderPrefix: {
      title: 'نوع مجوز',
      types: {
        basic: 'پایه',
        bearer: 'Bearer',
        custom: 'سفارشی',
      },
    },
    privacyPolicy: 'سیاست حفظ حریم خصوصی',
    privacyPolicyPlaceholder: 'لطفاً سیاست حفظ حریم خصوصی را وارد کنید',
    toolInput: {
      title: 'ورودی ابزار',
      name: 'نام',
      required: 'الزامی',
      method: 'روش',
      methodSetting: 'تنظیم',
      methodSettingTip: 'کاربر پیکربندی ابزار را پر می‌کند',
      methodParameter: 'پارامتر',
      methodParameterTip: 'LLM در طول استنباط پر می‌کند',
      label: 'برچسب‌ها',
      labelPlaceholder: 'برچسب‌ها را انتخاب کنید (اختیاری)',
      description: 'توضیحات',
      descriptionPlaceholder: 'توضیحات معنی پارامتر',
    },
    customDisclaimer: 'توجهیه سفارشی',
    customDisclaimerPlaceholder: 'لطفاً توجهیه سفارشی را وارد کنید',
    confirmTitle: 'آیا می‌خواهید ذخیره کنید؟',
    confirmTip: 'برنامه‌هایی که از این ابزار استفاده می‌کنند تحت تأثیر قرار خواهند گرفت',
    deleteToolConfirmTitle: 'آیا این ابزار را حذف کنید؟',
    deleteToolConfirmContent: 'حذف ابزار غیرقابل بازگشت است. کاربران دیگر قادر به دسترسی به ابزار شما نخواهند بود.',
  },
  test: {
    title: 'آزمایش',
    parametersValue: 'پارامترها و مقدار',
    parameters: 'پارامترها',
    value: 'مقدار',
    testResult: 'نتایج آزمایش',
    testResultPlaceholder: 'نتیجه آزمایش در اینجا نمایش داده می‌شود',
  },
  thought: {
    using: 'در حال استفاده',
    used: 'استفاده شده',
    requestTitle: 'درخواست به',
    responseTitle: 'پاسخ از',
  },
  setBuiltInTools: {
    info: 'اطلاعات',
    setting: 'تنظیمات',
    toolDescription: 'توضیحات ابزار',
    parameters: 'پارامترها',
    string: 'رشته',
    number: 'عدد',
    required: 'الزامی',
    infoAndSetting: 'اطلاعات و تنظیمات',
  },
  noCustomTool: {
    title: 'ابزار سفارشی وجود ندارد!',
    content: 'ابزارهای سفارشی خود را در اینجا اضافه و مدیریت کنید تا برنامه‌های هوش مصنوعی بسازید.',
    createTool: 'ایجاد ابزار',
  },
  noSearchRes: {
    title: 'متأسفیم، نتیجه‌ای پیدا نشد!',
    content: 'ما نتوانستیم ابزارهایی که با جستجوی شما مطابقت داشته باشد پیدا کنیم.',
    reset: 'بازنشانی جستجو',
  },
  builtInPromptTitle: 'پرامپت',
  toolRemoved: 'ابزار حذف شد',
  notAuthorized: 'ابزار مجوز ندارد',
  howToGet: 'چگونه دریافت کنید',
  openInStudio: 'باز کردن در استودیو',
  toolNameUsageTip: 'نام فراخوانی ابزار برای استدلال و پرامپت‌های عامل',
}

export default translation
