const translation = {
  title: 'Anotări',
  name: 'Răspuns la Anotație',
  editBy: 'Răspuns editat de {{author}}',
  noData: {
    title: 'Fără anotări',
    description: 'Puteți edita anotările în timpul depanării aplicației sau importați anotări în masă aici pentru un răspuns de înaltă calitate.',
  },
  table: {
    header: {
      question: 'întrebare',
      answer: 'răspuns',
      createdAt: 'creat la',
      hits: 'accesări',
      actions: 'acțiuni',
      addAnnotation: 'Adaugă Anotație',
      bulkImport: 'Import în Masă',
      bulkExport: 'Export în Masă',
      clearAll: 'Șterge Toate Anotațiile',
    },
  },
  editModal: {
    title: 'Editează Răspunsul la Anotație',
    queryName: 'Interogare Utilizator',
    answerName: 'Povestitorul Bot',
    yourAnswer: 'Răspunsul Tău',
    answerPlaceholder: 'Scrie răspunsul tău aici',
    yourQuery: 'Interogarea Ta',
    queryPlaceholder: 'Scrie interogarea ta aici',
    removeThisCache: 'Elimină Această Anotație',
    createdAt: 'Creat La',
  },
  addModal: {
    title: 'Adaugă Răspuns la Anotație',
    queryName: 'Întrebare',
    answerName: 'Răspuns',
    answerPlaceholder: 'Scrie răspunsul aici',
    queryPlaceholder: 'Scrie întrebarea aici',
    createNext: 'Adaugă un alt răspuns anotat',
  },
  batchModal: {
    title: 'Import în Masă',
    csvUploadTitle: 'Trage și plasează fișierul tău CSV aici, sau ',
    browse: 'răsfoiește',
    tip: 'Fișierul CSV trebuie să respecte următoarea structură:',
    question: 'întrebare',
    answer: 'răspuns',
    contentTitle: 'conținutul secțiunii',
    content: 'conținut',
    template: 'Descarcă șablonul aici',
    cancel: 'Anulează',
    run: 'Rulează Lotul',
    runError: 'Eroare la rularea lotului',
    processing: 'În procesare',
    completed: 'Import finalizat',
    error: 'Eroare de Import',
    ok: 'OK',
  },
  errorMessage: {
    answerRequired: 'Răspunsul este necesar',
    queryRequired: 'Întrebarea este necesară',
  },
  viewModal: {
    annotatedResponse: 'Răspuns Anotat',
    hitHistory: 'Istoric Accesări',
    hit: 'Acces',
    hits: 'Accesări',
    noHitHistory: 'Fără istoric de accesări',
  },
  hitHistoryTable: {
    query: 'Interogare',
    match: 'Potrivire',
    response: 'Răspuns',
    source: 'Sursă',
    score: 'Scor',
    time: 'Timp',
  },
  initSetup: {
    title: 'Configurare Inițială Răspuns la Anotație',
    configTitle: 'Configurare Răspuns la Anotație',
    confirmBtn: 'Salvează & Activează',
    configConfirmBtn: 'Salvează',
  },
  embeddingModelSwitchTip: 'Model de vectorizare a textului anotației, schimbarea modelelor va fi reîncorporată, rezultând costuri suplimentare.',
}

export default translation
