const translation = {
  pageTitle: '嗨，欢迎使用小象快答',
  welcome: '欢迎来到 Dify, 登录以继续',
  email: '邮箱',
  emailPlaceholder: '输入邮箱地址',
  password: '密码',
  passwordPlaceholder: '输入密码',
  name: '用户名',
  namePlaceholder: '输入用户名',
  forget: '忘记密码？',
  signBtn: '登录',
  installBtn: '设置',
  setAdminAccount: '设置管理员账户',
  setAdminAccountDesc: '管理员拥有的最大权限，可用于创建应用和管理 LLM 供应商等。',
  createAndSignIn: '创建账户',
  oneMoreStep: '还差一步',
  createSample: '基于这些信息，我们将为您创建一个示例应用',
  invitationCode: '邀请码',
  invitationCodePlaceholder: '输入邀请码',
  interfaceLanguage: '界面语言',
  timezone: '时区',
  go: '跳转至 Dify',
  sendUsMail: '发封邮件介绍你自己，我们会尽快处理。',
  acceptPP: '我已阅读并接受隐私政策',
  reset: '请运行以下命令重置密码',
  withGitHub: '使用 GitHub 登录',
  withGoogle: '使用 Google 登录',
  rightTitle: '释放大型语言模型的全部潜能',
  rightDesc: '简单构建可视化、可运营、可改进的 AI 应用',
  tos: '使用协议',
  pp: '隐私政策',
  tosDesc: '使用即代表你并同意我们的',
  goToInit: '如果您还没有初始化账户，请前往初始化页面',
  donthave: '还没有邀请码？',
  invalidInvitationCode: '无效的邀请码',
  accountAlreadyInited: '账户已经初始化',
  forgotPassword: '忘记密码？',
  resetLinkSent: '重置链接已发送',
  sendResetLink: '发送重置链接',
  backToSignIn: '返回登录',
  forgotPasswordDesc: '请输入您的电子邮件地址以重置密码。我们将向您发送一封电子邮件，包含如何重置密码的说明。',
  checkEmailForResetLink: '请检查您的电子邮件以获取重置密码的链接。如果几分钟内没有收到，请检查您的垃圾邮件文件夹。',
  passwordChanged: '立即登录',
  changePassword: '更改密码',
  changePasswordTip: '请输入您的新密码',
  invalidToken: '无效或已过期的令牌',
  confirmPassword: '确认密码',
  confirmPasswordPlaceholder: '确认您的新密码',
  passwordChangedTip: '您的密码已成功更改',
  error: {
    emailEmpty: '邮箱不能为空',
    emailInValid: '请输入有效的邮箱地址',
    nameEmpty: '用户名不能为空',
    passwordEmpty: '密码不能为空',
    passwordInvalid: '密码必须包含字母和数字，且长度不小于8位',
  },
  license: {
    tip: '启动 Dify 社区版之前, 请阅读 GitHub 上的',
    link: '开源协议',
  },
  join: '加入',
  joinTipStart: '邀请你加入',
  joinTipEnd: '团队',
  invalid: '链接已失效',
  explore: '探索 Dify',
  activatedTipStart: '您已加入',
  activatedTipEnd: '团队',
  activated: '现在登录',
  adminInitPassword: '管理员初始化密码',
  validate: '验证',
  noAccount: '没有账号？',
  apply: '申请体验',
}

export default translation
