const translation = {
  knowledgeSupplement: {
    title: 'Bilgi Takviyesi',
    description: 'Kullanıcıların bilgi boşluklarını özetleyin, her Pazartesi güncellenir. Takviyeden sonra, AI verilen yanıtları benzer soruları yanıtlamak için kullanacak ve yanıtların kalitesini artıracaktır.',
    noData: {
      title: 'Burada boş',
      description: 'Şu anda takviye edilecek bilgi yok. AI\'nın performansını konuşma kayıtlarında izleyebilirsiniz.',
    },
    saveSuccess: 'Bilgi takviyesi başarıyla kaydedildi',
    valid: {
      wordLimit: 'Yanıt içeriği en fazla 2000 karakter olabilir. Lütfen kaydetmeden önce içeriği kısaltın.',
    },
  },
  pending: {
    title: 'Olumsuz Geri Bildirim Optimizasyonu',
    description: 'Kullanıcıların memnun kalmadığı AI yanıtlarını kaydeder. Optimizasyondan sonra, AI verilen yanıtları benzer soruları yanıtlamak için kullanacak ve yanıtların kalitesini artıracaktır.',
    noData: {
      title: 'Burada boş',
      description: 'Şu anda kullanıcı şikayeti yok. AI\'nın performansını konuşma kayıtlarında izleyebilirsiniz.',
    },
    checkConversation: 'Tam konuşmayı görüntüle',
    saveSuccess: 'Soru-Cevap optimizasyonu başarıyla kaydedildi',
  },
  optimized: {
    title: 'Optimize Edildi',
    description: 'Optimize edilmiş Soru-Cevap\'ları kaydeder; burada Soru-Cevap içeriğini ayarlayabilir ve AI\'nın kullanım geçmişini görüntüleyebilirsiniz.',
    noData: {
      title: 'Burada boş',
      description: 'Memnuniyetsiz AI yanıtlarını optimize etmek için bekleyen listeyi veya konuşma kayıtlarını kontrol edebilirsiniz.',
    },
    viewModal: {
      optimizedTitle: 'Optimize Edilmiş Soru-Cevap',
      historyTitle: 'AI Kullanım Geçmişi',
    },
  },
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      userQuestion: 'Kullanıcı Sorusu',
      aiAnswer: 'AI Yanıtı',
      question: 'Soru',
      answer: 'Yanıt',
      createdAt: 'Oluşturma Zamanı',
      hits: 'AI Kullanım Sayısı',
      actions: 'Eylemler',
      time: 'Konuşma Zamanı',
      questionCount: 'Soru Sayısı',
    },
    pagination: {
      previous: 'Önceki Sayfa',
      next: 'Sonraki Sayfa',
    },
  },
  confirm: {
    dismiss: 'Bu soruyu reddet',
    delete: 'Bu optimizasyonu sil',
  },
  operation: {
    edit: 'Bu yanıtı optimize et',
    reEdit: 'Optimizasyon içeriğini düzenle',
    save: 'Optimizasyonu kaydet',
  },
  editModal: {
    question: 'Soru',
    answer: 'Yanıt',
    queryName: 'Kullanıcı Sorusu',
    answerName: 'AI Yanıtı',
    yourAnswer: 'Optimize Edilmiş Yanıt',
    answerPlaceholder: 'İstenen AI yanıtını buraya girin',
    yourQuery: 'Optimize Edilmiş Soru',
    queryPlaceholder: 'Kullanıcı sorusunu daha genel ve net bir soruya yeniden ifade edin, bu AI\'nın benzer soruları daha iyi eşleştirmesine yardımcı olur',
  },
  log: {
    optimizationLog: 'Optimizasyon eklendi',
    optimized: 'Bu yanıt optimize edilmiş içeriği kullandı',
  },
}

export default translation
