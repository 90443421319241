const translation = {
  knowledgeSupplement: {
    title: 'Uzupełnienie Wiedzy',
    description: 'Podsumowanie luk w wiedzy użytkowników, aktualizowane w każdy poniedziałek. Po uzupełnieniu, AI będzie używać podanych odpowiedzi do odpowiadania na podobne pytania, poprawiając jakość odpowiedzi.',
    noData: {
      title: 'Tutaj jest pusto',
      description: 'Na razie nie ma wiedzy do uzupełnienia. Możesz obserwować wydajność AI w zapisach rozmów.',
    },
    saveSuccess: 'Uzupełnienie wiedzy zapisane pomyślnie',
    valid: {
      wordLimit: 'Treść odpowiedzi może mieć do 2000 znaków. Proszę skrócić treść przed zapisaniem.',
    },
  },
  pending: {
    title: 'Optymalizacja Negatywnych Opinii',
    description: 'Rejestruje odpowiedzi AI, z których użytkownicy nie byli zadowoleni. Po optymalizacji, AI będzie używać podanych odpowiedzi do odpowiadania na podobne pytania, poprawiając jakość odpowiedzi.',
    noData: {
      title: 'Tutaj jest pusto',
      description: 'Na razie nie ma skarg użytkowników. Możesz obserwować wydajność AI w zapisach rozmów.',
    },
    checkConversation: 'Zobacz pełną rozmowę',
    saveSuccess: 'Optymalizacja Q&A zapisana pomyślnie',
  },
  optimized: {
    title: 'Zoptymalizowane',
    description: 'Rejestruje zoptymalizowane Q&A; możesz dostosować treść Q&A i zobaczyć historię użycia AI tutaj.',
    noData: {
      title: 'Tutaj jest pusto',
      description: 'Możesz sprawdzić listę oczekujących lub zapisy rozmów, aby zoptymalizować niezadowalające odpowiedzi AI.',
    },
    viewModal: {
      optimizedTitle: 'Zoptymalizowane Q&A',
      historyTitle: 'Historia Użycia AI',
    },
  },
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      userQuestion: 'Pytanie Użytkownika',
      aiAnswer: 'Odpowiedź AI',
      question: 'Pytanie',
      answer: 'Odpowiedź',
      createdAt: 'Czas Utworzenia',
      hits: 'Liczba Użyć AI',
      actions: 'Akcje',
      time: 'Czas Rozmowy',
      questionCount: 'Liczba Pytań',
    },
    pagination: {
      previous: 'Poprzednia Strona',
      next: 'Następna Strona',
    },
  },
  confirm: {
    dismiss: 'Odrzuć to pytanie',
    delete: 'Usuń tę optymalizację',
  },
  operation: {
    edit: 'Zoptymalizuj tę odpowiedź',
    reEdit: 'Edytuj treść optymalizacji',
    save: 'Zapisz optymalizację',
  },
  editModal: {
    question: 'Pytanie',
    answer: 'Odpowiedź',
    queryName: 'Pytanie Użytkownika',
    answerName: 'Odpowiedź AI',
    yourAnswer: 'Zoptymalizowana Odpowiedź',
    answerPlaceholder: 'Wpisz tutaj pożądaną odpowiedź AI',
    yourQuery: 'Zoptymalizowane Pytanie',
    queryPlaceholder: 'Przeformułuj pytanie użytkownika na bardziej ogólne i jasne, co pomoże AI lepiej dopasować podobne pytania',
  },
}

export default translation
