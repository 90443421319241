const translation = {
  title: 'Анотації',
  name: 'Відповідь на анотацію',
  editBy: 'Відповідь відредагована {{author}}',
  noData: {
    title: 'Немає анотацій',
    description: 'Ви можете редагувати анотації під час налагодження програми або імпортувати анотації гуртом тут для отримання високоякісної відповіді.',
  },
  table: {
    header: {
      question: 'запитання',
      answer: 'відповідь',
      createdAt: 'створено у',
      hits: 'переглядів',
      actions: 'дії',
      addAnnotation: 'Додати анотацію',
      bulkImport: 'Масовий імпорт',
      bulkExport: 'Масовий експорт',
      clearAll: 'Очистити всі анотації',
    },
  },
  editModal: {
    title: 'Редагувати відповідь на анотацію',
    queryName: 'Запит користувача',
    answerName: 'Бот-оповідач',
    yourAnswer: 'Ваша відповідь',
    answerPlaceholder: 'Введіть свою відповідь тут',
    yourQuery: 'Ваш запит',
    queryPlaceholder: 'Введіть свій запит тут',
    removeThisCache: 'Видалити цю анотацію',
    createdAt: 'Створено у',
  },
  addModal: {
    title: 'Додати відповідь на анотацію',
    queryName: 'Запитання',
    answerName: 'Відповідь',
    answerPlaceholder: 'Введіть відповідь тут',
    queryPlaceholder: 'Введіть запит тут',
    createNext: 'Додати ще одну анотовану відповідь',
  },
  batchModal: {
    title: 'Масовий імпорт',
    csvUploadTitle: 'Перетягніть файл CSV сюди або',
    browse: 'огляд',
    tip: 'Файл CSV повинен відповідати такій структурі:',
    question: 'запитання',
    answer: 'відповідь',
    contentTitle: 'вміст частини',
    content: 'вміст',
    template: 'Завантажте шаблон тут',
    cancel: 'Скасувати',
    run: 'Запустити партію',
    runError: 'Не вдалося запустити партію',
    processing: 'У пакетній обробці',
    completed: 'Імпорт завершено',
    error: 'Помилка імпорту',
    ok: 'ОК',
  },
  errorMessage: {
    answerRequired: 'Відповідь обов’язкова',
    queryRequired: 'Запитання обов’язкове',
  },
  viewModal: {
    annotatedResponse: 'Відповідь на анотацію',
    hitHistory: 'Історія переглядів',
    hit: 'Перегляд',
    hits: 'Переглядів',
    noHitHistory: 'Історії переглядів немає',
  },
  hitHistoryTable: {
    query: 'Запит',
    match: 'Збіг',
    response: 'Відповідь',
    source: 'Джерело',
    score: 'Бал',
    time: 'Час',
  },
  initSetup: {
    title: 'Початкова настройка відповіді на анотацію',
    configTitle: 'Налаштування відповіді на анотацію',
    confirmBtn: 'Зберегти та ввімкнути',
    configConfirmBtn: 'Зберегти',
  },
  embeddingModelSwitchTip: 'Модель векторизації тексту анотації, перемикання моделей буде повторно вбудовано, що призведе до додаткових витрат.',
}

export default translation
