const translation = {
  title: 'Bilgi ayarları',
  desc: 'Burada Bilginin özelliklerini ve çalışma yöntemlerini değiştirebilirsiniz.',
  form: {
    name: 'Bilgi İsmi',
    namePlaceholder: 'Bilgi ismini girin',
    nameError: 'İsim boş olamaz',
    desc: 'Bilgi açıklaması',
    descInfo: 'Lütfen Bilginin içeriğini özetlemek için net bir metinsel açıklama yazın. Bu açıklama, çıkarım için birden fazla Bilgi arasından seçim yaparken eşleştirme temeli olarak kullanılacaktır.',
    descPlaceholder: 'Bu Bilginin içeriğini açıklayın. Ayrıntılı bir açıklama, AI\'nın Bilginin içeriğine zamanında erişmesini sağlar. Boş bırakılırsa, Dify varsayılan isabet stratejisini kullanır.',
    descWrite: 'İyi bir Bilgi açıklamasının nasıl yazılacağını öğrenin.',
    permissions: 'İzinler',
    permissionsOnlyMe: 'Sadece ben',
    permissionsAllMember: 'Tüm takım üyeleri',
    permissionsInvitedMembers: 'Bazı takım üyeleri',
    me: '(Siz)',
    indexMethod: 'Dizin Yöntemi',
    indexMethodHighQuality: 'Yüksek Kalite',
    indexMethodHighQualityTip: 'Kullanıcılar sorguladığında daha yüksek doğruluk sağlamak için Yerleştirme modelini çağırır.',
    indexMethodEconomy: 'Ekonomik',
    indexMethodEconomyTip: 'Doğruluğu azaltmak için çevrimdışı vektör motorları, anahtar kelime dizinleri vb. kullanın, token harcamadan',
    embeddingModel: 'Yerleştirme Modeli',
    embeddingModelTip: 'Yerleştirme modelini değiştirmek için, lütfen ',
    embeddingModelTipLink: 'Ayarlar\'a gidin',
    retrievalSetting: {
      title: 'Geri Alım Ayarı',
      learnMore: 'Daha fazla bilgi edinin',
      description: ' geri alım yöntemi hakkında.',
      longDescription: ' geri alım yöntemi hakkında, bunu Bilgi ayarlarında istediğiniz zaman değiştirebilirsiniz.',
    },
    save: 'Kaydet',
  },
}

export default translation
