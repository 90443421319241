const translation = {
  list: {
    title: 'Documenti',
    desc: 'Tutti i file della Conoscenza sono mostrati qui, e l\'intera Conoscenza può essere collegata alle citazioni di Dify o indicizzata tramite il plugin di Chat.',
    addFile: 'Aggiungi file',
    addPages: 'Aggiungi Pagine',
    addUrl: 'Aggiungi URL',
    table: {
      header: {
        fileName: 'NOME FILE',
        words: 'PAROLE',
        hitCount: 'CONTEGGIO RECUPERI',
        uploadTime: 'ORA DI CARICAMENTO',
        status: 'STATO',
        action: 'AZIONE',
      },
      rename: 'Rinomina',
      name: 'Nome',
    },
    action: {
      uploadFile: 'Carica nuovo file',
      settings: 'Impostazioni segmenti',
      addButton: 'Aggiungi blocco',
      add: 'Aggiungi un blocco',
      batchAdd: 'Aggiungi in batch',
      archive: 'Archivia',
      unarchive: 'Disarchivia',
      delete: 'Elimina',
      enableWarning: 'Il file archiviato non può essere abilitato',
      sync: 'Sincronizza',
    },
    index: {
      enable: 'Abilita',
      disable: 'Disabilita',
      all: 'Tutti',
      enableTip: 'Il file può essere indicizzato',
      disableTip: 'Il file non può essere indicizzato',
    },
    status: {
      queuing: 'In coda',
      indexing: 'Indicizzazione',
      paused: 'In pausa',
      error: 'Errore',
      available: 'Disponibile',
      enabled: 'Abilitato',
      disabled: 'Disabilitato',
      archived: 'Archiviato',
      deep_indexing: 'AI Deep Indexing',
    },
    empty: {
      title: 'Non ci sono ancora documenti',
      upload: {
        tip: 'Puoi caricare file, sincronizzare dal sito web o da app web come Notion, GitHub, ecc.',
      },
      sync: {
        tip: 'Dify scaricherà periodicamente i file dal tuo Notion e completerà l\'elaborazione.',
      },
    },
    delete: {
      title: 'Sei sicuro di voler eliminare?',
      content:
        'Se hai bisogno di riprendere l\'elaborazione in seguito, continuerai da dove hai interrotto',
    },
    batchModal: {
      title: 'Aggiungi blocchi in batch',
      csvUploadTitle: 'Trascina e rilascia il tuo file CSV qui, o ',
      browse: 'sfoglia',
      tip: 'Il file CSV deve rispettare la seguente struttura:',
      question: 'domanda',
      answer: 'risposta',
      contentTitle: 'contenuto del blocco',
      content: 'contenuto',
      template: 'Scarica qui il modello',
      cancel: 'Annulla',
      run: 'Esegui Batch',
      runError: 'Esecuzione batch fallita',
      processing: 'Elaborazione batch in corso',
      completed: 'Importazione completata',
      error: 'Errore di importazione',
      ok: 'OK',
    },
  },
  metadata: {
    title: 'Metadati',
    desc: 'L\'etichettatura dei metadati per i documenti consente all\'IA di accedervi in modo tempestivo ed espone la fonte delle referenze per gli utenti.',
    dateTimeFormat: 'MMMM D, YYYY hh:mm A',
    docTypeSelectTitle: 'Per favore seleziona un tipo di documento',
    docTypeChangeTitle: 'Cambia tipo di documento',
    docTypeSelectWarning:
      'Se il tipo di documento viene cambiato, i metadati attualmente compilati non saranno più conservati',
    firstMetaAction: 'Andiamo',
    placeholder: {
      add: 'Aggiungi ',
      select: 'Seleziona ',
    },
    source: {
      upload_file: 'Carica File',
      notion: 'Sincronizza da Notion',
      github: 'Sincronizza da Github',
    },
    type: {
      book: 'Libro',
      webPage: 'Pagina Web',
      paper: 'Documento',
      socialMediaPost: 'Post sui Social Media',
      personalDocument: 'Documento Personale',
      businessDocument: 'Documento Aziendale',
      IMChat: 'Chat IM',
      wikipediaEntry: 'Voce Wikipedia',
      notion: 'Sincronizza da Notion',
      github: 'Sincronizza da Github',
      technicalParameters: 'Parametri Tecnici',
    },
    field: {
      processRule: {
        processDoc: 'Elabora Documento',
        segmentRule: 'Regola di Segmentazione',
        segmentLength: 'Lunghezza dei Segmenti',
        processClean: 'Pulizia del Testo',
      },
      book: {
        title: 'Titolo',
        language: 'Lingua',
        author: 'Autore',
        publisher: 'Editore',
        publicationDate: 'Data di Pubblicazione',
        ISBN: 'ISBN',
        category: 'Categoria',
      },
      webPage: {
        title: 'Titolo',
        url: 'URL',
        language: 'Lingua',
        authorPublisher: 'Autore/Editore',
        publishDate: 'Data di Pubblicazione',
        topicsKeywords: 'Argomenti/Parole Chiave',
        description: 'Descrizione',
      },
      paper: {
        title: 'Titolo',
        language: 'Lingua',
        author: 'Autore',
        publishDate: 'Data di Pubblicazione',
        journalConferenceName: 'Nome del Journal/Conferenza',
        volumeIssuePage: 'Volume/Numero/Pagina',
        DOI: 'DOI',
        topicsKeywords: 'Argomenti/Parole Chiave',
        abstract: 'Abstract',
      },
      socialMediaPost: {
        platform: 'Piattaforma',
        authorUsername: 'Autore/Username',
        publishDate: 'Data di Pubblicazione',
        postURL: 'URL del Post',
        topicsTags: 'Argomenti/Tag',
      },
      personalDocument: {
        title: 'Titolo',
        author: 'Autore',
        creationDate: 'Data di Creazione',
        lastModifiedDate: 'Data di Ultima Modifica',
        documentType: 'Tipo di Documento',
        tagsCategory: 'Tag/Categoria',
      },
      businessDocument: {
        title: 'Titolo',
        author: 'Autore',
        creationDate: 'Data di Creazione',
        lastModifiedDate: 'Data di Ultima Modifica',
        documentType: 'Tipo di Documento',
        departmentTeam: 'Dipartimento/Team',
      },
      IMChat: {
        chatPlatform: 'Piattaforma di Chat',
        chatPartiesGroupName: 'Parti della Chat/Nome del Gruppo',
        participants: 'Partecipanti',
        startDate: 'Data di Inizio',
        endDate: 'Data di Fine',
        topicsKeywords: 'Argomenti/Parole Chiave',
        fileType: 'Tipo di File',
      },
      wikipediaEntry: {
        title: 'Titolo',
        language: 'Lingua',
        webpageURL: 'URL della Pagina Web',
        editorContributor: 'Editore/Contributore',
        lastEditDate: 'Data di Ultima Modifica',
        summaryIntroduction: 'Sommario/Introduzione',
      },
      notion: {
        title: 'Titolo',
        language: 'Lingua',
        author: 'Autore',
        createdTime: 'Ora di Creazione',
        lastModifiedTime: 'Ora di Ultima Modifica',
        url: 'URL',
        tag: 'Tag',
        description: 'Descrizione',
      },
      github: {
        repoName: 'Nome del Repo',
        repoDesc: 'Descrizione del Repo',
        repoOwner: 'Proprietario del Repo',
        fileName: 'Nome del File',
        filePath: 'Percorso del File',
        programmingLang: 'Linguaggio di Programmazione',
        url: 'URL',
        license: 'Licenza',
        lastCommitTime: 'Ora dell\'Ultimo Commit',
        lastCommitAuthor: 'Autore dell\'Ultimo Commit',
      },
      originInfo: {
        originalFilename: 'Nome file originale',
        originalFileSize: 'Dimensione file originale',
        uploadDate: 'Data di caricamento',
        lastUpdateDate: 'Data di ultimo aggiornamento',
        source: 'Fonte',
      },
      technicalParameters: {
        segmentSpecification: 'Specifiche dei segmenti',
        segmentLength: 'Lunghezza dei segmenti',
        avgParagraphLength: 'Lunghezza media del paragrafo',
        paragraphs: 'Paragrafi',
        hitCount: 'Conteggio recuperi',
        embeddingTime: 'Tempo di embedding',
        embeddedSpend: 'Spesa di embedding',
      },
    },
    languageMap: {
      zh: 'Cinese',
      en: 'Inglese',
      es: 'Spagnolo',
      fr: 'Francese',
      de: 'Tedesco',
      ja: 'Giapponese',
      ko: 'Coreano',
      ru: 'Russo',
      ar: 'Arabo',
      pt: 'Portoghese',
      it: 'Italiano',
      nl: 'Olandese',
      pl: 'Polacco',
      sv: 'Svedese',
      tr: 'Turco',
      he: 'Ebraico',
      hi: 'Hindi',
      da: 'Danese',
      fi: 'Finlandese',
      no: 'Norvegese',
      hu: 'Ungherese',
      el: 'Greco',
      cs: 'Ceco',
      th: 'Thailandese',
      id: 'Indonesiano',
    },
    categoryMap: {
      book: {
        fiction: 'Narrativa',
        biography: 'Biografia',
        history: 'Storia',
        science: 'Scienza',
        technology: 'Tecnologia',
        education: 'Educazione',
        philosophy: 'Filosofia',
        religion: 'Religione',
        socialSciences: 'Scienze Sociali',
        art: 'Arte',
        travel: 'Viaggio',
        health: 'Salute',
        selfHelp: 'Auto-aiuto',
        businessEconomics: 'Economia Aziendale',
        cooking: 'Cucina',
        childrenYoungAdults: 'Bambini e Giovani Adulti',
        comicsGraphicNovels: 'Fumetti e Graphic Novels',
        poetry: 'Poesia',
        drama: 'Teatro',
        other: 'Altro',
      },
      personalDoc: {
        notes: 'Note',
        blogDraft: 'Bozza di Blog',
        diary: 'Diario',
        researchReport: 'Rapporto di Ricerca',
        bookExcerpt: 'Estratto di Libro',
        schedule: 'Pianificazione',
        list: 'Lista',
        projectOverview: 'Panoramica del Progetto',
        photoCollection: 'Collezione Fotografica',
        creativeWriting: 'Scrittura Creativa',
        codeSnippet: 'Frammento di Codice',
        designDraft: 'Bozza di Design',
        personalResume: 'Curriculum Vitae',
        other: 'Altro',
      },
      businessDoc: {
        meetingMinutes: 'Verbale della Riunione',
        researchReport: 'Rapporto di Ricerca',
        proposal: 'Proposta',
        employeeHandbook: 'Manuale del Dipendente',
        trainingMaterials: 'Materiali di Formazione',
        requirementsDocument: 'Documento di Requisiti',
        designDocument: 'Documento di Design',
        productSpecification: 'Specifiche del Prodotto',
        financialReport: 'Rapporto Finanziario',
        marketAnalysis: 'Analisi di Mercato',
        projectPlan: 'Piano di Progetto',
        teamStructure: 'Struttura del Team',
        policiesProcedures: 'Politiche e Procedure',
        contractsAgreements: 'Contratti e Accordi',
        emailCorrespondence: 'Corrispondenza Email',
        other: 'Altro',
      },
    },
  },
  embedding: {
    processing: 'Elaborazione embedding...',
    paused: 'Embedding in pausa',
    completed: 'Embedding completato',
    error: 'Errore embedding',
    docName: 'Elaborazione documento',
    mode: 'Regola di segmentazione',
    segmentLength: 'Lunghezza dei segmenti',
    textCleaning: 'Pre-definizione e pulizia del testo',
    segments: 'Paragrafi',
    highQuality: 'Modalità alta qualità',
    economy: 'Modalità economica',
    estimate: 'Consumo stimato',
    stop: 'Ferma elaborazione',
    resume: 'Riprendi elaborazione',
    automatic: 'Automatico',
    custom: 'Personalizzato',
    previewTip:
      'L\'anteprima del paragrafo sarà disponibile dopo il completamento dell\'embedding',
  },
  segment: {
    paragraphs: 'Paragrafi',
    keywords: 'Parole Chiave',
    addKeyWord: 'Aggiungi parola chiave',
    keywordError: 'La lunghezza massima della parola chiave è 20',
    characters: 'caratteri',
    hitCount: 'Conteggio recuperi',
    vectorHash: 'Hash del vettore: ',
    questionPlaceholder: 'aggiungi domanda qui',
    questionEmpty: 'La domanda non può essere vuota',
    answerPlaceholder: 'aggiungi risposta qui',
    answerEmpty: 'La risposta non può essere vuota',
    contentPlaceholder: 'aggiungi contenuto qui',
    contentEmpty: 'Il contenuto non può essere vuoto',
    newTextSegment: 'Nuovo Segmento di Testo',
    newQaSegment: 'Nuovo Segmento di Domanda & Risposta',
    delete: 'Eliminare questo blocco?',
    videoUrl: 'Video Url',
  },
}

export default translation
