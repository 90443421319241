const translation = {
  common: {
    welcome: '이용해주셔서 감사합니다',
    appUnavailable: '앱을 사용할 수 없습니다',
    appUnkonwError: '앱을 사용할 수 없습니다',
  },
  chat: {
    newChat: '새 채팅',
    pinnedTitle: '고정됨',
    unpinnedTitle: '채팅',
    newChatDefaultName: '새 대화',
    resetChat: '대화 재설정',
    powerBy: 'Powered by',
    prompt: '프롬프트',
    privatePromptConfigTitle: '채팅 설정',
    publicPromptConfigTitle: '초기 프롬프트',
    configStatusDes: '시작하기 전에 채팅 설정을 변경할 수 있습니다',
    configDisabled: '이전 세션의 설정이 현재 세션에서 사용되었습니다.',
    startChat: '채팅 시작',
    privacyPolicyLeft: '앱 개발자가 제공하는',
    privacyPolicyMiddle: '개인 정보 보호 정책',
    privacyPolicyRight: '을 읽어보세요.',
    deleteConversation: {
      title: '대화 삭제',
      content: '이 대화를 삭제하시겠습니까?',
    },
    tryToSolve: '해결하려고 합니다',
    temporarySystemIssue: '죄송합니다. 일시적인 시스템 문제가 발생했습니다.',
    like: '지원해 주셔서 감사합니다. 계속 발전하겠습니다!',
    dislikeLeft: '답변이 충분하지 않아 죄송합니다. 계속 발전하겠습니다.',
  },
  generation: {
    tabs: {
      create: '일회용 실행',
      batch: '일괄 실행',
      saved: '저장된 결과',
    },
    savedNoData: {
      title: '아직 저장된 결과가 없습니다!',
      description: '컨텐츠 생성을 시작하고 저장된 결과를 여기서 찾아보세요.',
      startCreateContent: '컨텐츠 생성 시작',
    },
    title: 'AI 완성',
    queryTitle: '컨텐츠 쿼리',
    completionResult: '완성 결과',
    queryPlaceholder: '쿼리 컨텐츠를 작성해주세요...',
    run: '실행',
    copy: '복사',
    resultTitle: 'AI 완성',
    noData: 'AI가 필요한 내용을 제공할 것입니다.',
    csvUploadTitle: 'CSV 파일을 여기로 끌어다 놓거나',
    browse: '찾아보기',
    csvStructureTitle: 'CSV 파일은 다음 구조를 따라야 합니다:',
    downloadTemplate: '여기에서 템플릿 다운로드',
    field: '필드',
    batchFailed: {
      info: '{{num}} 회의 실행이 실패했습니다',
      retry: '재시도',
      outputPlaceholder: '출력 컨텐츠 없음',
    },
    errorMsg: {
      empty: '업로드된 파일에 컨텐츠를 입력해주세요.',
      fileStructNotMatch: '업로드된 CSV 파일이 구조와 일치하지 않습니다.',
      emptyLine: '줄 {{rowIndex}}이(가) 비어 있습니다.',
      invalidLine: '줄 {{rowIndex}}: {{varName}}의 값은 비워둘 수 없습니다.',
      moreThanMaxLengthLine: '줄 {{rowIndex}}: {{varName}}의 값은 {{maxLength}}자를 초과할 수 없습니다.',
      atLeastOne: '업로드된 파일에는 적어도 한 줄의 입력이 필요합니다.',
    },
  },
}

export default translation
