const translation = {
  pageTitle: '¡Hola, vamos a empezar!👋',
  welcome: 'Bienvenido a Dify, por favor inicia sesión para continuar.',
  email: 'Correo electrónico',
  emailPlaceholder: 'Tu correo electrónico',
  password: 'Contraseña',
  passwordPlaceholder: 'Tu contraseña',
  name: 'Nombre de usuario',
  namePlaceholder: 'Tu nombre de usuario',
  forget: '¿Olvidaste tu contraseña?',
  signBtn: 'Iniciar sesión',
  sso: 'Continuar con SSO',
  installBtn: 'Configurar',
  setAdminAccount: 'Configurando una cuenta de administrador',
  setAdminAccountDesc: 'Privilegios máximos para la cuenta de administrador, que se puede utilizar para crear aplicaciones y administrar proveedores de LLM, etc.',
  createAndSignIn: 'Crear e iniciar sesión',
  oneMoreStep: 'Un paso más',
  createSample: 'Con esta información, crearemos una aplicación de muestra para ti',
  invitationCode: 'Código de invitación',
  invitationCodePlaceholder: 'Tu código de invitación',
  interfaceLanguage: 'Idioma de interfaz',
  timezone: 'Zona horaria',
  go: 'Ir a Dify',
  sendUsMail: 'Envíanos un correo electrónico con tu presentación y nosotros nos encargaremos de la solicitud de invitación.',
  acceptPP: 'He leído y acepto la política de privacidad',
  reset: 'Por favor, ejecuta el siguiente comando para restablecer tu contraseña',
  withGitHub: 'Continuar con GitHub',
  withGoogle: 'Continuar con Google',
  rightTitle: 'Desbloquea todo el potencial de LLM',
  rightDesc: 'Construye de manera sencilla aplicaciones de IA visualmente cautivadoras, operables y mejorables.',
  tos: 'Términos de servicio',
  pp: 'Política de privacidad',
  tosDesc: 'Al registrarte, aceptas nuestros',
  goToInit: 'Si no has inicializado la cuenta, por favor ve a la página de inicialización',
  donthave: '¿No tienes?',
  invalidInvitationCode: 'Código de invitación inválido',
  accountAlreadyInited: 'La cuenta ya está inicializada',
  forgotPassword: '¿Olvidaste tu contraseña?',
  resetLinkSent: 'Enlace de restablecimiento enviado',
  sendResetLink: 'Enviar enlace de restablecimiento',
  backToSignIn: 'Volver a iniciar sesión',
  forgotPasswordDesc: 'Por favor, ingresa tu dirección de correo electrónico para restablecer tu contraseña. Te enviaremos un correo electrónico con instrucciones sobre cómo restablecer tu contraseña.',
  checkEmailForResetLink: 'Por favor, revisa tu correo electrónico para encontrar un enlace para restablecer tu contraseña. Si no aparece en unos minutos, asegúrate de revisar tu carpeta de spam.',
  passwordChanged: 'Inicia sesión ahora',
  changePassword: 'Cambiar contraseña',
  changePasswordTip: 'Por favor, ingresa una nueva contraseña para tu cuenta',
  invalidToken: 'Token inválido o expirado',
  confirmPassword: 'Confirmar contraseña',
  confirmPasswordPlaceholder: 'Confirma tu nueva contraseña',
  passwordChangedTip: 'Tu contraseña se ha cambiado correctamente',
  error: {
    emailEmpty: 'Se requiere una dirección de correo electrónico',
    emailInValid: 'Por favor, ingresa una dirección de correo electrónico válida',
    nameEmpty: 'Se requiere un nombre',
    passwordEmpty: 'Se requiere una contraseña',
    passwordLengthInValid: 'La contraseña debe tener al menos 8 caracteres',
    passwordInvalid: 'La contraseña debe contener letras y números, y tener una longitud mayor a 8',
  },
  license: {
    tip: 'Antes de comenzar con Dify Community Edition, lee la',
    link: 'Licencia de código abierto de GitHub',
  },
  join: 'Unirse',
  joinTipStart: 'Te invita a unirte al equipo de',
  joinTipEnd: 'en Dify',
  invalid: 'El enlace ha expirado',
  explore: 'Explorar Dify',
  activatedTipStart: 'Te has unido al equipo de',
  activatedTipEnd: '',
  activated: 'Inicia sesión ahora',
  adminInitPassword: 'Contraseña de inicialización de administrador',
  validate: 'Validar',
}

export default translation
