const translation = {
  currentPlan: '現在のプラン',
  upgradeBtn: {
    plain: 'プランをアップグレード',
    encourage: '今すぐアップグレード',
    encourageShort: 'アップグレード',
  },
  viewBilling: '請求とサブスクリプションの管理',
  buyPermissionDeniedTip: 'サブスクリプションするには、エンタープライズ管理者に連絡してください',
  plansCommon: {
    title: 'あなた様に合ったプランを選択してください',
    yearlyTip: '年間購読で2か月無料！',
    mostPopular: '最も人気のある',
    planRange: {
      monthly: '月額',
      yearly: '年額',
    },
    month: '月',
    year: '年',
    save: '節約 ',
    free: '無料',
    currentPlan: '現在のプラン',
    contractSales: '営業に連絡する',
    contractOwner: 'チームマネージャーに連絡する',
    startForFree: '無料で始める',
    getStartedWith: '始める ',
    contactSales: '営業に連絡する',
    talkToSales: '営業と話す',
    modelProviders: 'モデルプロバイダー',
    teamMembers: 'チームメンバー',
    annotationQuota: 'アノテーション・クォータ',
    buildApps: 'アプリを作成する',
    vectorSpace: 'ベクトルスペース',
    vectorSpaceBillingTooltip: '1MBあたり約120万文字のベクトル化データを保存できます（OpenAI Embeddingsを使用して推定され、モデルによって異なります）。',
    vectorSpaceTooltip: 'ベクトルスペースは、LLMがデータを理解するために必要な長期記憶システムです。',
    documentsUploadQuota: 'ドキュメント・アップロード・クォータ',
    documentProcessingPriority: 'ドキュメント処理の優先度',
    documentProcessingPriorityTip: 'より高いドキュメント処理の優先度をご希望の場合は、プランをアップグレードしてください。',
    documentProcessingPriorityUpgrade: 'より高い精度と高速な速度でデータを処理します。',
    priority: {
      'standard': '標準',
      'priority': '優先',
      'top-priority': '最優先',
    },
    logsHistory: 'ログ履歴',
    customTools: 'カスタムツール',
    unavailable: '利用不可',
    days: '日',
    unlimited: '無制限',
    support: 'サポート',
    supportItems: {
      communityForums: 'コミュニティフォーラム',
      emailSupport: 'メールサポート',
      priorityEmail: '優先メール＆チャットサポート',
      logoChange: 'ロゴ変更',
      SSOAuthentication: 'SSO認証',
      personalizedSupport: '個別サポート',
      dedicatedAPISupport: '専用APIサポート',
      customIntegration: 'カスタム統合とサポート',
      ragAPIRequest: 'RAG APIリクエスト',
      bulkUpload: 'ドキュメントの一括アップロード',
      agentMode: 'エージェントモード',
      workflow: 'ワークフロー',
      llmLoadingBalancing: 'LLMロードバランシング',
      llmLoadingBalancingTooltip: 'APIレート制限を効果的に回避するために、モデルに複数のAPIキーを追加する。',
    },
    comingSoon: '近日公開',
    member: 'メンバー',
    memberAfter: 'メンバー',
    messageRequest: {
      title: 'メッセージクレジット',
      tooltip: 'OpenAIモデルを使用したさまざまなプランのメッセージ呼び出しクォータ（gpt4を除く）。制限を超えるメッセージはOpenAI APIキーを使用します。',
    },
    annotatedResponse: {
      title: '注釈クォータ制限',
      tooltip: '手動での編集と応答の注釈付けにより、アプリのカスタマイズ可能な高品質な質問応答機能が提供されます（チャットアプリのみ適用）。',
    },
    ragAPIRequestTooltip: 'Difyのナレッジベース処理機能のみを呼び出すAPI呼び出しの数を指します。',
    receiptInfo: 'チームオーナーとチーム管理者のみが購読および請求情報を表示できます',
  },
  plans: {
    sandbox: {
      name: 'サンドボックス',
      description: 'GPTの無料トライアル200回',
      includesTitle: '含まれるもの：',
    },
    professional: {
      name: 'プロフェッショナル',
      description: '個人や小規模チーム向けにより多くのパワーを手頃な価格で提供します。',
      includesTitle: '無料プランに加えて、次も含まれます：',
    },
    team: {
      name: 'チーム',
      description: '制限なく協力し、最高のパフォーマンスを楽しむ。',
      includesTitle: 'プロフェッショナルプランに加えて、次も含まれます：',
    },
    enterprise: {
      name: 'エンタープライズ',
      description: '大規模なミッションクリティカルシステムのためのフル機能とサポートを提供します。',
      includesTitle: 'チームプランに加えて、次も含まれます：',
    },
  },
  vectorSpace: {
    fullTip: 'ベクトルスペースがいっぱいです。',
    fullSolution: 'より多くのスペースを得るためにプランをアップグレードしてください。',
  },
  apps: {
    fullTipLine1: 'より多くのアプリを作成するには、',
    fullTipLine2: 'プランをアップグレードしてください。',
  },
  annotatedResponse: {
    fullTipLine1: 'より多くの会話を注釈するには、',
    fullTipLine2: 'プランをアップグレードしてください。',
    quotaTitle: '注釈返信クォータ',
  },
}

export default translation
