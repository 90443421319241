const translation = {
  pageTitle: 'هی، بیایید شروع کنیم!👋',
  welcome: 'به Dify خوش آمدید، لطفا برای ادامه وارد شوید.',
  email: 'آدرس ایمیل',
  emailPlaceholder: 'ایمیل شما',
  password: 'رمز عبور',
  passwordPlaceholder: 'رمز عبور شما',
  name: 'نام کاربری',
  namePlaceholder: 'نام کاربری شما',
  forget: 'رمز عبور خود را فراموش کرده‌اید؟',
  signBtn: 'ورود',
  sso: 'ادامه با SSO',
  installBtn: 'راه‌اندازی',
  setAdminAccount: 'راه‌اندازی حساب مدیر',
  setAdminAccountDesc: 'بیشترین امتیازات برای حساب مدیر، که می‌تواند برای ایجاد برنامه‌ها و مدیریت ارائه‌دهندگان LLM و غیره استفاده شود.',
  createAndSignIn: 'ایجاد و ورود',
  oneMoreStep: 'یک مرحله دیگر',
  createSample: 'بر اساس این اطلاعات، ما برای شما یک نمونه برنامه ایجاد خواهیم کرد',
  invitationCode: 'کد دعوت',
  invitationCodePlaceholder: 'کد دعوت شما',
  interfaceLanguage: 'زبان رابط کاربری',
  timezone: 'منطقه زمانی',
  go: 'برو به Dify',
  sendUsMail: 'ایمیل معرفی خود را برای ما ارسال کنید، و ما درخواست دعوت را بررسی خواهیم کرد.',
  acceptPP: 'من سیاست حفظ حریم خصوصی را خوانده و قبول می‌کنم',
  reset: 'لطفاً برای بازنشانی رمز عبور خود دستور زیر را اجرا کنید',
  withGitHub: 'ادامه با GitHub',
  withGoogle: 'ادامه با Google',
  rightTitle: 'پتانسیل کامل LLM را باز کنید',
  rightDesc: 'به راحتی برنامه‌های AI با ظاهری جذاب، قابل اجرا و بهبود پذیر ایجاد کنید.',
  tos: 'شرایط خدمات',
  pp: 'سیاست حفظ حریم خصوصی',
  tosDesc: 'با ثبت نام، شما با شرایط ما موافقت می‌کنید',
  goToInit: 'اگر حساب را اولیه نکرده‌اید، لطفاً به صفحه اولیه‌سازی بروید',
  donthave: 'ندارید؟',
  invalidInvitationCode: 'کد دعوت نامعتبر است',
  accountAlreadyInited: 'حساب قبلاً اولیه شده است',
  forgotPassword: 'رمز عبور خود را فراموش کرده‌اید؟',
  resetLinkSent: 'لینک بازنشانی ارسال شد',
  sendResetLink: 'ارسال لینک بازنشانی',
  backToSignIn: 'بازگشت به ورود',
  forgotPasswordDesc: 'لطفاً آدرس ایمیل خود را وارد کنید تا رمز عبور خود را بازنشانی کنید. ما یک ایمیل با دستورالعمل‌های بازنشانی برای شما ارسال خواهیم کرد.',
  checkEmailForResetLink: 'لطفاً ایمیل خود را برای لینک بازنشانی رمز عبور بررسی کنید. اگر در عرض چند دقیقه ظاهر نشد، پوشه اسپم خود را بررسی کنید.',
  passwordChanged: 'اکنون وارد شوید',
  changePassword: 'تغییر رمز عبور',
  changePasswordTip: 'لطفاً یک رمز عبور جدید برای حساب خود وارد کنید',
  invalidToken: 'توکن نامعتبر یا منقضی شده است',
  confirmPassword: 'تایید رمز عبور',
  confirmPasswordPlaceholder: 'رمز عبور جدید خود را تایید کنید',
  passwordChangedTip: 'رمز عبور شما با موفقیت تغییر یافت',
  error: {
    emailEmpty: 'آدرس ایمیل لازم است',
    emailInValid: 'لطفاً یک آدرس ایمیل معتبر وارد کنید',
    nameEmpty: 'نام لازم است',
    passwordEmpty: 'رمز عبور لازم است',
    passwordLengthInValid: 'رمز عبور باید حداقل ۸ کاراکتر باشد',
    passwordInvalid: 'رمز عبور باید شامل حروف و اعداد باشد و طول آن بیشتر از ۸ کاراکتر باشد',
  },
  license: {
    tip: 'قبل از شروع Dify Community Edition، GitHub را بخوانید',
    link: 'مجوز منبع باز',
  },
  join: 'عضویت',
  joinTipStart: 'شما را دعوت می‌کنیم به',
  joinTipEnd: 'تیم در Dify',
  invalid: 'لینک منقضی شده است',
  explore: 'کاوش Dify',
  activatedTipStart: 'شما به',
  activatedTipEnd: 'تیم پیوسته‌اید',
  activated: 'اکنون وارد شوید',
  adminInitPassword: 'رمز عبور اولیه مدیر',
  validate: 'اعتبارسنجی',
}

export default translation
