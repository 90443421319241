const translation = {
  welcome: {
    firstStepTip: 'शुरू करने के लिए,',
    enterKeyTip: 'नीचे अपना OpenAI API कुंजी दर्ज करें',
    getKeyTip: 'OpenAI डैशबोर्ड से अपनी API कुंजी प्राप्त करें',
    placeholder: 'आपकी OpenAI API कुंजी (उदा. sk-xxxx)',
  },
  apiKeyInfo: {
    cloud: {
      trial: {
        title: 'आप {{providerName}} परीक्षण कोटा का उपयोग कर रहे हैं।',
        description:
          'परीक्षण कोटा आपके परीक्षण उपयोग के लिए प्रदान किया गया है। कृपया परीक्षण कोटा कॉल समाप्त होने से पहले अपना स्वयं का मॉडल प्रदाता सेट करें या अतिरिक्त कोटा खरीदें।',
      },
      exhausted: {
        title:
          'आपका परीक्षण कोटा समाप्त हो गया है, कृपया अपना API कुंजी सेट करें।',
        description:
          'आपका परीक्षण कोटा समाप्त हो गया है। कृपया अपना स्वयं का मॉडल प्रदाता सेट करें या अतिरिक्त कोटा खरीदें।',
      },
    },
    selfHost: {
      title: {
        row1: 'शुरू करने के लिए,',
        row2: 'पहले अपना मॉडल प्रदाता सेटअप करें।',
      },
    },
    callTimes: 'कॉल समय',
    usedToken: 'उपयोग किया गया टोकन',
    setAPIBtn: 'मॉडल प्रदाता सेटअप पर जाएं',
    tryCloud: 'या मुफ्त कोटा के साथ Dify का क्लाउड संस्करण आज़माएं',
  },
  overview: {
    title: 'अवलोकन',
    appInfo: {
      explanation: 'रेडी-टू-यूज़ AI वेबऐप',
      accessibleAddress: 'सार्वजनिक URL',
      preview: 'पूर्वावलोकन',
      regenerate: 'पुनः उत्पन्न करें',
      regenerateNotice: 'क्या आप सार्वजनिक URL को पुनः उत्पन्न करना चाहते हैं?',
      preUseReminder: 'जारी रखने से पहले कृपया वेबऐप सक्षम करें।',
      settings: {
        entry: 'सेटिंग्स',
        title: 'वेबऐप सेटिंग्स',
        webName: 'वेबऐप नाम',
        webDesc: 'वेबऐप विवरण',
        webDescTip:
          'यह टेक्स्ट क्लाइंट साइड पर प्रदर्शित होगा, जो एप्लिकेशन का उपयोग करने के लिए बुनियादी मार्गदर्शन प्रदान करेगा',
        webDescPlaceholder: 'वेबऐप का विवरण दर्ज करें',
        language: 'भाषा',
        workflow: {
          title: 'वर्कफ़्लो स्टेप्स',
          show: 'दिखाएं',
          hide: 'छुपाएं',
        },
        chatColorTheme: 'चैटबॉट का रंग थीम',
        chatColorThemeDesc: 'चैटबॉट का रंग थीम निर्धारित करें',
        chatColorThemeInverted: 'उल्टा',
        invalidHexMessage: 'अमान्य हेक्स मान',
        more: {
          entry: 'अधिक सेटिंग्स दिखाएं',
          copyright: 'कॉपीराइट',
          copyRightPlaceholder: 'लेखक या संगठन का नाम दर्ज करें',
          privacyPolicy: 'गोपनीयता नीति',
          privacyPolicyPlaceholder: 'गोपनीयता नीति लिंक दर्ज करें',
          privacyPolicyTip:
            'आगंतुकों को यह समझने में मदद करता है कि एप्लिकेशन कौन सा डेटा एकत्र करता है, देखें Dify की <privacyPolicyLink>गोपनीयता नीति</privacyPolicyLink>।',
          customDisclaimer: 'कस्टम अस्वीकरण',
          customDisclaimerPlaceholder: 'कस्टम अस्वीकरण टेक्स्ट दर्ज करें',
          customDisclaimerTip:
            'कस्टम अस्वीकरण टेक्स्ट क्लाइंट साइड पर प्रदर्शित होगा, जो एप्लिकेशन के बारे में अतिरिक्त जानकारी प्रदान करेगा',
        },
      },
      embedded: {
        entry: 'एम्बेडेड',
        title: 'वेबसाइट पर एम्बेड करें',
        explanation: 'अपनी वेबसाइट पर चैट ऐप को एम्बेड करने का तरीका चुनें',
        iframe:
          'अपनी वेबसाइट के किसी भी हिस्से पर चैट ऐप जोड़ने के लिए, इस iframe को अपने HTML कोड में जोड़ें।',
        scripts:
          'अपनी वेबसाइट के निचले दाएं कोने में चैट ऐप जोड़ने के लिए इस कोड को अपने HTML में जोड़ें।',
        chromePlugin: 'Dify चैटबॉट क्रोम एक्सटेंशन इंस्टॉल करें',
        copied: 'कॉपी किया गया',
        copy: 'कॉपी करें',
      },
      qrcode: {
        title: 'शेयर करने के लिए क्यूआर कोड',
        scan: 'एप्लिकेशन शेयर स्कैन करें',
        download: 'क्यूआर कोड डाउनलोड करें',
      },
      customize: {
        way: 'तरीका',
        entry: 'कस्टमाइज़ करें',
        title: 'AI वेबऐप कस्टमाइज़ करें',
        explanation:
          'आप वेब ऐप के फ्रंटेंड को अपनी स्थिति और शैली की आवश्यकताओं के अनुसार कस्टमाइज़ कर सकते हैं।',
        way1: {
          name: 'क्लाइंट कोड को फोर्क करें, उसे संशोधित करें और Vercel पर डिप्लॉय करें (अनुशंसित)',
          step1: 'क्लाइंट कोड को फोर्क करें और संशोधित करें',
          step1Tip:
            'स्रोत कोड को अपने GitHub खाते में फोर्क करने और कोड संशोधित करने के लिए यहां क्लिक करें',
          step1Operation: 'Dify-WebClient',
          step2: 'Vercel पर डिप्लॉय करें',
          step2Tip:
            'भंडार को Vercel में आयात करने और डिप्लॉय करने के लिए यहां क्लिक करें',
          step2Operation: 'भंडार आयात करें',
          step3: 'पर्यावरण वेरिएबल्स कॉन्फ़िगर करें',
          step3Tip: 'Vercel में निम्नलिखित पर्यावरण वेरिएबल्स जोड़ें',
        },
        way2: {
          name: 'एपीआई को कॉल करने के लिए क्लाइंट-साइड कोड लिखें और इसे सर्वर पर डिप्लॉय करें',
          operation: 'प्रलेखन',
        },
      },
    },
    apiInfo: {
      title: 'बैकएंड सेवा एपीआई',
      explanation: 'आसानी से अपने एप्लिकेशन में एकीकृत',
      accessibleAddress: 'सेवा एपीआई एंडपॉइंट',
      doc: 'एपीआई संदर्भ',
    },
    status: {
      running: 'सेवा में',
      disable: 'अक्षम करें',
    },
  },
  analysis: {
    title: 'विश्लेषण',
    ms: 'मि.से.',
    tokenPS: 'टोकन/से.',
    totalMessages: {
      title: 'कुल संदेश',
      explanation:
        'दैनिक एआई इंटरैक्शन की गिनती; प्रॉम्प्ट इंजीनियरिंग/डीबगिंग को शामिल नहीं किया गया।',
    },
    activeUsers: {
      title: 'सक्रिय उपयोगकर्ता',
      explanation:
        'AI के साथ प्रश्नोत्तर में संलग्न अद्वितीय उपयोगकर्ता; प्रॉम्प्ट इंजीनियरिंग/डीबगिंग को शामिल नहीं किया गया।',
    },
    tokenUsage: {
      title: 'टोकन उपयोग',
      explanation:
        'एप्लिकेशन के लिए भाषा मॉडल के दैनिक टोकन उपयोग को दर्शाता है, जो लागत नियंत्रण उद्देश्यों के लिए उपयोगी है।',
      consumed: 'उपभोग किया गया',
    },
    avgSessionInteractions: {
      title: 'औसत सत्र इंटरैक्शन',
      explanation:
        'निरंतर उपयोगकर्ता-एआई संचार की गिनती; वार्तालाप-आधारित ऐप्स के लिए।',
    },
    avgUserInteractions: {
      title: 'औसत उपयोगकर्ता इंटरैक्शन',
      explanation:
        'उपयोगकर्ताओं के दैनिक उपयोग की आवृत्ति को दर्शाता है। यह मीट्रिक उपयोगकर्ता स्थिरता को दर्शाता है।',
    },
    userSatisfactionRate: {
      title: 'उपयोगकर्ता संतोष दर',
      explanation:
        'प्रति 1,000 संदेशों में से पसंदों की संख्या। यह उन उत्तरों के अनुपात को इंगित करता है जिनसे उपयोगकर्ता अत्यधिक संतुष्ट हैं।',
    },
    avgResponseTime: {
      title: 'औसत प्रतिक्रिया समय',
      explanation:
        'टेक्स्ट-आधारित ऐप्स के लिए एआई द्वारा संसाधित/प्रतिक्रिया समय (मि.से.)।',
    },
    tps: {
      title: 'टोकन आउटपुट स्पीड',
      explanation:
        'LLM के प्रदर्शन को मापें। अनुरोध के शुरू होने से आउटपुट पूरा होने तक LLM के टोकन आउटपुट गति की गणना करें।',
    },
  },
}

export default translation
