const translation = {
  pageTitle: {
    line1: 'PROMPT',
    line2: 'Engineering',
  },
  orchestrate: 'Orchestrate',
  promptMode: {
    simple: 'Tüm PROMPT\'u düzenlemek için Uzman Moduna geçin',
    advanced: 'Uzman Modu',
    switchBack: 'Geri Dön',
    advancedWarning: {
      title: 'Uzman Moduna geçtiniz ve PROMPT\'u değiştirdiğinizde, temel moda geri DÖNEMEZSİNİZ.',
      description: 'Uzman Modunda, tüm PROMPT\'u düzenleyebilirsiniz.',
      learnMore: 'Daha Fazla Bilgi',
      ok: 'Tamam',
    },
    operation: {
      addMessage: 'Mesaj Ekle',
    },
    contextMissing: 'Bağlam bileşeni eksik, promptun etkinliği iyi olmayabilir.',
  },
  operation: {
    applyConfig: 'Yayınla',
    resetConfig: 'Sıfırla',
    debugConfig: 'Hata Ayıkla',
    addFeature: 'Özellik Ekle',
    automatic: 'Oluştur',
    stopResponding: 'Yanıtlamayı Durdur',
    agree: 'beğeni',
    disagree: 'beğenmeme',
    cancelAgree: 'Beğeniyi İptal Et',
    cancelDisagree: 'Beğenmeme İptal Et',
    userAction: 'Kullanıcı',
  },
  notSetAPIKey: {
    title: 'LLM sağlayıcı anahtarı ayarlanmadı',
    trailFinished: 'Deneme süresi sona erdi',
    description: 'LLM sağlayıcı anahtarı ayarlanmadı, hata ayıklamadan önce ayarlanması gerekiyor.',
    settingBtn: 'Ayarlar\'a git',
  },
  trailUseGPT4Info: {
    title: 'Şu anda gpt-4 desteklenmiyor',
    description: 'Gpt-4 kullanmak için, lütfen API Anahtarını ayarlayın.',
  },
  feature: {
    groupChat: {
      title: 'Sohbet Geliştirme',
      description: 'Uygulamalar için ön görüşme ayarları eklemek kullanıcı deneyimini artırabilir.',
    },
    groupExperience: {
      title: 'Deneyim Geliştirme',
    },
    conversationOpener: {
      title: 'Konuşma Başlatıcı',
      description: 'Bir sohbet uygulamasında, yapay zekanın kullanıcıya aktif olarak söylediği ilk cümle genellikle bir karşılama olarak kullanılır.',
    },
    suggestedQuestionsAfterAnswer: {
      title: 'Takip Soruları',
      description: 'Sonraki soru önerilerini ayarlamak, kullanıcılara daha iyi bir sohbet deneyimi sunabilir.',
      resDes: 'Kullanıcı için 3 önerilen sonraki soru.',
      tryToAsk: 'Sormayı dene',
    },
    moreLikeThis: {
      title: 'Bunun gibi daha fazlası',
      description: 'Birden fazla metni bir kerede üretin, ardından düzenleyin ve üretmeye devam edin',
      generateNumTip: 'Her üretim seferinde üretilen metin sayısı',
      tip: 'Bu özelliği kullanmak ek token maliyetine yol açacaktır',
    },
    speechToText: {
      title: 'Sesten Metne',
      description: 'Etkinleştirildiğinde, sesli giriş kullanabilirsiniz.',
      resDes: 'Sesli giriş etkinleştirildi',
    },
    textToSpeech: {
      title: 'Metinden Sese',
      description: 'Etkinleştirildiğinde, metin sese dönüştürülebilir.',
      resDes: 'Metinden Ses dosyasına dönüştürme etkinleştirildi',
    },
    citation: {
      title: 'Alıntılar ve Atıflar',
      description: 'Etkinleştirildiğinde, oluşturulan içeriğin kaynak belgesi ve atıfta bulunulan bölümü gösterilir.',
      resDes: 'Alıntılar ve Atıflar etkinleştirildi',
    },
    annotation: {
      title: 'Ek Açıklama Yanıtı',
      description: 'Benzer kullanıcı sorularıyla öncelikli eşleşme için, yüksek kaliteli yanıtları manuel olarak önbelleğe ekleyebilirsiniz.',
      resDes: 'Ek Açıklama Yanıtı etkinleştirildi',
      scoreThreshold: {
        title: 'Skor Eşiği',
        description: 'Ek açıklama yanıtı için benzerlik eşiğini ayarlamak için kullanılır.',
        easyMatch: 'Kolay Eşleşme',
        accurateMatch: 'Doğru Eşleşme',
      },
      matchVariable: {
        title: 'Eşleşme Değişkeni',
        choosePlaceholder: 'Eşleşme değişkenini seçin',
      },
      cacheManagement: 'Ek Açıklamalar',
      cached: 'Ek Açıklamalı',
      remove: 'Kaldır',
      removeConfirm: 'Bu ek açıklamayı silmek istiyor musunuz?',
      add: 'Ek açıklama ekle',
      edit: 'Ek açıklamayı düzenle',
    },
    dataSet: {
      title: 'Bağlam',
      noData: 'Bağlam olarak Bilgi\'yi içe aktarabilirsiniz',
      words: 'Kelimeler',
      textBlocks: 'Metin Blokları',
      selectTitle: 'Referans Bilgi\'yi seçin',
      selected: 'Bilgi seçildi',
      noDataSet: 'Bilgi bulunamadı',
      toCreate: 'Oluşturmaya git',
      notSupportSelectMulti: 'Şu anda sadece bir Bilgi destekleniyor',
      queryVariable: {
        title: 'Sorgu değişkeni',
        tip: 'Bu değişken, bağlam geri alımı için sorgu girişi olarak kullanılacak ve bu değişkenin girdisiyle ilgili bağlam bilgisi elde edilecektir.',
        choosePlaceholder: 'Sorgu değişkenini seçin',
        noVar: 'Değişken yok',
        noVarTip: 'Lütfen Değişkenler bölümünde bir değişken oluşturun',
        unableToQueryDataSet: 'Bilgi sorgulanamıyor',
        unableToQueryDataSetTip: 'Bilgi başarılı bir şekilde sorgulanamıyor, lütfen bağlam bölümünde bir bağlam sorgu değişkeni seçin.',
        ok: 'Tamam',
        contextVarNotEmpty: 'Bağlam sorgu değişkeni boş olamaz',
        deleteContextVarTitle: 'Değişken "{{varName}}" silinsin mi?',
        deleteContextVarTip: 'Bu değişken bağlam sorgu değişkeni olarak ayarlanmış, kaldırılması Bilgi\'nin normal kullanımını etkileyecektir. Yine de silmek istiyorsanız, lütfen bağlam bölümünde yeniden seçin.',
      },
    },
    tools: {
      title: 'Araçlar',
      tips: 'Araçlar, kullanıcı girişi veya değişkenleri istek parametreleri olarak alarak dış verileri bağlam olarak sorgulamak için standart bir API çağrı yöntemi sağlar.',
      toolsInUse: '{{count}} araç kullanımda',
      modal: {
        title: 'Araç',
        toolType: {
          title: 'Araç Türü',
          placeholder: 'Lütfen araç türünü seçin',
        },
        name: {
          title: 'İsim',
          placeholder: 'Lütfen ismi girin',
        },
        variableName: {
          title: 'Değişken İsmi',
          placeholder: 'Lütfen değişken ismini girin',
        },
      },
    },
    conversationHistory: {
      title: 'Konuşma Geçmişi',
      description: 'Konuşma rolleri için ön ek isimler ayarlayın',
      tip: 'Konuşma Geçmişi etkin değil, lütfen promptun üst kısmında <histories> ekleyin.',
      learnMore: 'Daha fazla bilgi',
      editModal: {
        title: 'Konuşma Rol İsimlerini Düzenle',
        userPrefix: 'Kullanıcı ön eki',
        assistantPrefix: 'Asistan ön eki',
      },
    },
    toolbox: {
      title: 'ARAÇLAR',
    },
    moderation: {
      title: 'İçerik Denetimi',
      description: 'Denetim API\'sini kullanarak veya bir hassas kelime listesi oluşturarak model çıktısını güvence altına alın.',
      allEnabled: 'GİRİŞ/ÇIKIŞ İçeriği Etkin',
      inputEnabled: 'GİRİŞ İçeriği Etkin',
      outputEnabled: 'ÇIKIŞ İçeriği Etkin',
      modal: {
        title: 'İçerik denetim ayarları',
        provider: {
          title: 'Sağlayıcı',
          openai: 'OpenAI Denetim',
          openaiTip: {
            prefix: 'OpenAI Denetim, Ayarlar sayfasında yapılandırılmış bir OpenAI API anahtarı gerektirir',
            suffix: '.',
          },
          keywords: 'Anahtar Kelimeler',
        },
        keywords: {
          tip: 'Her satırda bir tane, satır sonlarıyla ayrılmış. Satır başına en fazla 100 karakter.',
          placeholder: 'Her satırda bir tane, satır sonlarıyla ayrılmış',
          line: 'Satır',
        },
        content: {
          input: 'GİRİŞ İçeriğini Denetle',
          output: 'ÇIKIŞ İçeriğini Denetle',
          preset: 'Önceden Ayarlanmış Yanıtlar',
          placeholder: 'Önceden ayarlanmış yanıt içeriği buraya',
          condition: 'GİRİŞ ve ÇIKIŞ İçeriği denetimi en az birinde etkin',
          fromApi: 'Önceden ayarlanmış yanıtlar API tarafından döndürülür',
          errorMessage: 'Önceden ayarlanmış yanıtlar boş olamaz',
          supportMarkdown: 'Markdown desteklenir',
        },
        openaiNotConfig: {
          before: 'OpenAI Denetim, Ayarlar sayfasında yapılandırılmış bir OpenAI API anahtarı gerektirir',
          after: '',
        },
      },
    },
  },
  generate: {
    title: 'Prompt Oluşturucu',
    description: 'Prompt Oluşturucu, yapılandırılan modeli kullanarak promptları daha iyi kalite ve yapı için optimize eder. Lütfen açık ve ayrıntılı talimatlar yazın.',
    tryIt: 'Deneyin',
    instruction: 'Talimatlar',
    instructionPlaceHolder: 'Açık ve belirli talimatlar yazın.',
    generate: 'Oluştur',
    resTitle: 'Oluşturulmuş Prompt',
    noDataLine1: 'Kullanım durumunuzu solda açıklayın,',
    noDataLine2: 'orkestrasyon önizlemesi burada görünecek.',
    apply: 'Uygula',
    loading: 'Uygulama orkestrasyonu yapılıyor...',
    overwriteTitle: 'Mevcut yapılandırmanın üzerine yazılsın mı?',
    overwriteMessage: 'Bu promptu uygulamak mevcut yapılandırmanın üzerine yazacaktır.',
    template: {
      pythonDebugger: {
        name: 'Python hata ayıklayıcı',
        instruction: 'Talimatlarınıza göre kod üretebilen ve hata ayıklayabilen bir bot',
      },
      translation: {
        name: 'Çeviri',
        instruction: 'Birden çok dili çevirebilen bir çevirmen',
      },
      professionalAnalyst: {
        name: 'Profesyonel analist',
        instruction: 'Uzun raporlardan içgörüleri çıkarın, riskleri belirleyin ve temel bilgileri tek bir notta özetleyin',
      },
      excelFormulaExpert: {
        name: 'Excel formül uzmanı',
        instruction: 'Kullanıcı talimatlarına dayalı olarak Excel formüllerini anlamaya, kullanmaya ve oluşturmaya yardımcı olan bir sohbet botu',
      },
      travelPlanning: {
        name: 'Seyahat planlama',
        instruction: 'Seyahat Planlama Asistanı, kullanıcıların seyahatlerini zorluk çekmeden planlamalarına yardımcı olmak için tasarlanmış akıllı bir araçtır',
      },
      SQLSorcerer: {
        name: 'SQL büyücüsü',
        instruction: 'Günlük dili SQL sorgularına dönüştürür',
      },
      GitGud: {
        name: 'Git güd',
        instruction: 'Kullanıcı tarafından açıklanan sürüm kontrol eylemlerine dayalı uygun Git komutları oluşturur',
      },
      meetingTakeaways: {
        name: 'Toplantıdan alınanlar',
        instruction: 'Toplantıları anahtar konular, önemli çıkarımlar ve eylem maddeleri dahil olmak üzere özlü özetlere ayırır',
      },
      writingsPolisher: {
        name: 'Yazma cilalayıcı',
        instruction: 'Yazılarınızı geliştirmek için ileri düzeyde kopya düzenleme teknikleri kullanır',
      },
    },
  },
  resetConfig: {
    title: 'Sıfırlamayı onaylıyor musunuz?',
    message:
      'Sıfırlama, son yayımlanan yapılandırmaya geri yükleyerek değişiklikleri atar.',
  },
  errorMessage: {
    nameOfKeyRequired: 'Anahtar adı: {{key}} gerekli',
    valueOfVarRequired: '{{key}} değeri boş olamaz',
    queryRequired: 'İstek metni gereklidir.',
    waitForResponse:
      'Önceki mesajın yanıtını tamamlamasını bekleyin.',
    waitForBatchResponse:
      'Toplu görevin yanıtını tamamlamasını bekleyin.',
    notSelectModel: 'Lütfen bir model seçin',
    waitForImgUpload: 'Lütfen görüntünün yüklenmesini bekleyin',
  },
  chatSubTitle: 'Talimatlar',
  completionSubTitle: 'Ön Prompt',
  promptTip:
    'Promptlar, yapay zekayı talimatlar ve kısıtlamalarla yönlendirir. {{input}} gibi değişkenler ekleyin. Bu prompt, kullanıcılara görünmeyecek.',
  formattingChangedTitle: 'Biçimlendirme değiştirildi',
  formattingChangedText:
    'Biçimlendirmeyi değiştirmek hata ayıklama alanını sıfırlayacaktır, emin misiniz?',
  variableTitle: 'Değişkenler',
  variableTip:
    'Kullanıcılar değişkenleri bir formda doldurur ve otomatik olarak prompt içinde değişkenler değiştirilir.',
  notSetVar: 'Değişkenler, kullanıcıların form doldururken prompt kelimelerini veya açılış ifadelerini getirmesine izin verir. Prompt kelimelerine "{{input}}" yazmayı deneyebilirsiniz.',
  autoAddVar: 'Ön promptta referans verilen tanımlanmamış değişkenler, kullanıcı giriş formunda eklemek istiyor musunuz?',
  variableTable: {
    key: 'Değişken Anahtarı',
    name: 'Kullanıcı Giriş Alanı Adı',
    optional: 'İsteğe Bağlı',
    type: 'Giriş Tipi',
    action: 'Aksiyonlar',
    typeString: 'Metin',
    typeSelect: 'Seçim',
  },
  varKeyError: {
    canNoBeEmpty: 'Değişken anahtarı boş olamaz',
    tooLong: 'Değişken anahtarı: {{key}} çok uzun. 30 karakterden uzun olamaz',
    notValid: 'Değişken anahtarı: {{key}} geçersizdir. Sadece harfler, rakamlar ve altçizgiler içerebilir',
    notStartWithNumber: 'Değişken anahtarı: {{key}} bir rakamla başlamamalıdır',
    keyAlreadyExists: 'Değişken anahtarı: {{key}} zaten mevcut',
  },
  otherError: {
    promptNoBeEmpty: 'Prompt boş olamaz',
    historyNoBeEmpty: 'Konuşma geçmişi prompt\'ta ayarlanmalıdır',
    queryNoBeEmpty: 'Sorgu prompt\'ta ayarlanmalıdır',
  },
  variableConig: {
    addModalTitle: 'Giriş Alanı Ekle',
    editModalTitle: 'Giriş Alanı Düzenle',
    description: 'Değişken ayarı {{varName}}',
    fieldType: 'Alan türü',
    string: 'Kısa Metin',
    textInput: 'Kısa Metin',
    paragraph: 'Paragraf',
    select: 'Seçim',
    number: 'Numara',
    notSet: 'Ayarlanmamış, ön promptta {{input}} yazmayı deneyin',
    stringTitle: 'Form metin kutusu seçenekleri',
    maxLength: 'En uzunluk',
    options: 'Seçenekler',
    addOption: 'Seçenek ekle',
    apiBasedVar: 'API tabanlı Değişken',
    varName: 'Değişken Adı',
    labelName: 'Etiket Adı',
    inputPlaceholder: 'Lütfen girin',
    content: 'İçerik',
    required: 'Gerekli',
    errorMsg: {
      varNameRequired: 'Değişken adı gereklidir',
      labelNameRequired: 'Etiket adı gereklidir',
      varNameCanBeRepeat: 'Değişken adı tekrar edemez',
      atLeastOneOption: 'En az bir seçenek gereklidir',
      optionRepeat: 'Yinelenen seçenekler var',
    },
  },
  vision: {
    name: 'Görüş',
    description: 'Görüş etkinleştirildiğinde modelin görüntüleri almasını ve bunlarla ilgili soruları yanıtlamasını sağlar.',
    settings: 'Ayarlar',
    visionSettings: {
      title: 'Görüş Ayarları',
      resolution: 'Çözünürlük',
      resolutionTooltip: 'Düşük çözünürlük, modelin görüntünün düşük çözünürlüklü 512 x 512 versiyonunu almasını sağlar ve görüntüyü 65 token bütçesiyle temsil eder. Bu, API\'nin daha hızlı yanıtlar dönmesini ve daha az giriş tokeni tüketmesini sağlar ve bu yüksek detay gerektirmeyen kullanım durumları için uygundur.\nYüksek çözünürlük, modelin önce düşük çözünürlüklü görüntüyü görmesini sağlar ve ardından giriş görüntüsünün boyutuna göre 512 piksel kareler olarak detaylı kırpma işlemleri gerçekleştirir. Her bir detaylı kırpma işlemi toplamda 129 token bütçesi kullanır.',
      high: 'Yüksek',
      low: 'Düşük',
      uploadMethod: 'Yükleme Yöntemi',
      both: 'Her İkisi',
      localUpload: 'Yerel Yükleme',
      url: 'URL',
      uploadLimit: 'Yükleme Limiti',
    },
  },
  voice: {
    name: 'Konuşma',
    defaultDisplay: 'Varsayılan Ses',
    description: 'Metinden sese ses ayarları',
    settings: 'Ayarlar',
    voiceSettings: {
      title: 'Ses Ayarları',
      language: 'Dil',
      resolutionTooltip: 'Metinden sese ses destek dili.',
      voice: 'Ses',
      autoPlay: 'Otomatik Oynatma',
      autoPlayEnabled: 'Açık',
      autoPlayDisabled: 'Kapalı',
    },
  },
  openingStatement: {
    title: 'Konuşma Başlatıcı',
    add: 'Ekle',
    writeOpener: 'Başlangıç mesajı yaz',
    placeholder: 'Başlangıç mesajınızı buraya yazın, değişkenler kullanabilirsiniz, örneğin {{variable}} yazmayı deneyin.',
    openingQuestion: 'Açılış Soruları',
    noDataPlaceHolder:
      'Kullanıcı ile konuşmayı başlatmak, AI\'ın konuşma uygulamalarında onlarla daha yakın bir bağlantı kurmasına yardımcı olabilir.',
    varTip: 'Değişkenler kullanabilirsiniz, örneğin {{variable}} yazmayı deneyin',
    tooShort: 'Konuşma için açılış ifadeleri oluşturmak için en az 20 kelimelik başlangıç promptu gereklidir.',
    notIncludeKey: 'Başlangıç promptu değişkeni içermiyor: {{key}}. Lütfen bunu başlangıç promptuna ekleyin.',
  },
  modelConfig: {
    model: 'Model',
    setTone: 'Yanıtların tonunu ayarla',
    title: 'Model ve Parametreler',
    modeType: {
      chat: 'Sohbet',
      completion: 'Tamamlama',
    },
  },
  inputs: {
    title: 'Hata ayıklama ve Önizleme',
    noPrompt: 'Ön prompt girişine bazı promptlar yazmayı deneyin',
    userInputField: 'Kullanıcı Giriş Alanı',
    noVar: 'Yeni bir oturum başlatıldığında prompt kelimesinde otomatik olarak değiştirilecek değişkenin değerini doldurun.',
    chatVarTip: 'Yeni bir oturum başlatıldığında prompt kelimesinde otomatik olarak değiştirilecek değişkenin değerini doldurun',
    completionVarTip: 'Her soru gönderildiğinde prompt kelimelerinde otomatik olarak değiştirilecek değişkenin değerini doldurun.',
    previewTitle: 'Prompt önizleme',
    queryTitle: 'Sorgu içeriği',
    queryPlaceholder: 'Lütfen istek metnini girin.',
    run: 'ÇALIŞTIR',
  },
  result: 'Çıktı Metni',
  datasetConfig: {
    settingTitle: 'Geri Alım Ayarları',
    knowledgeTip: 'Bilgi eklemek için “+” düğmesine tıklayın',
    retrieveOneWay: {
      title: 'N-to-1 geri alım',
      description: 'Kullanıcı niyetine ve Bilgi tanımına dayanarak, Agent en iyi Bilgi\'yi sorgulamak için özerk bir şekilde seçer. Belirgin, sınırlı Bilgi bulunan uygulamalar için en iyisidir.',
    },
    retrieveMultiWay: {
      title: 'Çoklu yol geri alım',
      description: 'Kullanıcı niyetine dayanarak, tüm Bilgilerde sorgular, çoklu kaynaklardan ilgili metni alır ve yeniden sıraladıktan sonra kullanıcı sorgusuyla eşleşen en iyi sonuçları seçer.',
    },
    rerankModelRequired: 'Rerank modeli gereklidir',
    params: 'Parametreler',
    top_k: 'En İyi K',
    top_kTip: 'Kullanıcı sorularına en çok benzeyen parçaları filtrelemek için kullanılır. Sistem ayrıca en iyi K değerini, seçilen modelin max_tokens\'a göre dinamik olarak ayarlar.',
    score_threshold: 'Skor Eşiği',
    score_thresholdTip: 'Parça filtreleme için benzerlik eşiğini ayarlamak için kullanılır.',
    retrieveChangeTip: 'Dizin modunu ve geri alım modunu değiştirmek, bu Bilgi ile ilişkili uygulamaları etkileyebilir.',
  },
  debugAsSingleModel: 'Tek Model Olarak Hata Ayıkla',
  debugAsMultipleModel: 'Çoklu Model Olarak Hata Ayıkla',
  duplicateModel: 'Çoğalt',
  publishAs: 'Olarak Yayınla',
  assistantType: {
    name: 'Asistan Türü',
    chatAssistant: {
      name: 'Temel Asistan',
      description: 'Büyük Dil Modeli kullanarak sohbet tabanlı bir asistan oluşturun',
    },
    agentAssistant: {
      name: 'Agent Asistanı',
      description: 'Görevleri tamamlamak için araçları özerk bir şekilde seçebilen bir zeki Agent oluşturun',
    },
  },
  agent: {
    agentMode: 'Agent Modu',
    agentModeDes: 'Agent için çıkarım modunu ayarlayın',
    agentModeType: {
      ReACT: 'ReAct',
      functionCall: 'Fonksiyon Çağrısı',
    },
    setting: {
      name: 'Agent Ayarları',
      description: 'Agent Asistanı ayarları, Agent modunu ve yerleşik promptlar gibi gelişmiş özellikleri ayarlamanıza olanak tanır. Sadece Agent türünde kullanılabilir.',
      maximumIterations: {
        name: 'Maksimum Yineleme',
        description: 'Bir Agent asistanının gerçekleştirebileceği yineleme sayısını sınırlayın',
      },
    },
    buildInPrompt: 'Yerleşik Prompt',
    firstPrompt: 'İlk Prompt',
    nextIteration: 'Sonraki Yineleme',
    promptPlaceholder: 'Promptunuzu buraya yazın',
    tools: {
      name: 'Araçlar',
      description: 'Araçlar kullanmak, internette arama yapmak veya bilimsel hesaplamalar yapmak gibi LLM yeteneklerini genişletebilir',
      enabled: 'Etkinleştirildi',
    },
  },
}

export default translation
