const translation = {
  title: 'एनोटेशन',
  name: 'एनोटेशन उत्तर',
  editBy: 'उत्तर संपादित किया गया {{author}} द्वारा',
  noData: {
    title: 'कोई एनोटेशन नहीं',
    description: 'आप ऐप डिबगिंग के दौरान एनोटेशन संपादित कर सकते हैं या उच्च गुणवत्ता वाले उत्तर के लिए यहां बल्क में एनोटेशन आयात कर सकते हैं।',
  },
  table: {
    header: {
      question: 'प्रश्न',
      answer: 'उत्तर',
      createdAt: 'निर्माण तिथि',
      hits: 'हिट्स',
      actions: 'क्रियाएँ',
      addAnnotation: 'एनोटेशन जोड़ें',
      bulkImport: 'बल्क आयात',
      bulkExport: 'बल्क निर्यात',
      clearAll: 'सभी एनोटेशन साफ करें',
    },
  },
  editModal: {
    title: 'एनोटेशन उत्तर संपादित करें',
    queryName: 'उपयोगकर्ता प्रश्न',
    answerName: 'स्टोरीटेलर बॉट',
    yourAnswer: 'आपका उत्तर',
    answerPlaceholder: 'यहां अपना उत्तर टाइप करें',
    yourQuery: 'आपका प्रश्न',
    queryPlaceholder: 'यहां अपना प्रश्न टाइप करें',
    removeThisCache: 'इस एनोटेशन को हटाएं',
    createdAt: 'निर्माण तिथि',
  },
  addModal: {
    title: 'एनोटेशन उत्तर जोड़ें',
    queryName: 'प्रश्न',
    answerName: 'उत्तर',
    answerPlaceholder: 'यहां उत्तर टाइप करें',
    queryPlaceholder: 'यहां प्रश्न टाइप करें',
    createNext: 'एक और एनोटेटेड उत्तर जोड़ें',
  },
  batchModal: {
    title: 'बल्क आयात',
    csvUploadTitle: 'अपनी CSV फ़ाइल यहां खींचें और छोड़ें, या ',
    browse: 'ब्राउज़ करें',
    tip: 'CSV फ़ाइल को निम्नलिखित संरचना के अनुरूप होना चाहिए:',
    question: 'प्रश्न',
    answer: 'उत्तर',
    contentTitle: 'खंड सामग्री',
    content: 'सामग्री',
    template: 'टेम्पलेट यहां डाउनलोड करें',
    cancel: 'रद्द करें',
    run: 'बैच चलाएँ',
    runError: 'बैच चलाना विफल रहा',
    processing: 'बैच प्रोसेसिंग में',
    completed: 'आयात पूर्ण',
    error: 'आयात त्रुटि',
    ok: 'ठीक',
  },
  errorMessage: {
    answerRequired: 'उत्तर आवश्यक है',
    queryRequired: 'प्रश्न आवश्यक है',
  },
  viewModal: {
    annotatedResponse: 'एनोटेशन उत्तर',
    hitHistory: 'हिट इतिहास',
    hit: 'हिट',
    hits: 'हिट्स',
    noHitHistory: 'कोई हिट इतिहास नहीं',
  },
  hitHistoryTable: {
    query: 'प्रश्न',
    match: 'मेल',
    response: 'प्रतिक्रिया',
    source: 'स्रोत',
    score: 'स्कोर',
    time: 'समय',
  },
  initSetup: {
    title: 'एनोटेशन उत्तर प्रारंभिक सेटअप',
    configTitle: 'एनोटेशन उत्तर सेटअप',
    confirmBtn: 'सहेजें और सक्षम करें',
    configConfirmBtn: 'सहेजें',
  },
  embeddingModelSwitchTip: 'एनोटेशन टेक्स्ट वेक्टराइजेशन मॉडल, मॉडल बदलने से पुनः एम्बेड किया जाएगा, जिससे अतिरिक्त लागतें उत्पन्न होंगी।',
}

export default translation
