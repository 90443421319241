const translation = {
  pageTitle: 'Bun venit! Hai să începem!👋',
  welcome: 'Bine ai venit la Dify, te rugăm să te autentifici pentru a continua.',
  email: 'Adresă de email',
  emailPlaceholder: 'Adresa ta de email',
  password: 'Parolă',
  passwordPlaceholder: 'Parola ta',
  name: 'Nume de utilizator',
  namePlaceholder: 'Numele tău de utilizator',
  forget: 'Ai uitat parola?',
  signBtn: 'Autentificare',
  sso: 'Continuă cu SSO',
  installBtn: 'Configurare',
  setAdminAccount: 'Configurare cont de administrator',
  setAdminAccountDesc: 'Privilegii maxime pentru contul de administrator, care poate fi utilizat pentru crearea de aplicații și gestionarea furnizorilor LLM, etc.',
  createAndSignIn: 'Creează și autentifică-te',
  oneMoreStep: 'Un pas în plus',
  createSample: 'Pe baza acestor informații, vom crea o aplicație de exemplu pentru tine',
  invitationCode: 'Cod de invitație',
  invitationCodePlaceholder: 'Codul tău de invitație',
  interfaceLanguage: 'Limba interfeței',
  timezone: 'Fus orar',
  go: 'Mergi la Dify',
  sendUsMail: 'Trimite-ne un email cu introducerea ta și noi ne vom ocupa de cererea de invitație.',
  acceptPP: 'Am citit și accept politica de confidențialitate',
  reset: 'Rulați următoarea comandă pentru a vă reseta parola',
  withGitHub: 'Continuă cu GitHub',
  withGoogle: 'Continuă cu Google',
  rightTitle: 'Deblochează întregul potențial al LLM',
  rightDesc: 'Construiește cu ușurință aplicații AI captivante din punct de vedere vizual, utilizabile și îmbunătățibile.',
  tos: 'Termeni și condiții',
  pp: 'Politica de confidențialitate',
  tosDesc: 'Prin înregistrarea, ești de acord cu',
  goToInit: 'Dacă nu ai inițializat încă contul, te rugăm să mergi la pagina de inițializare',
  donthave: 'Nu ai?',
  invalidInvitationCode: 'Cod de invitație invalid',
  accountAlreadyInited: 'Contul este deja inițializat',
  forgotPassword: 'Ați uitat parola?',
  resetLinkSent: 'Link de resetare trimis',
  sendResetLink: 'Trimiteți linkul de resetare',
  backToSignIn: 'Înapoi la autentificare',
  forgotPasswordDesc: 'Vă rugăm să introduceți adresa de e-mail pentru a reseta parola. Vă vom trimite un e-mail cu instrucțiuni despre cum să resetați parola.',
  checkEmailForResetLink: 'Vă rugăm să verificați e-mailul pentru un link de resetare a parolei. Dacă nu apare în câteva minute, verificați folderul de spam.',
  passwordChanged: 'Conectează-te acum',
  changePassword: 'Schimbă parola',
  changePasswordTip: 'Vă rugăm să introduceți o nouă parolă pentru contul dvs',
  invalidToken: 'Token invalid sau expirat',
  confirmPassword: 'Confirmă parola',
  confirmPasswordPlaceholder: 'Confirmați noua parolă',
  passwordChangedTip: 'Parola dvs. a fost schimbată cu succes',
  error: {
    emailEmpty: 'Adresa de email este obligatorie',
    emailInValid: 'Te rugăm să introduci o adresă de email validă',
    nameEmpty: 'Numele este obligatoriu',
    passwordEmpty: 'Parola este obligatorie',
    passwordInvalid: 'Parola trebuie să conțină litere și cifre, iar lungimea trebuie să fie mai mare de 8 caractere',
  },
  license: {
    tip: 'Înainte de a începe Dify Community Edition, citește',
    link: 'Licența open-source de pe GitHub',
  },
  join: 'Alătură-te',
  joinTipStart: 'Te invităm să te alături echipei',
  joinTipEnd: 'pe Dify',
  invalid: 'Link-ul a expirat',
  explore: 'Explorează Dify',
  activatedTipStart: 'Te-ai alăturat echipei',
  activatedTipEnd: '',
  activated: 'Autentifică-te acum',
  adminInitPassword: 'Parola de inițializare a administratorului',
  validate: 'Validează',
}

export default translation
