const translation = {
  pageTitle: 'Привіт, почнемо!👋',
  welcome: 'Ласкаво просимо до Dify, будь ласка, увійдіть, щоб продовжити.',
  email: 'Адреса електронної пошти',
  emailPlaceholder: 'Ваша електронна пошта',
  password: 'Пароль',
  passwordPlaceholder: 'Ваш пароль',
  name: 'Ім\'я користувача',
  namePlaceholder: 'Ваше ім\'я користувача',
  forget: 'Забули пароль?',
  signBtn: 'Вхід',
  installBtn: 'Налаштувати',
  setAdminAccount: 'Налаштування облікового запису адміністратора',
  setAdminAccountDesc: 'Максимальні привілеї для облікового запису адміністратора, які можна використовувати для створення програм, керування постачальниками LLM тощо.',
  createAndSignIn: 'Створити та увійти',
  oneMoreStep: 'Ще один крок',
  createSample: 'На основі цієї інформації ми створимо для вас зразок програми',
  invitationCode: 'Код запрошення',
  invitationCodePlaceholder: 'Ваш код запрошення',
  interfaceLanguage: 'Мова інтерфейсу',
  timezone: 'Часовий пояс',
  go: 'Перейти до Dify',
  sendUsMail: 'Надішліть нам своє представлення, і ми обробимо запит на запрошення.',
  acceptPP: 'Я прочитав і приймаю політику конфіденційності',
  reset: 'Будь ласка, виконайте таку команду, щоб скинути пароль',
  withGitHub: 'Продовжити з GitHub',
  withGoogle: 'Продовжити з Google',
  rightTitle: 'Розкрийте весь потенціал LLM',
  rightDesc: 'З легкістю створюйте візуально привабливі, функціональні та вдосконалювані програми зі штучним інтелектом.',
  tos: 'Умови обслуговування',
  pp: 'Політика конфіденційності',
  tosDesc: 'Реєструючись, ви приймаєте наші',
  goToInit: 'Якщо ви ще не ініціалізували обліковий запис, перейдіть на сторінку ініціалізації',
  donthave: 'Не маєте?',
  invalidInvitationCode: 'Недійсний код запрошення',
  accountAlreadyInited: 'Обліковий запис уже ініціалізовано',
  forgotPassword: 'Забули пароль?',
  resetLinkSent: 'Посилання для скидання надіслано',
  sendResetLink: 'Надіслати посилання для скидання',
  backToSignIn: 'Повернутися до входу',
  forgotPasswordDesc: 'Будь ласка, введіть свою електронну адресу, щоб скинути пароль. Ми надішлемо вам електронного листа з інструкціями щодо скидання пароля.',
  checkEmailForResetLink: 'Будь ласка, перевірте свою електронну пошту на наявність посилання для скидання пароля. Якщо протягом кількох хвилин не з’явиться, перевірте папку зі спамом.',
  passwordChanged: 'Увійдіть зараз',
  changePassword: 'Змінити пароль',
  changePasswordTip: 'Будь ласка, введіть новий пароль для свого облікового запису',
  invalidToken: 'Недійсний або прострочений токен',
  confirmPassword: 'Підтвердити пароль',
  confirmPasswordPlaceholder: 'Підтвердьте новий пароль',
  passwordChangedTip: 'Ваш пароль було успішно змінено',
  error: {
    emailEmpty: 'Адреса електронної пошти обов\'язкова',
    emailInValid: 'Введіть дійсну адресу електронної пошти',
    nameEmpty: 'Ім\'я обов\'язкове',
    passwordEmpty: 'Пароль є обов’язковим',
    passwordInvalid: 'Пароль повинен містити літери та цифри, а довжина повинна бути більшою за 8',
  },
  license: {
    tip: 'Перед запуском Dify Community Edition ознайомтеся з ліцензією з відкритим кодом на GitHub',
    link: 'Ліцензія з відкритим кодом',
  },
  join: 'Приєднуйтесь',
  joinTipStart: 'Запрошуємо вас приєднатися',
  joinTipEnd: 'команда Dify',
  invalid: 'Посилання застаріло',
  explore: 'Досліджуйте Dify',
  activatedTipStart: 'Ви приєдналися до',
  activatedTipEnd: 'команда',
  activated: 'Увійти зараз',
  adminInitPassword: 'Пароль ініціалізації адміністратора',
  validate: 'Перевірити',
}

export default translation
