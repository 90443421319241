const translation = {
  title: '日誌',
  description: '日誌記錄了應用的執行情況，包括使用者的輸入和 AI 的回覆。',
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      updatedTime: '更新時間',
      time: '創建時間',
      endUser: '使用者',
      input: '輸入',
      output: '輸出',
      summary: '標題',
      messageCount: '訊息數',
      userRate: '使用者反饋',
      adminRate: '管理員反饋',
      startTime: '開始時間',
      status: '狀態',
      runtime: '執行時間',
      tokens: 'TOKENS',
      user: '使用者',
      version: '版本',
    },
    pagination: {
      previous: '上一頁',
      next: '下一頁',
    },
    empty: {
      noChat: '未開始的對話',
      noOutput: '無輸出',
      element: {
        title: '這裡有人嗎',
        content: '在這裡觀測和標註終端使用者和 AI 應用程式之間的互動，以不斷提高 AI 的準確性。您可以<testLink>試試</testLink> WebApp 或<shareLink>分享</shareLink>出去，然後返回此頁面。',
      },
    },
  },
  detail: {
    time: '時間',
    conversationId: '對話 ID',
    promptTemplate: '字首提示詞',
    promptTemplateBeforeChat: '對話前提示詞 · 以系統訊息提交',
    annotationTip: '{{user}} 標記的改進回覆',
    timeConsuming: '耗時',
    second: ' 秒',
    tokenCost: '花費 Token',
    loading: '載入中',
    operation: {
      like: '有幫助',
      dislike: '沒有幫助',
      addAnnotation: '標記改進回覆',
      editAnnotation: '編輯改進回覆',
      annotationPlaceholder: '輸入你希望 AI 回覆的預期答案，這在今後可用於模型微調，持續改進文字生成質量。',
    },
    variables: '變數',
    uploadImages: '上傳的圖片',
  },
  filter: {
    period: {
      today: '今天',
      last7days: '過去 7 天',
      last4weeks: '過去 4 周',
      last3months: '過去 3 月',
      last12months: '過去 12 月',
      monthToDate: '本月至今',
      quarterToDate: '本季度至今',
      yearToDate: '本年至今',
      allTime: '所有時間',
    },
    annotation: {
      all: '全部',
      annotated: '已標註改進（{{count}} 項）',
      not_annotated: '未標註',
    },
    sortBy: '排序方式：',
    descending: '降序',
    ascending: '升序',
  },
  workflowTitle: '日誌',
  workflowSubtitle: '日誌記錄了應用的執行情況',
  runDetail: {
    title: '對話日誌',
    workflowTitle: '日誌詳情',
  },
  promptLog: 'Prompt 日誌',
  agentLog: 'Agent 日誌',
  viewLog: '檢視日誌',
  agentLogDetail: {
    agentMode: 'Agent 模式',
    toolUsed: '使用工具',
    iterations: '迭代次數',
    iteration: '迭代',
    finalProcessing: '最終處理',
  },
}

export default translation
