const translation = {
  pageTitle: 'Xin chào, hãy bắt đầu! 👋',
  welcome: 'Chào mừng bạn đến với Dify, vui lòng đăng nhập để tiếp tục.',
  email: 'Địa chỉ email',
  emailPlaceholder: 'Nhập email của bạn',
  password: 'Mật khẩu',
  passwordPlaceholder: 'Nhập mật khẩu của bạn',
  name: 'Tên người dùng',
  namePlaceholder: 'Nhập tên người dùng của bạn',
  forget: 'Quên mật khẩu?',
  signBtn: 'Đăng nhập',
  installBtn: 'Cài đặt',
  setAdminAccount: 'Thiết lập tài khoản quản trị',
  setAdminAccountDesc: 'Tài khoản quản trị có quyền tối đa, có thể tạo ứng dụng và quản lý các nhà cung cấp LLM, v.v.',
  createAndSignIn: 'Tạo và đăng nhập',
  oneMoreStep: 'Còn một bước nữa',
  createSample: 'Dựa trên thông tin này, chúng tôi sẽ tạo một ứng dụng mẫu cho bạn',
  invitationCode: 'Mã mời',
  invitationCodePlaceholder: 'Nhập mã mời của bạn',
  interfaceLanguage: 'Ngôn ngữ giao diện',
  timezone: 'Múi giờ',
  go: 'Đi đến Dify',
  sendUsMail: 'Gửi email giới thiệu cho chúng tôi, chúng tôi sẽ xử lý yêu cầu mời của bạn.',
  acceptPP: 'Tôi đã đọc và đồng ý với chính sách bảo mật',
  reset: 'Vui lòng chạy lệnh sau để đặt lại mật khẩu của bạn',
  withGitHub: 'Tiếp tục với GitHub',
  withGoogle: 'Tiếp tục với Google',
  rightTitle: 'Khai phá tiềm năng tối đa của LLM',
  rightDesc: 'Dễ dàng xây dựng ứng dụng AI hấp dẫn, có thể vận hành và cải thiện được.',
  tos: 'Điều khoản dịch vụ',
  pp: 'Chính sách bảo mật',
  tosDesc: 'Bằng cách đăng ký, bạn đồng ý với',
  goToInit: 'Nếu bạn chưa khởi tạo tài khoản, vui lòng chuyển đến trang khởi tạo',
  donthave: 'Chưa có tài khoản?',
  invalidInvitationCode: 'Mã mời không hợp lệ',
  accountAlreadyInited: 'Tài khoản đã được khởi tạo',
  forgotPassword: 'Quên mật khẩu?',
  resetLinkSent: 'Đã gửi liên kết đặt lại mật khẩu',
  sendResetLink: 'Gửi liên kết đặt lại mật khẩu',
  backToSignIn: 'Quay lại đăng nhập',
  forgotPasswordDesc: 'Vui lòng nhập địa chỉ email của bạn để đặt lại mật khẩu. Chúng tôi sẽ gửi cho bạn một email hướng dẫn cách đặt lại mật khẩu.',
  checkEmailForResetLink: 'Vui lòng kiểm tra email để nhận liên kết đặt lại mật khẩu. Nếu không thấy trong vài phút, hãy kiểm tra thư mục spam.',
  passwordChanged: 'Đăng nhập ngay',
  changePassword: 'Đổi mật khẩu',
  changePasswordTip: 'Vui lòng nhập mật khẩu mới cho tài khoản của bạn',
  invalidToken: 'Mã thông báo không hợp lệ hoặc đã hết hạn',
  confirmPassword: 'Xác nhận mật khẩu',
  confirmPasswordPlaceholder: 'Nhập lại mật khẩu mới của bạn',
  passwordChangedTip: 'Mật khẩu của bạn đã được thay đổi thành công',
  error: {
    emailEmpty: 'Vui lòng nhập địa chỉ email',
    emailInValid: 'Vui lòng nhập một địa chỉ email hợp lệ',
    nameEmpty: 'Vui lòng nhập tên',
    passwordEmpty: 'Vui lòng nhập mật khẩu',
    passwordInvalid: 'Mật khẩu phải chứa cả chữ và số, và có độ dài ít nhất 8 ký tự',
  },
  license: {
    tip: 'Trước khi bắt đầu sử dụng Phiên bản Cộng đồng của Dify, vui lòng đọc',
    link: 'Giấy phép mã nguồn mở trên GitHub',
  },
  join: 'Tham gia',
  joinTipStart: 'Mời bạn tham gia',
  joinTipEnd: 'đội ngũ tại Dify',
  invalid: 'Liên kết đã hết hạn',
  explore: 'Khám phá Dify',
  activatedTipStart: 'Bạn đã tham gia',
  activatedTipEnd: 'đội ngũ',
  activated: 'Đăng nhập ngay',
  adminInitPassword: 'Mật khẩu khởi tạo quản trị viên',
  validate: 'Xác thực',
}

export default translation
