const translation = {
  pageTitle: '嗨，近來可好 👋',
  welcome: '歡迎來到 Dify, 登入以繼續',
  email: '郵箱',
  emailPlaceholder: '輸入郵箱地址',
  password: '密碼',
  passwordPlaceholder: '輸入密碼',
  name: '使用者名稱',
  namePlaceholder: '輸入使用者名稱',
  forget: '忘記密碼？',
  signBtn: '登入',
  installBtn: '設定',
  setAdminAccount: '設定管理員賬戶',
  setAdminAccountDesc: '管理員擁有的最大許可權，可用於建立應用和管理 LLM 供應商等。',
  createAndSignIn: '建立賬戶',
  oneMoreStep: '還差一步',
  createSample: '基於這些資訊，我們將為您建立一個示例應用',
  invitationCode: '邀請碼',
  invitationCodePlaceholder: '輸入邀請碼',
  interfaceLanguage: '介面語言',
  timezone: '時區',
  go: '跳轉至 Dify',
  sendUsMail: '發封郵件介紹你自己，我們會盡快處理。',
  acceptPP: '我已閱讀並接受隱私政策',
  reset: '請執行以下命令重置密碼',
  withGitHub: '使用 GitHub 登入',
  withGoogle: '使用 Google 登入',
  rightTitle: '釋放大型語言模型的全部潛能',
  rightDesc: '簡單構建視覺化、可運營、可改進的 AI 應用',
  tos: '使用協議',
  pp: '隱私政策',
  tosDesc: '使用即代表你並同意我們的',
  goToInit: '如果您還沒有初始化賬戶，請前往初始化頁面',
  donthave: '還沒有邀請碼？',
  invalidInvitationCode: '無效的邀請碼',
  accountAlreadyInited: '賬戶已經初始化',
  forgotPassword: '忘記密碼？',
  resetLinkSent: '重設連結已發送',
  sendResetLink: '發送重設連結',
  backToSignIn: '返回登錄',
  forgotPasswordDesc: '請輸入您的電子郵件地址以重設密碼。我們將向您發送一封電子郵件，說明如何重設密碼。',
  checkEmailForResetLink: '請檢查您的電子郵件以獲取重設密碼的連結。如果幾分鐘內沒有收到，請檢查您的垃圾郵件文件夾。',
  passwordChanged: '立即登入',
  changePassword: '更改密碼',
  changePasswordTip: '請輸入您的新密碼',
  invalidToken: '無效或已過期的令牌',
  confirmPassword: '確認密碼',
  confirmPasswordPlaceholder: '確認您的新密碼',
  passwordChangedTip: '您的密碼已成功更改',
  error: {
    emailEmpty: '郵箱不能為空',
    emailInValid: '請輸入有效的郵箱地址',
    nameEmpty: '使用者名稱不能為空',
    passwordEmpty: '密碼不能為空',
    passwordInvalid: '密碼必須包含字母和數字，且長度不小於8位',
  },
  license: {
    tip: '啟動 Dify 社群版之前, 請閱讀 GitHub 上的',
    link: '開源協議',
  },
  join: '加入',
  joinTipStart: '邀請你加入',
  joinTipEnd: '團隊',
  invalid: '連結已失效',
  explore: '探索 Dify',
  activatedTipStart: '您已加入',
  activatedTipEnd: '團隊',
  activated: '現在登入',
  adminInitPassword: '管理員初始化密碼',
  validate: '驗證',
}

export default translation
